import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl, ProgressBar } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import imagen_vehiculo from '../../assets/images/vehiculo.png';
import imagen_sin_foto from '../../assets/images/logo.jpg';
import { fomatearFecha} from '../../helpers'
import DetalleProceso from '../panelCliente/DetalleProceso';
import StarRatings from 'react-star-ratings';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import { laravelAPI } from '../../config.js'
import ReactPanZoom from 'react-image-pan-zoom-rotate';

//importar funciones helpers
import { obtenerEscala} from '../../helpers'



class MostrarFormImage extends Component {
    constructor(props) {
        super(props);
        this.state = {imagenes:null}
    }

    changeImagen = (imagenes) => {
        
        this.setState({imagenes: imagenes});
    
    }

    registros = [];
    
    render() {

        this.registros = []
        let data = this.props.data
        let pedido = this.props.data.pedido
        let mostrar_modal = data.modal_regularizar_imagen.mostrar_modal
        let client = pedido ? pedido.detail : null
        
        if (!pedido || !mostrar_modal) {
            return <></>
        }
 
        const images = []; 

        let titulo = `Regularizar Imagenes Pedido`
        let errores_validacion = data.errores_validacion
 
        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.cerrar(null)}
                    backdrop="static"
                    keyboard={false}
                    size='sm'
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <form onSubmit={this.props.guardarEntrega} encType="multipart/form-data" id="imageForm"> */}
                        { pedido ? (
                            <div className="row">
                                <div className="col-12">
                                    <label className='text-info' style={{'font-size': 'large'}}>Cliente {client.code}</label>
                                </div>
                                <div className="col-12">
                                    <label className='text-info' style={{'font-size': 'large'}}>Doc. {client.document_number}</label>
                                </div>
                                <div className="col-12">
                                    <Form.Group controlId="formFile"  className="mb-3">
                                      <Form.Label>Cargar foto del documento :</Form.Label>
                                      <Form.Control isInvalid = {errores_validacion[`imagen-${pedido.customer_id}`] ? true : false}   type="file" name={`imagen-${pedido.customer_id}`} onChange={(e) => {this.props.funcionRegularizarImagen(e,pedido.customer_id,client.order_delivery_id,'peso')}} />
                                      <ProgressBar style={{'border': 'black 1px solid'}} now={this.props.data.progress_bar[`imagen-${pedido.customer_id}`] ? this.props.data.progress_bar[`imagen-${pedido.customer_id}`] : 0} />
                                    </Form.Group>
                                </div>
                                <div className="col-12">
                                    <Form.Group  controlId="formFile"  className="mb-3">
                                      <Form.Label>Cargar Foto de bulto:</Form.Label>
                                      <Form.Control isInvalid = {errores_validacion[`imagen-bulto-${pedido.customer_id}`] ? true : false}   type="file" name={`imagen-bulto-${pedido.customer_id}`} onChange={(e) => {this.props.funcionRegularizarImagen(e,pedido.customer_id,client.order_delivery_id,'bulto')}} />
                                      <ProgressBar   style={{'border': 'black 1px solid'}} now={this.props.data.progress_bar[`imagen-bulto-${pedido.customer_id}`] ? this.props.data.progress_bar[`imagen-bulto-${pedido.customer_id}`] : 0} />
                                    </Form.Group>
                                </div>
                            </div>) : ''
                            }
                        {/* </form> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.cerrar()} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    
  //obtener los registros de pedido
  obtenerRegistroDeProcesoDePedido(detalle){

    let descripcion = detalle.transit.description_web
    //validar si ya se agrego dicho detalle
    const result = this.registros.filter(registro => registro==descripcion);

    //si ya se agrego salimos de la funcion
    if (result.length>0) {
      return null
    }

    //si no existe lo agregamos al arreglo
    this.registros.push(descripcion);
    
    return {
      id: detalle.id,
      titulo_estado: descripcion,
      fecha: `${detalle.date_of_work} ${detalle.work_time}`,
      descripcion: descripcion+' de Manera Exitosa.',
      estado: 'Aprobado'
    };

  }

  async obtenerFotos(id){

    let fotos = await this.buscarFotos(id) 

    console.log(fotos)
    
    this.setState({imagenes: fotos})

  }

  cerrar(){

    this.setState({imagenes: null})

    this.props.funcionMostrarPedido(null)
  }

  
    activarRotacion(data) {

        return(<ReactPanZoom
                    image={data.original}
                     
                />)
        
    }

  buscarFotos(id) {

        return new Promise((resolve, reject) => {
            //bloquear pantalla
            laravelAPI.get(`/despachos/obtener-fotos/${id}`)
                .then(function (response) {
 
                    resolve(response.data.imagenes)

                })
                .catch(function (error) {
                    reject(error)
                })
        })

    }
}





export default MostrarFormImage;