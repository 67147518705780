import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faLock,faIdCard } from '@fortawesome/free-solid-svg-icons';



class Crear extends Component {



    render() {

        let data = this.props.data
        // console.log(data);
        let titulo = `Crear ${data.titulo_modulo} MMTrack`
        let mostrar_modal = data.modal_crear.mostrar_modal
        let usuario = null
        let roles = null
        let showLicencia = data.showLicencia;
        usuario = data.crear_registro

        if (data.registros) {
            roles = data.registros.roles
        }

        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={this.props.funcionMostrarOcultarModalCrear}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"> <FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                                <FormControl
                                    value={usuario ? usuario.name : ''}
                                    aria-describedby="basic-addon1"
                                    name="name"
                                    onChange={this.props.funcionHandleInputChanger}
                                    type="text"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"> <FontAwesomeIcon icon={faLock} /></InputGroup.Text>
                                <FormControl
                                    value={usuario ? usuario.password : ''}
                                    aria-describedby="basic-addon1"
                                    name="clave"
                                    onChange={this.props.funcionHandleInputChanger}
                                    type="password"

                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"> <FontAwesomeIcon icon={faEnvelopeSquare} /></InputGroup.Text>
                                <FormControl
                                    value={usuario ? usuario.email : ''}
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="email"
                                    type="email"
                                    onChange={this.props.funcionHandleInputChanger}

                                />
                            </InputGroup>
                            {
                                showLicencia && 
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"> <FontAwesomeIcon icon={faIdCard} /></InputGroup.Text>
                                    <FormControl
                                        value={usuario ? usuario.licencia : ''}
                                        aria-label="Licencia de conducir"
                                        aria-describedby="basic-addon1"
                                        name="licencia"
                                        type="text"
                                        onChange={this.props.funcionHandleInputChanger}
                                    />
                                </InputGroup>
                            }
                            
                            {
                                roles &&

                                <div className="form-group">
                                    <label>Roles del Usuario</label>
                                    {
                                        
                                        roles.map((rol, key) => {

                                            let check_active = data.crear_registro.roles.includes(rol.name) ? true : false

                                            return(
                                                <div className="form-check" key={key}>
                                                    <input className="form-check-input" value={rol.name} checked={check_active}  onChange={this.props.funcionHandleCheckboxChange} type="checkbox"/>
                                                    <label className="form-check-label">{rol.name}</label>
                                                </div>
                                            ) 
                                        })
                                    }
                                    
                                </div>
                            }


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.funcionMostrarOcultarModalCrear} >
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.props.funcionGuardarRegistrosUsiario}>Guardar</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}




export default Crear;