import React, { useState, useEffect } from 'react';
import { PageContent, } from 'react-adminlte-3'
import EncabezadoModulo from './EncabezadoModulo';
import axios from 'axios';
import { cerrarMenuLateral } from '../../../helpers.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck,faLocationArrow, faMap } from '@fortawesome/free-solid-svg-icons';
import { Manager } from "socket.io-client";
//importar configuracion axio
import { DIRECCION_SOCKET } from '../../../config.js'
 
const ENDPOINT = DIRECCION_SOCKET;




function Contenido() {
    //estatus
    const [state, setState] = useState({
        registros: null,
        titulo_modulo: 'Configuracion GPS',
        seccion: null,
        vehiculos: null,
        dispositivos: null
    });

    const manager = new Manager(ENDPOINT);
    const socket = manager.socket("/configuracion-gps");

    //funciones que se ejecutaran al iniciar el componente
    useEffect(async () => {
        //cerrar menu lateral
        cerrarMenuLateral()

        socket.emit("suscribir");

        //respuesta de suscripcion
        socket.on('resultado-suscripcion', resultadoSuscripcion);

        //respuesta de estado de plataforma gps
        socket.on('estatus-plataforma-gps', sincronizarEstatusPlataformaGps);

        //escuchar cambios de secciones
        socket.on('cambio-en-seccion', sincronizarEstatusPlataformaGps);

    }, []);




    return (
        <div>
            <EncabezadoModulo
                titulo_modulo={state.titulo_modulo}
            />

            <PageContent>
                <section className="content">

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className={`small-box bg-${state.seccion ? 'success': 'danger'}`}>
                                    <div className="inner">
                                        <h3>{state.seccion ? 'Conectado': 'Desconectado'}</h3>
                                        <p>Conexiòn GPS </p>
                                    </div>
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faMap}   />
                                    </div>
                                    <a className="small-box-footer">Tocken Vence en {state.seccion ? state.seccion.TIEMPO_TOCKEN_GPS: ''} Min. </a>
                                </div> 
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{state.dispositivos ? state.dispositivos.total : '0'}</h3>
                                        <p>Dispositivos GPS</p>
                                    </div>
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faLocationArrow}   />
                                    </div>
                                    <a href="#" className="small-box-footer">Total de Dispositivos <i className="fas fa-arrow-circle-right"></i></a>
                                </div> 
                            </div>
                            
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>150</h3>
                                        <p>Vehiculos Encendidos</p>
                                    </div>
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faTruck}   />
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div> 
                            </div>
                            
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>150</h3>
                                        <p>Vehiculos Apagados</p>
                                    </div>
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faTruck}   />
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div> 
                            </div>
                             
                        </div>
                    </div>



                </section>
            </PageContent>
        </div>
    )



    function imprimirLog(data) {
        console.log(data)
    }

    function sincronizarEstatusPlataformaGps(data) {
     
        console.log(data)
        
        setState(previousState => {
            return { ...previousState,  seccion: data.sessionGPS, dispositivos: data.dispositivosGps}
        });
        

    }

    function resultadoSuscripcion(data) {

        //validar que se suscribio correctamente
        if (data.ok) {
            socket.emit("consultar-estatus-plataforma-gps");
        }
    }

}



export default Contenido;