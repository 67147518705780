import React, { useState,useEffect  } from 'react';
import {PageContent,} from 'react-adminlte-3'
import {Form} from 'react-bootstrap';
import EncabezadoModulo from './EncabezadoModulo';
import axios from 'axios';

//importar funciones helpers
import { mostrarMensajeExito,cerrarMenuLateral,mostrarMensajeError,mensajeDeConfirmacion } from '../../helpers'
import Vehiculos from './Vehiculos';
import AsignacionCarga from './AsignacionCarga';
//importar configuracion axio
import {laravelAPI}  from '../../config.js'
import ClipLoader from "react-spinners/ClimbingBoxLoader";
const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };


function Contenido() {
    //estatus
    const [state, setState] = useState({
        registros : null,
        pedidos_lima : null,
        pedidos_provincia:null,
        titulo_modulo: 'Asignar Carga',
        formularios : { crear: false},
        modal_vehiculo:{ mostrar_modal: false},
        crear_registro:{    vehiculo_id:'',
                            tipo_despacho:'Pre-Asignado',
                            envios_lima:'',
                            envio_provincias:'',
                            preasignado:false,
                             
                        },
        errores_validacion : { vehiculo_id:null, tipo_despacho:null,envios_lima:null, envio_provincias:null,},
        refrescar : 0,
        vehiculo: null,
       
    });

    //variables y funcion para bloquear pantalla
    const [cargando, setCargando] = useState(false)
    const gestionarCarga = (valor) =>{  setCargando(valor); }

    //funciones que se ejecutaran al iniciar el componente
    useEffect( async() => {
        //cerrar menu lateral
        cerrarMenuLateral()
        
        let vehiculos_disponibles = await obtenerRegistros();

        //validar que exista datos de cliente
        if (vehiculos_disponibles.data) {
            //actualizar datos de registros
            actualizarDatosDeRegistros(vehiculos_disponibles.data);
        }  
        
        actualizarPedidos();
        
        setInterval(actualizarPedidos, 20000);
 
         

    }, [state.refrescar]);


    
    if (cargando) {
        return (
          <div  className='mx-auto' style={style}>
            <ClipLoader color='rgb(53, 213, 182)' loading={true}  size={16} />
          </div>
        )
    }else{
        return (
            <div>
            <EncabezadoModulo 
                titulo_modulo={state.titulo_modulo}
            />

            {!state.vehiculo &&
                <Vehiculos  
                    data={state}
                    funcionCrearRegistro={crearRegistro}
                    funcionIniciarDespacho = {iniciarDespacho}
                />
            }

            {state.vehiculo &&
                <AsignacionCarga  
                    data={state} 
                    funcionCrearRegistro={crearRegistro}
                    funcionHandleInputChanger = {handleInputChange}
                    funcionGuardarRegistros = {guardarRegistro}
                    funcionActualizarPreasignado = {actualizarPreasignado}
                />
            }

            <PageContent>
                
            </PageContent>
            </div>
        )
    }
    
    


    function obtenerRegistros() {
        
        let parametros = {
            mostrar_inactivos : state.mostrar_inactivos
        };
        return new Promise((resolve, reject)=>{
            //bloquear pantalla
            gestionarCarga(true)
            laravelAPI.get('vehiculos/obtenerVehiculos/Disponible')
            .then(function (response) {
                //desbloquear pantalla
                gestionarCarga(false)
                resolve(response)
                
            })
            .catch(function (error) {
                //desbloquear pantalla
                gestionarCarga(false)
                reject(null)
            })
        })

    }

    function obtenerPedidos() {
        
        let parametros = {
            mostrar_inactivos : state.mostrar_inactivos
        };
        return new Promise((resolve, reject)=>{
            //bloquear pantalla
            gestionarCarga(false)
            laravelAPI.get('pedidos/obtenerPedidos/empaquetados')
            .then(function (response) {
                //desbloquear pantalla
                gestionarCarga(false)
                resolve(response)
                
            })
            .catch(function (error) {
                //desbloquear pantalla
                gestionarCarga(false)
                reject(null)
            })
        })

    }

    async function actualizarPedidos() {
               
        let buscar_pedidos = await obtenerPedidos();
        
        console.log(buscar_pedidos)

        //validar que exista datos de cliente
        if (buscar_pedidos.data) {
            //actualizar datos de pedidos
            setState(previousState => {
                return { ...previousState,  pedidos_lima:buscar_pedidos.data.pedidos_lima,pedidos_provincia:buscar_pedidos.data.pedidos_provincia}
            });
        } 
    }

    function actualizarDatosDeRegistros(data) {
        
        setState(previousState => {
            return { ...previousState,  registros:data}
        });
    }

    function actualizarPreasignado(valor){
        setState(previousState => {
            return { ...previousState,  crear_registro : { ... state.crear_registro, preasignado : valor}}
        });
    }
 
    
    
    async function iniciarDespacho(vehiculo_id){
        
        let esConfirmar = await mensajeDeConfirmacion('Seguro de realizar la acción?')
       

        if(!esConfirmar){
            
            return
        } 


        return new Promise((resolve, reject)=>{
            //bloquear pantalla
            gestionarCarga(true)
            laravelAPI.get('despachos/iniciar-despacho', { params : {vehiculo_id} })
            .then(function (response) {
                
                mostrarMensajeExito(response.data.mensaje)
                setState(previousState => {
                    return { ...previousState,  refrescar : state.refrescar+1, vehiculo: null }
                });

                //desbloquear pantalla
                gestionarCarga(false)
                resolve(response)
                
            })
            .catch(function (error) {
                 
                if (error.response.status==422) {
                    //actualizar los errores
                    setState(previousState => {
                        return { ...previousState,  errores_validacion:error.response.data, refrescar : state.refrescar+1 }
                    });
                }

                mostrarMensajeError(error.response)
                //desbloquear pantalla
                gestionarCarga(false)
                reject(null)
            })
        })
         
        
        
    }  

    function guardarRegistro(){
        
        return new Promise((resolve, reject)=>{
            //bloquear pantalla
            gestionarCarga(true)
            laravelAPI.get('despachos/registrarDespacho', { params : state.crear_registro })
            .then(function (response) {
                
                mostrarMensajeExito(response.data.mensaje)
                setState(previousState => {
                    return { ...previousState,  refrescar : state.refrescar+1, vehiculo: null }
                });

                //desbloquear pantalla
                gestionarCarga(false)
                resolve(response)
                
            })
            .catch(function (error) {
                 
                if (error.response.status==422) {
                    //actualizar los errores
                    setState(previousState => {
                        return { ...previousState,  errores_validacion:error.response.data, refrescar : state.refrescar+1 }
                    });
                }

                mostrarMensajeError(error.response)
                //desbloquear pantalla
                gestionarCarga(false)
                reject(null)
            })
        })
         
        
        
    }

    function handleInputChange(event){

        setState(previousState => {
            return { ...previousState,  crear_registro : { ... state.crear_registro, [event.target.name] : event.target.value}}
        });

  
    }
 
    
    //craer funcion para que se ejecute al
    // mostrar o ocultar modal de vehiculo
    function crearRegistro(vehiculo = null){
                
        let valor = state.modal_vehiculo.mostrar_modal ? false : true
        
        setState(previousState => {
            return { ...previousState,  formulario : { crear: valor}, vehiculo:vehiculo, crear_registro : { ... state.crear_registro, vehiculo_id : vehiculo ? vehiculo.id: null}}
        });
      
    }    
 

 


}
 
 

export default Contenido;