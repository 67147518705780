import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faLock } from '@fortawesome/free-solid-svg-icons';



class Crear extends Component {



    render() {

        let data = this.props.data
        let titulo = `Crear Sucursal de ${data.titulo_modulo}`
        let mostrar_modal = data.modal_crear.mostrar_modal
        let agencia = data.crear_registro
        let departamentos = null

        if (data.registros) {
            departamentos = data.registros.departamentos
        }
         
        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>{this.props.funcionMostrarOcultarModalCrear(null)}} 
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"> <FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                                <FormControl
                                    value={agencia ? agencia.agencia_name : ''}
                                    aria-describedby="basic-addon1"
                                    name="agencia_name"
                                    onChange={this.props.funcionHandleInputChanger}
                                    type="text"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"> <FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                                <FormControl
                                    aria-describedby="basic-addon1"
                                    name="sucursal_name"
                                    placeholder='Nombre de Sucursal'
                                    onChange={this.props.funcionHandleInputChanger}
                                    isInvalid={data.errores_validacion.sucursal_name ? true : false}
                                    type="text"
                                />
                            </InputGroup>

                            {
                                departamentos &&

                                <div className="form-group">
                                    <label>Departamentos de la Sucursal</label>
                                    <div className='row'>
                                    {
                                        
                                        departamentos.map((departamento, key) => {
                                            
                                            let check_active = data.crear_registro.departamentos.includes(departamento.id+"") ? true : false

                                            return(
                                                <div className='col-6'  key={key}>
                                                    <div className="form-check ">
                                                        <input className="form-check-input" value={departamento.id} checked={check_active}  onChange={this.props.funcionHandleCheckboxChange} type="checkbox"/>
                                                        <label className="form-check-label">{departamento.name}</label>
                                                    </div>
                                                </div>
                                            ) 
                                        })
                                    }
                                    </div>
                                </div>
                            }


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{this.props.funcionMostrarOcultarModalCrear(null)}} >
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.props.funcionGuardarRegistrosUsiario}>Guardar</Button>

                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}




export default Crear;