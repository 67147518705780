import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faLocationArrow, faMap } from '@fortawesome/free-solid-svg-icons';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { MarkerIcon } from '../../assets/js/react-leaflet-icon';
import MarkerImagen from '../rastreo/Marker/MarkerImagen';
import Markers from "../rastreo/Marker/Markers";
//importar funciones helpers
import { cerrarMenuLateral, fomatearFecha } from '../../helpers.js';
import imagen_sin_foto from '../../assets/images/logo.jpg';
import imagen_delivery from '../../assets/images/entrega-urgente.png';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import ReactPanZoom from 'react-image-pan-zoom-rotate';

const ENDPOINT = 'http://127.0.0.10:9000';

function MapaMYM(props) {
 
    let registros = props.state;
    let url_mapa = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15607.23257992278!2d-76.9688683!3d-12.0567177!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x19df7b2849becd79!2sMYM%20REPUESTOS%20Y%20SERVICIOS%20TIENDA%20ATE!5e0!3m2!1ses!2spe!4v1642774593070!5m2!1ses!2spe'
    let center_mapa = [-12.046141514664367, -77.04262613595078];    
    
    if (registros.pedido) {
        if (registros.pedido.tracking.transit_status_id == 31 || registros.pedido.tracking.transit_status_id == 33) {
            if (registros.dispositivos) {
                if (registros.dispositivos.items) {
                    registros.dispositivos.items.map((dispositivo, key) => { 
                        if (!dispositivo) {
                            return
                        }
                        if (dispositivo.vehiculo.plate == registros.pedido.tracking.vehiculo.placa) {
                            let latitude = dispositivo.vehiculo.ultima_ubicacion.latitude
                            let longitude = dispositivo.vehiculo.ultima_ubicacion.longitude
                            center_mapa = [latitude, longitude];
                        } 
                    })
                }
            }
        }
    }
    //validar si existe un pedido
    if (registros.pedido) {
        if (registros.pedido.tracking.transit_status_id == 31 || registros.pedido.tracking.transit_status_id == 33) {
            return (
                <div id='div-mapa-mym' className="card">
                    <div className="card-header ui-sortable-handle" >
                        <h3 className="card-title">
                            Ubicacion del Pedido
                        </h3>
                    </div>
                    <div className="card-body" style={{ height: '500px', width: '100%' }}>
                        <MapContainer style={{ height: '100%', width: '100%' }} center={center_mapa} zoom={15}>
                            <TileLayer
                                //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                                maxZoom={20}
                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            />
                            {registros.dispositivos &&
                                registros.dispositivos.items &&
    
                                registros.dispositivos.items.map((dispositivo, key) => {
                                    if (dispositivo.vehiculo.plate == registros.pedido.tracking.vehiculo.placa) {
    
    
                                        let latitude = dispositivo.vehiculo.ultima_ubicacion.latitude
                                        let longitude = dispositivo.vehiculo.ultima_ubicacion.longitude
    
                                        return <Markers key={key}
                                            position={[latitude, longitude]}
                                            icon={<MarkerImagen vehiculo={dispositivo.vehiculo} />}>
    
                                            <Popup>
                                                <div>
                                                    <p><b>Placa:</b> {dispositivo.vehiculo.plate}</p>
                                                    <p><b>Modelo:</b> {dispositivo.vehiculo.model}</p>
                                                    <p><b>Color:</b> {dispositivo.vehiculo.color}</p>
                                                    <p><b>Marca:</b> {dispositivo.vehiculo.brand}</p>
                                                </div>
    
                                                actualizado a las {fomatearFecha(dispositivo.vehiculo.ultima_ubicacion.messageTime)}
                                            </Popup>
                                        </Markers>
                                    } 
                                })
                            }
    
                        </MapContainer>                    </div>
                </div>
            )  
        }else if (registros.pedido.tracking.transit_status_id == 32 ) {
            let imagen_detalle =imagen_sin_foto
            let imagen_bulto =imagen_sin_foto
    
            const images = []; 
    
            if (registros.pedido.detail) { 
                imagen_detalle = registros.pedido.detail.image_64 ? 
                                            registros.pedido.detail.image_64.includes('data:') ? registros.pedido.detail.image_64: `data:image/jpeg;base64,${registros.pedido.detail.image_64}` : 
                                            registros.pedido.detail.imagen ? `https://apisl.mym.com.pe:445/${registros.pedido.detail.imagen}` :  imagen_sin_foto
                imagen_bulto = registros.pedido.detail.image_64_bultos ?
                                            registros.pedido.detail.image_64_bultos.includes('data:') ? registros.pedido.detail.image_64_bultos: `data:image/jpeg;base64,${registros.pedido.detail.image_64_bultos}` : 
                                            registros.pedido.detail.imagen_bultos ? `https://apisl.mym.com.pe:445/${registros.pedido.detail.imagen_bultos}` :  imagen_sin_foto
    
                images.push({
                    original: imagen_detalle,
                    thumbnail: imagen_detalle,
                });
                images.push({
                    original: imagen_bulto,
                    thumbnail: imagen_bulto,
                    
                });
            }
    
            return (
                <div id='div-mapa-mym' className="card"> 
                    <div className="card-header ui-sortable-handle" >
                        <h3 className="card-title">
                            Captura de Fotos
                        </h3>
                    </div>
                    <div className="card-body"    >
                        <ImageGallery  renderItem={activarRotacion} showPlayButton={false} className='w-100 h-100'  showFullscreenButton={false} items={images} />
    
                    </div>
                </div>
            )
        }else{
            let urlImg = imagen_sin_foto;
            return (
                <div id='div-mapa-mym' className="card"> 
                    <div className="card-header ui-sortable-handle" >
                        <h3 className="card-title">
                            <b>M & M RESPUESTOS Y SERVICIOS</b>
                        </h3>
                    </div>
                    <div className="card-body" style={{textAlign:"center"}}>
                    <img
                            src={urlImg}
                            style={{ maxWidth: '100%' }}
                        />
                    </div>
                </div>
            )
        }


    }  

    else {
        let urlImg = imagen_sin_foto;
        return (
            <div id='div-mapa-mym' className="card"> 
                <div className="card-header ui-sortable-handle" >
                    <h3 className="card-title">
                        <b>M & M RESPUESTOS Y SERVICIOS</b>
                    </h3>
                </div>
                <div className="card-body" style={{textAlign:"center"}}>
                <img
                        src={urlImg}
                        style={{ maxWidth: '100%' }}
                    />
                </div>
            </div>
        )
        /*
        return (

            <div id='div-mapa-mym' className="card">
                <div className="card-header ui-sortable-handle" >
                    <h3 className="card-title">
                        Ubicacion del Pedido v3.
                    </h3>
                </div>
                <div className="card-body" style={{ height: '500px' }}>
                    <iframe className='w-100 h-100' style={{ border: 0 }} src={url_mapa}></iframe>
                </div>
            </div>

        )
        */
    }

    return(<h1>hola</h1>)
    function activarRotacion(data) {
        return(<ReactPanZoom
            image={data.original}
            
        />)
    }

}

export default MapaMYM;




