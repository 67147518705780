import React, { useState, useEffect } from 'react';
import ListadoTable from './ListadoTable';
import { PageContent, } from 'react-adminlte-3'
import { Form, Check } from 'react-bootstrap';
import Mostrar from './Mostrar';
import RegularizarImagen from './MostrarFormImage';
import GuiaRemision from '../panelCliente/Mostrar';
import EncabezadoModulo from './EncabezadoModulo';
import { useParams, Navigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
//importar configuracion axio
import { laravelAPI } from '../../config.js'
//importar funciones helpers
import { mostrarMensajeExito, mostrarMensajeAlertas,EsAdministrador, obtenerCodigoAs400, cerrarMenuLateral, mostrarMensajeError, mensajeDeConfirmacion, fechaActual} from '../../helpers'
import ClipLoader from "react-spinners/ClimbingBoxLoader";
const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

function Contenido() {
    //estatus
    const [state, setState] = useState({
        registros: null,
        titulo_modulo: 'Listado de Pedidos',
        refrescar: 0,
        pedido: null,
        filtros: 'todos',
        modal_pedido_detalles:{ mostrar_modal: false},
        modal_pedido:{ mostrar_modal: false},
        modal_regularizar_imagen: { mostrar_modal: false},
        otros_filtros: {
                        numero_pedido: '',
                        codigo_cliente: '',
                        fecha_actual: fechaActual
                    },
        progress_bar: {},
        errores_validacion: {},
        mostrar_foto : null

    });

    //variables y funcion para bloquear pantalla
    const [cargando, setCargando] = useState(false)
    const gestionarCarga = (valor) =>{  setCargando(valor); }
    const [imagenes_cargadas, setModificarImagenes] = useState([]);


    //funciones que se ejecutaran al iniciar el componente
    useEffect(async () => {
        //cerrar menu lateral
        cerrarMenuLateral()
        
        await obtenerRegistros();


    }, [state.refrescar]);


    if (cargando) {
        return (
          <div  className='mx-auto' style={style}>
            <ClipLoader color='rgb(53, 213, 182)' loading={true}  size={16} />
          </div>
        )
    }else{

        return (
            <div>
                <EncabezadoModulo
                    titulo_modulo={state.titulo_modulo}
                    funcionMostrarOcultarModalCrear={() => mostrarOcultarModalCrear(null)}
                />

                <PageContent>
                    <section className="content">

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <div className="form-group">
                                                        <div>
                                                            <label>Estado del Pedido</label> 
                                                        </div>
                                                        

                                                        <div>
                                                            <input type="radio" name='radio'  onChange={(checked)=>handleInputChange(checked)} value='todos' checked={state.filtros==='todos'}></input>
                                                            <span htmlFor="todos">Todos</span> 
                                                        </div>
                                                        <div>
                                                            <input type="radio" name='radio' onChange={(checked)=>handleInputChange(checked)} value="recibido" checked={state.filtros==='recibido'}></input>
                                                            <span htmlFor="recibido">Recibido</span> 
                                                        </div>
                                                        <div>
                                                            <input type="radio" name='radio' onChange={(checked)=>handleInputChange(checked)} value="en_ruta" checked={state.filtros==='en_ruta'}></input>
                                                            <span htmlFor="en_ruta">En Ruta</span> 
                                                        </div>
                                                        <div>
                                                            <input type="radio" name='radio'  onChange={(checked)=>handleInputChange(checked)} value="en_preparacion" checked={state.filtros==='en_preparacion'}></input>
                                                            <span htmlFor="en_preparacion">En Preparación</span>
                                                        </div>
                                                        <div>
                                                            <input type="radio" name='radio'  onChange={(checked)=>handleInputChange(checked)} value="delivery" checked={state.filtros==='delivery'}></input>
                                                            <span htmlFor="en_preparacion">Listo para Delivery</span>
                                                        </div>
                                                        <div>
                                                            <input type="radio" name='radio'  onChange={(checked)=>handleInputChange(checked)} value="en_punto_cliente" checked={state.filtros==='en_punto_cliente'}></input>
                                                            <span htmlFor="en_preparacion">En Punto de Entrega</span>
                                                        </div>
                                                        <div>
                                                            <input type="radio" name='radio' onChange={(checked)=>handleInputChange(checked)} value="entregado" checked={state.filtros==='entregado'}></input>
                                                            <span htmlFor="entregado">Entregado</span>
                                                        </div>
                                                        <div>
                                                            <input type="radio" name='radio' onChange={(checked)=>handleInputChange(checked)} value="rechazado" checked={state.filtros==='rechazado'}></input>
                                                            <span htmlFor="rechazado">Rechazados</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <div className="form-group">
                                                        <label>Nº Pedido</label>
                                                        <input className='form-control' name='numero_pedido' onKeyPress={onKeyUp} value={state.otros_filtros.numero_pedido} onChange={modificarInputChange}></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Codigo Cliente</label>
                                                        <input className='form-control' name='codigo_cliente' onKeyPress={onKeyUp} value={state.otros_filtros.codigo_cliente} onChange={modificarInputChange}></input>
                                                    </div>
                                                </div>
                                                <div className='col-sm-4'>
                                                    
                                                    <div className="form-group">
                                                        <label>Fecha</label>
                                                        <input type='date' className='form-control' name='fecha_actual' onKeyPress={onKeyUp} value={state.otros_filtros.fecha_actual} onChange={modificarInputChange}></input>
                                                    </div>
                                                    <button type="button"  onClick={obtenerRegistros} className="btn btn-primary ">
                                                        <FontAwesomeIcon icon={faSearch}  /> Buscar
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='table-responsive p-0'>
                                                <ListadoTable
                                                    registros={state.registros}
                                                    funcionMostrarPedido={mostrarPedido}
                                                    funcionMostrarOcultarModalCrear={mostrarOcultarModalCrear}
                                                    funcionMostrarRegularizarImagen={mostrarRegularizarImagen}
                                                    funcionRegularizarGuiaSunat={regularizarGuiaSunat}
                                                    funcionObtenerPedidos={obtenerRegistros}
                                                    funcionMostrarGuia = {mostrarGuia}
                                                    funcionRefrescar = {refrescarVista}
                                                    funcionRegularizarImagen = {regularizarImagenes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Mostrar 
                            data={state}
                            funcionMostrarPedido = {mostrarPedido}
                        />

                        <GuiaRemision 
                            data={state}
                            funcionMostrarPedido = {mostrarGuia}
                        />

                        <RegularizarImagen 
                            data={state}
                            funcionMostrarPedido = {mostrarRegularizarImagen}
                            funcionRegularizarImagen={regularizarImagenes}
                        />
                        

                    </section>
                </PageContent>
            </div>
        )

    }



    function obtenerRegistros(pageNumber = 1) {

            let parametros = {
                mostrar_inactivos: state.mostrar_inactivos,
                estado_pedido: state.filtros,
                numero_pedido: state.otros_filtros.numero_pedido,
                codigo_cliente: state.otros_filtros.codigo_cliente,
                fecha: state.otros_filtros.fecha_actual,
                codigo_as400 : !EsAdministrador() ? obtenerCodigoAs400() : '',
        };

        return new Promise((resolve, reject) => {
            //bloquear pantalla
            gestionarCarga(true)

            laravelAPI.get(`pedidos/obtenerpedidos?page=${pageNumber}`, { params: parametros })
                .then(function (response) {

                    //actualizar datos de registros
                    actualizarDatosDeRegistros(response.data);
                    //desbloquear pantalla
                    gestionarCarga(false)
                    resolve(response)

                })
                .catch(function (error) {
                    //desbloquear pantalla
                    gestionarCarga(false)
                    reject(null)
                })
        })

    }



    function actualizarDatosDeRegistros(data) {
        setState(previousState => {
            return { ...previousState, registros: data }
        });
    }

    /**
     * metodo encargado de refrescar la vista
     *
     */
    function refrescarVista() {

        console.log('refrescarVista')
        setState(previousState => {
            return { ...previousState, refrescar: state.refrescar + 1  }
        });
    }

    
    //craer funcion para que se ejecute al mostrar o ocultar modal 
    function mostrarOcultarModalCrear(vehiculo = null) {

        let valor = state.modal_crear.mostrar_modal ? false : true
        let formulario = {
                            id: null,
                            placa: '',
                            description: '',
                            modelo: '',
                            tipo: '',
                            marca: '',
                            color: '',
                            maxima_velocidad: '',
                            conductor_id: '',
                            sede: '',
                            capacidad_kilogramos: '',
                            capacidad_volumen: '',
                        }

        /* VALIDAR SI ES EL FORMULARIO DE EDICION */
        if (vehiculo) {
            formulario = {
                id: vehiculo.id,
                placa: vehiculo.placa,
                description: vehiculo.description,
                modelo: vehiculo.model,
                tipo: vehiculo.vehicle_type_id,
                marca: vehiculo.car_brands_id,
                color: vehiculo.color,
                sede: vehiculo.sede,
                capacidad_volumen: vehiculo.capacidad_volumen,
                capacidad_kilogramos: vehiculo.capacidad_kilogramos,
                maxima_velocidad: vehiculo.maximum_speed,
                conductor_id: vehiculo.driver_id ? vehiculo.driver_id : ''
            }
        }


        setState(previousState => {
            return {...previousState, modal_crear: {mostrar_modal: valor }, vehiculo: vehiculo, crear_registro: formulario }
        });

    }



    async function handleInputChange(event) {
        
        setState(previousState => {
            return { ...previousState, filtros: event.target.value, refrescar: state.refrescar + 1 }
        });
    }

    function modificarInputChange(event){

        setState(previousState => {
            return { ...previousState,  otros_filtros : { ... state.otros_filtros, [event.target.name] : event.target.value}}
        });

  
    }

    //craer funcion para que se ejecute al
    // mostrar o ocultar modal de pedido
    function mostrarPedido(pedido = null) {

        let valor = state.modal_pedido_detalles.mostrar_modal ? false : true

        setState(previousState => {
            return {...previousState, modal_pedido_detalles: {mostrar_modal: valor }, pedido: pedido }
        });

    }
    
    //craer funcion para que se ejecute al
    // mostrar o ocultar modal de guia
    function mostrarGuia(pedido = null) {

        let valor = state.modal_pedido.mostrar_modal ? false : true

        setState(previousState => {
            return {...previousState, modal_pedido: {mostrar_modal: valor }, pedido: pedido }
        });

    }

    function mostrarRegularizarImagen(pedido = null){
        let valor = state.modal_regularizar_imagen.mostrar_modal ? false : true

        setState(previousState => {
            return {...previousState, modal_regularizar_imagen: {mostrar_modal: valor }, pedido: pedido }
        });
    }

    function regularizarGuiaSunat(dispatch){
        console.log(dispatch);
        return new Promise((resolve, reject) => {
            //bloquear pantalla
            gestionarCarga(true)

            laravelAPI.get(`regularizarGuia/${dispatch}`)
                .then(function (response) {
                    console.log(response);
                    //desbloquear pantalla
                    gestionarCarga(false)
                    if (response.data.status==200) {
                        mostrarMensajeExito(response.data.mensaje)
                    }else{
                        mostrarMensajeAlertas(response.data.mensaje);
                    }
                    setState(previousState => {
                        return{ ...previousState,  refrescar : state.refrescar+1 }
                    });
                    //actualizar datos de registros
                    resolve(response)
                })
                .catch(function (error) {
                    //desbloquear pantalla
                    mostrarMensajeError(error.response)
                    reject(null)
                })
        })
    }

    function regularizarImagenes(e, customer_id, order_id, tipo){
        const data = new FormData();
        let file = e.target.files[0];
        let imagen_64 = file;
        let nombre_imagen =
        tipo == "peso" ? `imagen-${customer_id}` : `imagen-bulto-${customer_id}`;

        const progreso = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let precentage = Math.floor((loaded * 100) / total);

                if (precentage == 100) {
                modificarContadorImagenes(nombre_imagen, true);
                }
                setState((previousState) => {
                return {
                    ...previousState,
                    progress_bar: {
                    ...state.progress_bar,
                    [nombre_imagen]: precentage,
                    },
                };
                });
            },
        };

        data.append("order_id", order_id);
        data.append("customer_id", customer_id);
        data.append("imagen", imagen_64);
        data.append("tipo", tipo);

        laravelAPI
        .post(`despachos/guardar-imagen`, data, progreso)
        .then((res) => {
            console.log("imagen guardada");
        })
        .catch(function (error) {
            //desbloquear pantalla
            console.log(error);
        });
    }

    function modificarContadorImagenes(id, sumar) {
        if (sumar) {
          let arreglo = imagenes_cargadas;
          //validar si ya existe el id
          if (!arreglo.includes(id)) {
            arreglo.push(id);
    
            setModificarImagenes(arreglo);
          }
        }
    }

    async function onKeyUp(event) {
        if (event.charCode === 13) {
            //alert('hola'); 
        }
    }
    
}



export default Contenido;