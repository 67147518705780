import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock,faEye } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/logo.jpg';
//importar funciones helpers
import {mostrarMensajeError,mostrarMensajeExito,guardarToken,TokenValido } from '../../helpers'
//importar configuracion axio
import {laravelAPI}  from '../../config.js'


function Contenido() {
    //estatus
    const [state, setState] = useState({
        datos_usuario: {
            correo: null,
            clave: null,
        },
        errores_validacion: {
            clave: null,
            correo: null
        }
    });

    // Initialize a boolean state
    const [passwordShown, setPasswordShown] = useState(false);


    //funciones que se ejecutaran al iniciar el componente
    useEffect(async () => {
        // Taking reference of body element
        let bodyElement = document.getElementsByTagName('body')[0];

        // Changing the class of body Before mounting
        bodyElement.className = "hold-transition login-page";

    }, []);




    return (
        <div className="login-box">
            <div className="card card-outline card-primary">
                <div className="card-header">
                    <div className=' text-center'><img className="profile-user-img img-fluid img-circle" src={logo} alt="logo"></img></div>
                    <div className=' text-center h1'><b >MMTrack</b></div>
                </div>
                <div className="card-body">
                    <p className="login-box-msg">Ingresa tus Credenciales para Inicia Sesión </p>

                    <form method="post" onSubmit={iniciarSeccion}>
                        {state.errores_validacion.correo && <span className="form-text text-danger mb-1">{state.errores_validacion.correo[0]}</span>}
                        <div className="input-group mb-3">

                            <input type="email" className="form-control"  placeholder="Email" name='correo' onChange={handleInputChanger}></input>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={faEnvelope} />

                                </div>
                            </div>
                        </div>
                        {state.errores_validacion.clave && <span className="form-text text-danger mb-1">{state.errores_validacion.clave[0]}</span>}
                        <div className="input-group mb-3">
                            <input type={passwordShown ? "text" : "password"} className="form-control"  placeholder="Clave" name='clave' onChange={handleInputChanger}></input>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <a onClick={togglePassword}>
                                        <FontAwesomeIcon icon={faEye} color={passwordShown ? "#5e1d1d" : "#007bff"} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8">
                                
                            </div>

                            <div className="col-4">
                                <button type="submit" className="btn btn-primary btn-block">Ingresar</button>
                            </div>

                        </div>
                        <p className='text-center mt-3'>MMTrack ® – Copyright {new Date().getFullYear()}</p>
                    </form>

                </div>

            </div>

        </div>
    )


    function iniciarSeccion(e) {
        e.preventDefault()

        return new Promise((resolve, reject) => {

            laravelAPI.post('login', state.datos_usuario)
                .then(function (response) {
                    //mostrarMensajeExito('Inicio Exitoso')
                    guardarToken(response.data)

                    redireccionarPorRol(response.data.roles[0].name);
                   
                    
                    resolve(response)

                })
                .catch(function (error) {
                   
                    if (error.response.status == 422) {
                        //actualizar los errores
                        setState(previousState => {
                            return { ...previousState, errores_validacion: error.response.data }
                        });
                    }
                    if (error.response.status == 401) {
                        //actualizar los errores
                        setState(previousState => {
                            return { ...previousState, errores_validacion: {
                                clave: null,
                                correo: null
                            }}
                        });
                    }
                    
                    mostrarMensajeError(error.response)

                    reject(null)
                })
        })

    }

    function redireccionarPorRol(rol) {

        switch (rol) {
            case 'Vendedor':
                window.location.replace('/pedidos');
                
                break;
            case 'Conductor':
                window.location.replace('/entrega/pedidos');
                
                break;
            case 'Ayudante':
                window.location.replace('/entrega/pedidos');
                
                break;
        
            default:
                window.location.replace('');

                break;
        }
    }

    function handleInputChanger(event) {

        setState(previousState => {
            return { ...previousState, datos_usuario: { ...state.datos_usuario, [event.target.name]: event.target.value } }
        });


    }

  // Password toggle handler
  function togglePassword(){
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };



}

export default Contenido;