import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faEye,faCheck,faCar,faCarSide } from '@fortawesome/free-solid-svg-icons';
import imagen_vehiculo from '../../assets/images/vehiculo.png';
import imagen_sin_foto from '../../assets/images/logo.jpg';
import { fomatearFecha } from '../../helpers'
import DetalleProceso from '../panelCliente/DetalleProceso';
import StarRatings from 'react-star-ratings';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
//importar configuracion axio
import { laravelAPI } from '../../config.js'

//importar funciones helpers
import { obtenerEscala,confirmarVehiculo,mostrarMensajeExito,validarExistenciaDePermiso,mensajeDeConfirmacion } from '../../helpers'



class MostrarVehiculo extends Component {

    registros = [];

    render() {
        this.registros = []

        let data = this.props.data
        let vehiculo = this.props.data.pedido
        let mostrar_modal = data.modal_vehiculo.mostrar_modal
         
        if (!vehiculo || !mostrar_modal) {
            return <></>
        }
         
        let pedidos_entregados = vehiculo.pedidos.filter(pedido => pedido.status_order == "Entregado")

        let pedidos_en_ruta = vehiculo.pedidos.filter(pedido => pedido.status_order == "En Ruta" ||pedido.status_order == "Punto de Destino" )

        let pedidos_pendientes = vehiculo.pedidos.filter(pedido => pedido.status_order == "Despachado")
 
        let titulo = `Datos del Pedido`


        return (
            < >
                <Modal
                    show={mostrar_modal}
                    onHide={() => this.props.funcionMostrarPedido(null)}
                    backdrop="static"
                    keyboard={false}
                    size='xl'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <div className="card card-widget widget-user-2">
                                    <div className="widget-user-header bg-success">
                                        <div className="widget-user-image">
                                            <img className="img-circle elevation-2" src={imagen_vehiculo} alt="User Avatar"></img>
                                        </div>
                                        <h3 className="widget-user-username">Listado</h3>
                                        <h5 className="widget-user-desc">Pedidos Entregados</h5>
                                    </div>
                                    <div className="card-footer p-0">
                                        <ul className="nav flex-column">
                                            {
                                                pedidos_entregados.map((registro,key) =>{
                                                    return(
                                                            <li className="nav-item"  key={key}>
                                                                <div className='p-2'>
                                                                    <span className=''><b>Cliente</b> {registro.pedido.customer_code} </span>
                                                                    <span className='ml-2'><b>Pedido</b> <a onClick={() => this.props.funcionMostrarTrackingPedido(registro.pedido)} style={{'cursor':'pointer','color':'blue'}}> {registro.pedido.document_order_number} </a></span>
                                                                </div>
                                                            </li>
                                                    )
                                                })
                                            }
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className="card card-widget widget-user-2">
                                    <div className="widget-user-header bg-warning">
                                        <div className="widget-user-image">
                                            <img className="img-circle elevation-2" src={imagen_vehiculo} alt="User Avatar"></img>
                                        </div>
                                        <h3 className="widget-user-username">Listado</h3>
                                        <h5 className="widget-user-desc">Pedidos en Ruta</h5>
                                    </div>
                                    <div className="card-footer p-0">
                                        <ul className="nav flex-column">
                                            {
                                                pedidos_en_ruta.map((registro,key) =>{
                                                    return(
                                                            <li className="nav-item"  key={key}>
                                                                <div className='p-2'>
                                                                    <span className=''><b>Cliente</b> {registro.pedido.customer_code} </span>
                                                                    <span className='ml-2'><b>Pedido</b> <a onClick={() => this.props.funcionMostrarTrackingPedido(registro.pedido)} style={{'cursor':'pointer','color':'blue'}}> {registro.pedido.document_order_number} </a></span>
                                                                </div>
                                                            </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className="card card-widget widget-user-2">
                                    <div className="widget-user-header bg-danger">
                                        <div className="widget-user-image">
                                            <img className="img-circle elevation-2" src={imagen_vehiculo} alt="User Avatar"></img>
                                        </div>
                                        <h3 className="widget-user-username">Listado</h3>
                                        <h5 className="widget-user-desc">Pedidos por Entregar</h5>
                                    </div>
                                    <div className="card-footer p-0">
                                        <ul className="nav flex-column">
                                            {
                                                pedidos_pendientes.map((registro,key) =>{
                                                    return(
                                                            <li className="nav-item" key={key}>
                                                                <div className='p-2'>
                                                                    <span className=''><b>Cliente</b> {registro.pedido.customer_code} </span>
                                                                    <span className='ml-2'><b>Pedido</b>  <a onClick={() => this.props.funcionMostrarTrackingPedido(registro.pedido)} style={{'cursor':'pointer','color':'blue'}}> {registro.pedido.document_order_number} </a> </span>
                                                                    {this.validarDespacho(registro.pedido.id,registro.id, vehiculo)}
                                                                </div>
                                                            </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.props.funcionMostrarPedido(null)} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }


    //obtener los registros de pedido
    obtenerRegistroDeProcesoDePedido(detalle) {

        let descripcion = detalle.transit.description_web
        //validar si ya se agrego dicho detalle
        const result = this.registros.filter(registro => registro == descripcion);

        //si ya se agrego salimos de la funcion
        if (result.length > 0) {
            return null
        }

        //si no existe lo agregamos al arreglo
        this.registros.push(descripcion);

        return {
            id: detalle.id,
            titulo_estado: descripcion,
            fecha: `${detalle.date_of_work} ${detalle.work_time}`,
            descripcion: descripcion + ' de Manera Exitosa.',
            estado: 'Aprobado'
        };

    }

    validarDespacho(dispatch_id,vehicles_id,vehiculo){
  

            return (<> 
                    
                    {
                        validarExistenciaDePermiso(['generar-transbordo']) &&
                        <>
                        <button title='Generar Transbordo' onClick={async()=>{
                                                    await this.generarTransbordo(dispatch_id,vehiculo)
                                                    this.props.funcionRefrescar()
                                                    }
                                        } className='float-right btn-xs btn btn-sm btn-primary ml-2'>
                            <FontAwesomeIcon icon={faCarSide}  />
                        </button>
                        <button title='Descargar Unidad' onClick={async()=>{ await this.descargarUnidad(dispatch_id,vehicles_id)
                                                                                    this.props.funcionRefrescar()
                                                }
                                        } className='float-right btn-xs btn btn-sm btn-danger ml-2'>
                            <FontAwesomeIcon icon={faCarSide}  />
                        </button>
                        </>
                    }
                    </>)
      

        return <span className='badge badge-danger'> Sin Despacho</span>

    }

    
  async generarTransbordo(pedido_id,vehiculo){
    this.props.funcionMostrarPedido(vehiculo)

    let confirmacion = await confirmarVehiculo('Si Desea Generar un Transbordo Seleccione el Vehiculo')

    if(confirmacion){
      return new Promise((resolve, reject) => {

        laravelAPI.get(`despachos/registrarTransbordo`, { params: {vehiculo_id:confirmacion, pedido_id:pedido_id} })
            .then(function (response) {

                mostrarMensajeExito(response.mensaje)
                 

                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
      })
    }

    this.props.funcionMostrarPedido(vehiculo)


  }
  
  async descargarUnidad(pedido_id,vehiculo_id){
    let confirmacion = await mensajeDeConfirmacion('Si Desea descargar el Vehiculo')

    if(confirmacion){
      return new Promise((resolve, reject) => {

        laravelAPI.get(`despachos/registrarDescarga`, { params: {pedido_id:pedido_id,vehiculo_id:vehiculo_id} })
            .then(function (response) {

                mostrarMensajeExito(response.mensaje)
                this.props.funcionMostrarPedido(null)

                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
      })
    }


  }

}
 
export default MostrarVehiculo;