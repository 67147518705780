import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { fomatearFecha, obtenerEstado } from '../../helpers'
import Pagination from "react-js-pagination";

class ListadoTable extends Component {

  //renderizar componente
  render() {
    let registros = this.props.registros;
    let usuarios = null;
    // let showLicencia = false;
    /* validar que existan registros */
    if (registros) {
      usuarios = registros.usuarios;
    }
    return (
        <>
          <table className="table table-head-fixed text-nowrap table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Codigo</th>
                <th>Usuario</th>
                <th>Correo</th>
                <th>Fech. Nac.</th>
                <th>E. AS400</th>
                <th>E. MMTrack</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                  /* validar que existan registros */
                  this.props.registros &&
                    /* iterar los registros para la tabla */
                    usuarios.data.map((usuario, key) =>{
                      return(
                        <tr key={usuario.id}>
                          <td>{key+1}</td>
                          <td>{usuario.code}</td>
                          <td>{usuario.nombre_completo}</td>
                          <td>{usuario.email}</td>
                          <td>{usuario.birthdate}</td>
                          <td>{obtenerEstado(usuario.reg_status)}</td>
                          <td>{obtenerEstado(usuario.user ? 1 : 0)}</td>
                          
                          <td>
                            <div>
                              <button  title='Activar o Desactivar' className='btn btn-xs btn-success mr-2' onClick={()=>{this.props.funcionMostrarOcultarModalCrear(usuario, usuario.user)}}>
                                <FontAwesomeIcon icon={faCheck} color="white" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                }
               
            </tbody>
          </table>
          <div className='mt-4'>
          <Pagination 
            activePage={usuarios ? usuarios.current_page : 0}
            itemsCountPerPage={usuarios ? usuarios.per_page : 0}
            totalItemsCount={usuarios ? usuarios.total : 0}
            
            onChange={(pageNumber) => {
              this.props.funcionObtenerUsuarios(pageNumber)
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="Primera página"
            lastPageText="Último página"
          />
          </div>
        </>

    )

  }



}

export default ListadoTable;
