import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faEye,faCheck,faCar,faCarSide,faFileUpload,faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";

//importar funciones helpers
import {EsAdministrador,obtenerEstadoAccion,obtenerEstadoPedido,validarExistenciaDePermiso,obtenerEstado,fomatearFecha,mensajeDeConfirmacion,confirmarVehiculo,mostrarMensajeExito } from '../../helpers'
//importar configuracion axio
import { laravelAPI } from '../../config.js'

class ListadoTable extends Component {

  //renderizar componente
  render() {
    let pedidos = null;
    let is_admin = EsAdministrador();
    console.log(is_admin);
    /* validar que existan registros */
    if (this.props.registros) {
      pedidos = this.props.registros.pedidos;
    }
    return (
      <>
         <table className="table table-head-fixed text-nowrap table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Mostrar</th>
                <th>Núm. Pedido</th>
                <th>Cod. Cliente</th>
                <th>Cliente</th>
                <th>Doc. Cliente</th>
                <th>Fecha</th>
                <th>Tracking</th>
                <th>Despachado</th>
                <th title='Tipo de Entrega'>T. Entrega</th>
                <th>Vendedor</th>
                <th>Estatus</th>
                {
                  is_admin ? (<th>Accion</th>) : ''
                }
              </tr>
            </thead>
            <tbody>
              {
                /* validar que existan registros */
                this.props.registros &&
                  /* iterar los registros para la tabla */
                  this.props.registros.pedidos.data.map((pedido, key) =>{
                    return(
                      <tr key={pedido.id}>
                        <td>{key+1}</td>
                        <td>
                          <div>
                            <button title='Visualizar' className='btn btn-xs btn-success mr-2' onClick={()=>{this.props.funcionMostrarPedido(pedido)}}><FontAwesomeIcon icon={faEye} color="white" /></button>
                          </div>
                        </td>
                        <td><a  title='Mostrar Guia' href='#' onClick={()=>{this.props.funcionMostrarGuia(pedido)}}>{pedido.document_order_number}</a></td>
                        <td>{pedido.client?pedido.client.code:''}</td>
                        <td>{pedido.social_reason}</td>
                        <td>{pedido.client.document?pedido.client.document.abrv:''} {pedido.client.document_number}</td>
                        <td>{fomatearFecha(pedido.date_of_work,true)}</td>
                        <td>{pedido.tracking ? pedido.tracking.transit.description_web : ''}</td>
                        <td>{this.validarDespacho(pedido.tracking)}</td>   
                        <td>{this.obtenerTipoEntrega(pedido.tracking)}</td>
                        <td>{pedido.served_by}</td>
                        <td>{obtenerEstadoPedido(pedido.registration_status)}</td>
                        {
                          is_admin ? (
                            <td>
                              <div>
                                <button title='Regularizar Imagenes' className='btn btn-xs btn-success mr-2' onClick={()=>{this.props.funcionMostrarRegularizarImagen(pedido)}}><FontAwesomeIcon icon={faFileUpload} color="white" /></button>
                                <button title='Regularizar Guia Sunat' className='btn btn-xs btn-success mr-2' onClick={()=>{this.props.funcionRegularizarGuiaSunat(pedido.id)}}><FontAwesomeIcon icon={faPaperPlane} color="white" /></button>
                              </div>
                            </td>
                          ) : ''
                        }
                      </tr>
                    )
                  })
              }
               
            </tbody>
          </table>
          <div className='mt-4'>
            <Pagination 
              activePage={pedidos ? pedidos.current_page : 0}
              itemsCountPerPage={pedidos ? pedidos.per_page : 0}
              totalItemsCount={pedidos ? pedidos.total : 0}
              
              onChange={(pageNumber) => {
                this.props.funcionObtenerPedidos(pageNumber)
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="Primera página"
              lastPageText="Último página"
            />
          </div>
      </>
    )

  }

  validarDespacho(tracking) {
 
    if (tracking) {
      if (tracking.vehicles_id) {

        return (<><span className='badge badge-info'>Despachado </span>
                  
                  {
                    tracking.transit_status_id != 32 &&
                      tracking.transit_status_id != 31 &&
                      validarExistenciaDePermiso(['generar-transbordo']) &&
                      <>
                      <button title='Generar Transbordo' onClick={async()=>{
                                                  await this.generarTransbordo(tracking.dispatch_id)
                                                  this.props.funcionRefrescar()
                                                }
                                      } className='btn btn-sm btn-primary ml-2'>
                        <FontAwesomeIcon icon={faCarSide}  />
                      </button>
                      <button title='Descargar Unidad' onClick={async()=>{ await this.descargarUnidad(tracking.dispatch_id,tracking.vehicles_id)
                                                  this.props.funcionRefrescar()
                                            }
                                      } className='btn btn-sm btn-danger ml-2'>
                        <FontAwesomeIcon icon={faCarSide}  />
                      </button>
                      </>
                  }
                </>)
      }
    }

    return <span className='badge badge-danger'> Sin Despacho</span>

  }

  obtenerTipoEntrega(tracking){

    //pedidos delivery en lima 
    if (tracking.carrier_code =='003174') {

      return <span> Despacho Lima</span>

    }

    //pedidos delivery en provincia 
    if (tracking.carrier_code !='003174' && tracking.carrier_code !='003151') {
      
      return <span> Despacho Provincia</span>

    }

    //pedidos recoje el cliente
    if (tracking.carrier_code =='003151') {
      
      return <span> Recojo Cliente</span>

    }
    

  }

  async generarTransbordo(pedido_id){
    let confirmacion = await confirmarVehiculo('Si Desea Generar un Transbordo Seleccione el Vehiculo')

    if(confirmacion){
      return new Promise((resolve, reject) => {

        laravelAPI.get(`despachos/registrarTransbordo`, { params: {vehiculo_id:confirmacion, pedido_id:pedido_id} })
            .then(function (response) {

                mostrarMensajeExito(response.mensaje)
                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
      })
    }


  }
  
  async descargarUnidad(pedido_id,vehiculo_id){
    let confirmacion = await mensajeDeConfirmacion('Si Desea descargar el Vehiculo')

    if(confirmacion){
      return new Promise((resolve, reject) => {

        laravelAPI.get(`despachos/registrarDescarga`, { params: {pedido_id:pedido_id,vehiculo_id:vehiculo_id} })
            .then(function (response) {

                mostrarMensajeExito(response.mensaje)

                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
      })
    }


  }

}

export default ListadoTable;
