import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import imagen_vehiculo from '../../assets/images/vehiculo.png';



class Mostrar extends Component {



    render() {

        let data = this.props.data
        let vehiculo = this.props.data.vehiculo
        
        if (!vehiculo) {
            return <></>
        }

        let titulo = `Datos del Vehiculo`
        let mostrar_modal = data.modal_vehiculo.mostrar_modal

        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.props.funcionMostrarVehiculo(null)}
                    backdrop="static"
                    keyboard={false}
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="card card-widget widget-user-2">
                            <div className="widget-user-header bg-info">
                                <div className="widget-user-image">
                                    <img className="img-circle elevation-2" src={imagen_vehiculo} alt="User Avatar"></img>
                                </div>
                                <h3 className="widget-user-username">{vehiculo.placa}</h3>
                                <h5 className="widget-user-desc">{vehiculo.tipo.name}</h5>
                            </div>
                            <div className="card-footer p-0">
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <a  className="nav-link">
                                            Marca <span className="float-right text-dark">{vehiculo.marca ? vehiculo.marca.name : ''}</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a  className="nav-link">
                                            Conductor <span className="float-right text-dark">{vehiculo.conductor ? vehiculo.conductor.names : ''} {vehiculo.conductor ? vehiculo.conductor.surnames : ''}</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a  className="nav-link">
                                            Placa <span className="float-right text-dark">{vehiculo.placa}</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a  className="nav-link">
                                            Descripcion <span className="float-right text-dark">{vehiculo.description}</span>
                                        </a>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <a  className="nav-link">
                                            Modelo <span className="float-right text-dark">{vehiculo.model}</span>
                                        </a>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <a  className="nav-link">
                                            Color <span className="float-right text-dark">{vehiculo.color}</span>
                                        </a>
                                    </li>                                    
                                    <li className="nav-item">
                                        <a  className="nav-link">
                                            Maxima Velocidad <span className="float-right text-dark">{vehiculo.maximum_speed}</span>
                                        </a>
                                    </li>                                    
                                    <li className="nav-item">
                                        <a  className="nav-link">
                                            Estatus <span className="float-right text-dark">{vehiculo.status==1 ? 'Activo' : 'Inactivo'}</span>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.props.funcionMostrarVehiculo(null)} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}




export default Mostrar;