import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { fomatearFecha, obtenerEstado, } from '../../helpers'
import Pagination from "react-js-pagination";

class ListadoTable extends Component {

  //renderizar componente
  render() {
    let registros = this.props.registros;
    let agencias = null;
    /* validar que existan registros */
    if (registros) {
      agencias = registros.agencias;
    }
    return (
        <>
          <table className="table table-head-fixed text-nowrap table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Codigo</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Sucursales</th>
                <th>E. MMTrack</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                  /* validar que existan registros */
                  this.props.registros &&
                    /* iterar los registros para la tabla */
                    agencias.data.map((agencia, key) =>{
                      return(
                        <tr key={agencia.id}>
                          <td>{key+1}</td>
                          <td>{agencia.code}</td>
                          <td>{agencia.name}</td>
                          <td>{agencia.description}</td>
                          <td>{agencia.sucursales_count == 0 ?
                                <span className='badge badge-danger'>Sin Sucursales</span> :
                                 
                                  <button  title='Ver Sucursales' className='btn btn-xs btn-info mr-2 p-2' onClick={()=>{this.props.funcionMostrarOcultarModalSucursales(agencia)}}>
                                      Ver Sucursales
                                  </button>
                                 
                              }
                          </td>
                          <td>{obtenerEstado(agencia.reg_status)}</td>
                          
                          <td>
                            <div>
                              <button  title='Activar o Desactivar' className='btn btn-xs btn-success mr-2' onClick={()=>{this.props.funcionMostrarOcultarModalCrear(agencia)}}>
                                <FontAwesomeIcon icon={faCheck} color="white" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                }
               
            </tbody>
          </table>
          <div className='mt-4'>
          <Pagination 
            activePage={agencias ? agencias.current_page : 0}
            itemsCountPerPage={agencias ? agencias.per_page : 0}
            totalItemsCount={agencias ? agencias.total : 0}
            
            onChange={(pageNumber) => {
              this.props.funcionObtenerAgencias(pageNumber)
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="Primera página"
            lastPageText="Último página"
          />
          </div>
        </>

    )

  }



}

export default ListadoTable;
