import Swal from 'sweetalert2'
import moment from 'moment'
import withReactContent from 'sweetalert2-react-content'
import { laravelAPI,laravelAPISinc } from './config.js'


const MySwal = withReactContent(Swal)

/**
 * metodo encargado de mostrar los mensajes de notificaciones 
 * exitosas
 * @param {*} mensaje 
 */
 export async function mostrarMensajeExito(mensaje) {

    await MySwal.fire({
        title: <strong>Confirmado</strong>,
        html: <i>{mensaje}</i>,
        icon: 'success'
    })    
}
/**
 * metodo encargado de mostrar los mensajes de notificaciones 
 * exitosas
 * @param {*} mensaje 
 */
 export async function mostrarMensajeAlertas(mensaje) {

    await MySwal.fire({
        title: <strong>Alerta</strong>,
        html: <i>{mensaje}</i>,
        icon: 'warning'
    })    
}

/**
 * metodo encargado de mostrar los mensajes de notificaciones 
 * exitosas
 * @param {*} mensaje 
 */
export async function mostrarMensajeAlerta(mensaje) {

    await MySwal.fire({
        title: <strong>Confirmado</strong>,
        html: <i>{mensaje}</i>,
        icon: 'warning'
    })    
}

/**
 * metodo encargado de cerrar el menu lateral
 */
export async function cerrarMenuLateral() {
    
    document.body.classList.remove('sidebar-open');
    document.body.classList.remove('modal-open');
    document.body.classList.add('sidebar-closed');
    document.body.classList.add('sidebar-collapse');

}


export async function mostrarMensajeError(err){

    console.log(err);
    switch (err.status) {
        case 422:
            await MySwal.fire({
                title: <strong>Error En Validacion</strong>,
                html: 'Datos de Formulario con errores.',
                icon: 'error'
            })  

            break;
        case 403:
            await MySwal.fire({
                title: <strong>Error</strong>,
                html: err.responseJSON.mensaje,
                icon: 'error'
            })  
             


            break;

        case 500:
            await MySwal.fire({
                title: <strong>Error</strong>,
                html: 'Ocurrió un error interno',
                icon: 'error'
            })  

          

        case 404:
            await MySwal.fire({
                title: <strong>Error</strong>,
                html: err.responseJSON.mensaje ? err.responseJSON.mensaje : 'Recurso no encontrado',
                icon: 'error'
            })  

 
        case 401:
            await MySwal.fire({
                title: <strong>Error</strong>,
                html: err.data.mensaje ? err.data.mensaje : 'Recurso no encontrado',
                icon: 'error'
            })             

            break;
        default:
            console.log(err);
    }


}


export function mensajeDeConfirmacion(mensaje) {
    
    return new Promise((resolve, reject)=>{
        MySwal.fire({
            title: 'Confirmar?',
            text: mensaje,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si Seguro!'
        }).then((result) => {
            if (result.isConfirmed) {
                resolve(true)
            }else{
                resolve(false)

            }
        })
    })
}


export async function confirmarVehiculo(titulo) {

    //obtener vehiculos activos 
    let vehiculos = await obtenerVehiculosActivos();
    //this i assume would be loaded via ajax or something?
    var myArrayOfThings = {}
    
    if (vehiculos.data) {
        vehiculos.data.map(vehiculo => {return myArrayOfThings[vehiculo.id] = vehiculo.placa})
    }
    
    return new Promise((resolve, reject)=>{

        MySwal.fire({
            title: titulo,
            input: 'select',
            inputAttributes: {
                autocapitalize: 'off'
            },
            inputOptions: myArrayOfThings,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                resolve(result.value);
            } 
            resolve(null);

        })
    })
}


export async function confirmarAyudante(titulo) {

    //obtener ayudantes activos 
    let ayudantes = await obtenerAyudantesActivos();
    //this i assume would be loaded via ajax or something?
    var myArrayOfThings = {}
    
    if (ayudantes.data) {
        ayudantes.data.map(ayudante => {return myArrayOfThings[ayudante.id] = ayudante.names+" "+ayudante.surnames})
    }
    
    return new Promise((resolve, reject)=>{

        MySwal.fire({
            title: titulo,
            input: 'select',
            inputAttributes: {
                autocapitalize: 'off'
            },
            inputOptions: myArrayOfThings,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                let ayudante = ayudantes.data.filter(ayudante => ayudante.id ==result.value)
                resolve(ayudante[0]);
            } 
            resolve(null);

        })
    })
}

export  function obtenerEstadoAccion(estado){

    switch (estado) {
        case 'PRE-ASIGNADO':
            
            return <span className="badge bagde-pull badge-success">{estado}</span>
             
        case 'Disponible':
            
           return <span className="badge bagde-pull badge-success">{estado}</span>
            
        case 'En Ruta':
           
           return <span className="badge bagde-pull badge-primary">{estado}</span>


        case 'Ocupado':
           return <span className="badge bagde-pull badge-danger">{estado}</span>


        case 'Al-Almacen':
        return <span className="badge bagde-pull badge-warning">{estado}</span>
         

        case 'En Taller':
            return <span className="badge bagde-pull badge-warning">En el Taller</span>
           
           
       default:
           console.log(estado);
   }


}


export  function obtenerEstado(estado){

    switch (estado) {
       case 1:
            
           return <span className="badge bagde-pull badge-success">Activo</span>
            
       case 0:
           
           return <span className="badge bagde-pull badge-danger">Inactivo</span>

           
       default:
           console.log(estado);
   }


}

export  function obtenerEstadoPedido(estado){

     switch (estado) {
        case "A":
             
            return <span className="badge bagde-pull badge-success">Aceptado</span>
             
        case "I":
            
            return <span className="badge bagde-pull badge-danger">Rechazado</span>

            
        default:
            console.log(estado);
    }


}


export function fomatearFecha(fecha_completa,fecha_corta=false) {
   
    if (fecha_corta) {

        return moment(fecha_completa).format('DD-MM-YYYY ');
 
    }

    return moment(fecha_completa).format('DD-MM-YYYY H:mm:ss');
    
}


 
 

export function guardarToken(data) {
   
    localStorage.setItem("token", data.access_token)
    localStorage.setItem("token_type", data.token_type)
    localStorage.setItem("nombre_usuario", data.user)
    localStorage.setItem("fecha_expiracion", data.expires_date)
    localStorage.setItem("vehiculo_id", data.vehiculo_id)
    localStorage.setItem("codigo_as400", data.codigo_as400)
    localStorage.setItem("permisos", JSON.stringify(data.permisos.map(permiso =>{return permiso.name})))
    localStorage.setItem("roles", JSON.stringify(data.roles.map(rol =>{return rol.name})))
    localStorage.setItem("usuario_id", data.usuario_id)
    localStorage.setItem("ayudante_id", data.ayudante_id)
    
    
}

export function cambiarVehiculoConductor(id) {
    
    localStorage.setItem("vehiculo_id", id)
    window.location.replace('');
}

export function TokenValido() {
    let token = null
    //validar que exista un token
    if(localStorage.hasOwnProperty('token')){
        
        //validar que aun no expira el token
        let fecha = localStorage.getItem('fecha_expiracion')
        let esMenorFechaActual = new Date() < new Date(fecha);

        if (esMenorFechaActual) {
            
            token = `${localStorage.getItem('token_type')} ${localStorage.getItem('token')}`
            
        }else{

            localStorage.removeItem('token');
            localStorage.removeItem('usuario_id');
            localStorage.removeItem('ayudante_id');
            localStorage.removeItem('permisos');
            localStorage.removeItem('vehiculo_id');
            localStorage.removeItem('roles');
            localStorage.removeItem('codigo_as400');

        }
        
    }
    
    return token;
}


export async function eliminarToken(){
    
    let esConfirmar = await mensajeDeConfirmacion('Seguro que Desea Cerrar Sesión')

    if(!esConfirmar) return

    //validar que exista un token
    if(localStorage.hasOwnProperty('token')){
        localStorage.removeItem('token');
        localStorage.removeItem('usuario_id');
        localStorage.removeItem('ayudante_id');
        localStorage.removeItem('permisos');
        localStorage.removeItem('vehiculo_id');
        localStorage.removeItem('codigo_as400');
        window.location.replace('');
    }
}
 
 
function obtenerVehiculosActivos() {
    return new Promise((resolve, reject) => {

        laravelAPI.get(`vehiculos/obtenerVehiculosActivos`)
            .then(function (response) {
 
                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
    })
} 

function obtenerAyudantesActivos() {
    return new Promise((resolve, reject) => {

        laravelAPI.get(`ayudantes/obtener-ayudantes-activos`)
            .then(function (response) {
 
                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
    })
}
export const fechaActual =  new Date().getFullYear()+"-"+zfill((new Date().getMonth() + 1),2)+"-"+zfill(new Date().getDate(),2)
  
export const horaActual = zfill(new Date().getHours(),2)+":"+zfill(new Date().getMinutes())

 
function zfill(number, width) {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */ 
    var zero = "0"; /* String de cero */  
    
    if (width <= length) {
        if (number < 0) {
             return ("-" + numberOutput.toString()); 
        } else {
             return numberOutput.toString(); 
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString()); 
        }
    }
}


export function validarExistenciaDePermiso(permiso) {
  
    let permisoValido = false 

    //validar que exista un array de permisos
    if(localStorage.hasOwnProperty('permisos')){
        let permisos = localStorage.getItem('permisos')
        
        permiso.forEach(element => {
            if (!permisoValido) {
                // validar si el permiso existe
                permisoValido = permisos.includes(element)
            }
        });
       
        return permisoValido
    }

    return permisoValido
}


export function obtenerCodigoAs400() {
    //validar que exista un array de permisos
    if(localStorage.hasOwnProperty('codigo_as400')){
        let codigo = localStorage.getItem('codigo_as400')
    
        return codigo
    }

    return ""
}

export function EsAdministrador() {
    //validar que exista un array de permisos
    if(localStorage.hasOwnProperty('roles')){
        let roles = localStorage.getItem('roles')
        
        if (!roles.includes('Administrador')) {
           
            return roles.includes('Supervisor')
 
        }else{

            return roles.includes('Administrador')
        }
    }

   
    return ""
}



export function obtenerVehiculoId() {
    //validar que exista un array de permisos
    if(localStorage.hasOwnProperty('vehiculo_id')){
        let codigo = localStorage.getItem('vehiculo_id')
    
        return codigo
    }

    return null
}

export function obtenerUsuarioId() {
    //validar que exista un array de permisos
    if(localStorage.hasOwnProperty('usuario_id')){
        let codigo = localStorage.getItem('usuario_id')
    
        return codigo
    }

    return null
}

export function obtenerAyudanteId() {
    //validar que exista un array de permisos
    if(localStorage.hasOwnProperty('ayudante_id')){
        let codigo = localStorage.getItem('ayudante_id')
    
        return codigo
    }

    return null
}

export function verificarRolAyudante() {
    //validar que exista un array de ayudante
    if(localStorage.hasOwnProperty('roles')){
        let roles = localStorage.getItem('roles')
        
        if (roles.includes('Ayudante')) {
           
            return true
 
        }else{

            return false
        }
    }

   
    return ""
}

/* export function sincronizarAs400() {
    return new Promise((resolve, reject) => {

        laravelAPISinc.get(`sincronizar/as400`)
            .then(function (response) {
                
                mostrarMensajeExito("Sincronizado Exitoso.");
                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
    })
} */

export function obtenerNombreUsuario() {
    //validar que exista un array de permisos
    if(localStorage.hasOwnProperty('nombre_usuario')){
        let nombre_usuario = localStorage.getItem('nombre_usuario')
    
        return nombre_usuario
    }

    return ""
}
    


async function cambiarClave() {
    return new Promise((resolve, reject)=>{

        MySwal.fire({
            title: 'Para Cambiar Clave Ingrese Su Nueva Clave',
            input: 'password',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                resolve(result.value);
            } 
            resolve(null);

        })
    })
}

export async function cambiarClaveUsuario(nombre=null) {
 
    let clave = await cambiarClave()
    let usuario = await obtenerNombreUsuario()

    let nuevo_nombre = nombre ? nombre : usuario


    if (clave && clave!="") {
        let params = {
            clave:clave,
            nombre: nuevo_nombre
        }

        await serverCambiarClave(params);
        
    }else{
        mostrarMensajeAlerta("Debe ingresar su nueva clave");
    }
}

function serverCambiarClave(params) {

    return new Promise((resolve, reject) => {

        laravelAPI.get(`usuario/cambiar-clave`,{ params :  params })
            .then(function (response) {
                
                mostrarMensajeExito("Cambio Exitoso.");
                resolve(response)

            })
            .catch(function (error) {
                reject(error)
            })
    })
}


export function obtenerEscala (valor){

    switch (valor) {
      case 0:
        return "Sin Calificar"; 

      case 1:
        return "Malo"; 
    
      case 2:
        return "Muy Malo"; 

      case 3:
        return "Aceptable"; 

      case 4:
        return "Bueno"; 
        
      case 5:
        return "Muy bueno"; 
                          
      default:
        break;
    }
  }

 
 
 