import React, { useState, useEffect } from 'react';
import ListadoTable from './ListadoTable';
import { PageContent } from 'react-adminlte-3'
import EncabezadoModulo from './EncabezadoModulo';
import Crear from './Crear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
//importar funciones helpers
import { mostrarMensajeExito, cerrarMenuLateral, fomatearFecha,mostrarMensajeError,validarExistenciaDePermiso } from '../../helpers'
//importar configuracion axio
import { laravelAPI } from '../../config.js'

function Contenido() {

    //estatus
    const [state, setState] = useState({
        registros: null,
        titulo_modulo: 'Usuarios',
        refrescar: 0,
        otros_filtros: {
            busqueda: '',

        },
        crear_registro : {
            id: null,
            rol:'',
            password:'',
            name:'',
            email:'',
            user_as400_id : '',
            licencia:'',
            roles : []
        },
        modal_crear : { mostrar_modal: false},
        usuario:null,  
        errores_validacion : { 
            rol:'',
            password:'',
            name:'',
            email:'',
            user_as400_id : '',
            roles : []

        },
        showLicencia: false

    });

    //funciones que se ejecutaran al iniciar el componente
    useEffect(async () => {
        //cerrar menu lateral
        cerrarMenuLateral()

        await obtenerRegistros();

    }, [state.refrescar]);



    let titulo_modulo = state.titulo_modulo

    return (
        <div>
            <EncabezadoModulo
                titulo_modulo={titulo_modulo}
            />

            <PageContent>
                <section className="content">

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                         
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <div className="form-group">
                                                    <label>Buscar</label>
                                                    <input className='form-control' name='busqueda' onKeyPress={onKeyUp} value={state.otros_filtros.busqueda} onChange={modificarInputChange}></input>
                                                </div>
                                            </div>
                                            <div className='col-sm-4 my-auto'>
                                                <button type="button" onClick={obtenerRegistros} className="btn btn-primary ">
                                                    <FontAwesomeIcon icon={faSearch} /> Buscar
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className='table-responsive p-0 w-100'>
                                            <ListadoTable 
                                                registros={state.registros}
                                                funcionObtenerPedidos={obtenerRegistros}
                                                funcionMostrarOcultarModalCrear = {mostrarOcultarModalCrear}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Crear 
                        data={state}
                        funcionMostrarOcultarModalCrear = {mostrarOcultarModalCrear}
                        funcionGuardarRegistrosUsiario  = {guardarRegistrosUsiario}
                        funcionHandleInputChanger = {handleInputChange}
                        funcionHandleCheckboxChange = {handleCheckboxChange}
                        
                    />

                </section>
            </PageContent>
        </div>
    )


    function obtenerRegistros(pageNumber = 1) {

        let parametros = {
            //mostrar_inactivos: state.mostrar_inactivos,
            //estado_pedido: state.filtros,
            //numero_pedido: state.otros_filtros.numero_pedido,
            //codigo_cliente: state.otros_filtros.codigo_cliente,
            //fecha: state.otros_filtros.fecha_actual,
            busqueda: state.otros_filtros.busqueda,
        };

        return new Promise((resolve, reject) => {

            laravelAPI.get(`usuarios/obtenerUsuarios?page=${pageNumber}`, { params: parametros })
                .then(function (response) {

                    //actualizar datos de registros
                    actualizarDatosDeRegistros(response.data);

                    resolve(response)

                })
                .catch(function (error) {
                    reject(null)
                })
        })

    }


    function actualizarDatosDeRegistros(data) {
        setState(previousState => {
            return { ...previousState, registros: data }
        });
    }

    async function onKeyUp(event) {
        if (event.charCode === 13) {
            await obtenerRegistros();
        }
    }

    function modificarInputChange(event) {

        setState(previousState => {
            return { ...previousState, otros_filtros: { ...state.otros_filtros, [event.target.name]: event.target.value } }
        });


    }

    //craer funcion para que se ejecute al mostrar o ocultar modal 
    function mostrarOcultarModalCrear(usuario_as400, usuario){
    
        let valor = state.modal_crear.mostrar_modal ? false : true
        let showLicencia = false;
        let formulario = {
            id: null,
            rol:'',
            password:'',
            name:'',
            email:'',
            user_as400_id : '',
            licencia: '',
            roles: []
        }

        if (usuario_as400) {
            
            //vallidar si tiene roles
            let roles = [];
            if (usuario_as400.user) {
                if (usuario_as400.user.user_sistem) {
                    roles = usuario_as400.user.user_sistem.roles.map(rol => {
                        if (rol.name === 'Conductor') {
                            showLicencia = true;
                        }
                        return rol.name
                    })
                }
            }
            formulario = {    
                id: null,
                            rol:'',
                            password:usuario_as400.first_name,
                            name:`${usuario_as400.first_name} ${usuario_as400.last_name}`,
                            email: usuario_as400.email,
                            user_as400_id : usuario_as400.id,
                            licencia: usuario_as400.licencia,
                            roles : roles
                        }
        }
             
        setState(previousState => {
            return { ...previousState,  modal_crear : { mostrar_modal: valor}, usuario: usuario, crear_registro : formulario, showLicencia:showLicencia}
        });
      
    }

        
    function guardarRegistrosUsiario(e){
        
        e.preventDefault()
        console.log(state);
        return new Promise((resolve, reject)=>{
            
            laravelAPI.get('usuario/crear-usuario', { params : state.crear_registro })
            .then(function (response) {
                
                mostrarMensajeExito(response.data.mensaje)
                //cerrar modal 
                mostrarOcultarModalCrear(null, null)
                
                setState(previousState => {
                    return{ ...previousState,  refrescar : state.refrescar+1 }
                });

                resolve(response)
                
            })
            .catch(function (error) {
                 
                console.log(error);
                if (error.response.status==422) {
                    //actualizar los errores
                    setState(previousState => {
                        return { ...previousState,  errores_validacion:error.response.data}
                    });
                }

                mostrarMensajeError(error.response)

                reject(null)
            })
        })
         
        
        
    }

    function handleInputChange(event){

        setState(previousState => {
            return { ...previousState,  crear_registro : { ... state.crear_registro, [event.target.name] : event.target.value}}
        });

  
    }

    function handleCheckboxChange (event){

        let newArray = [... state.crear_registro.roles, event.target.value];
        let showLicencia = false;
        if (state.crear_registro.roles.includes(event.target.value)) {
          newArray = newArray.filter(rol => rol !== event.target.value);
        }
        console.log(event);
        if (event.target.value == 'Conductor') {
            if (event.target.checked) {
                showLicencia = true;
            }else{
                showLicencia = false;
            }
        }

        setState(previousState => {
            return { ...previousState,  crear_registro : { ... state.crear_registro, roles : newArray}, showLicencia:showLicencia}
        });

        //console.log(state.crear_registro.roles)
    };

}

export default Contenido;