import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';


class Error extends Component {

  //renderizar componente
  render() {    
    return (
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Pagina no encontrada</h1>
              </div>
              <div className="col-sm-6">
                 
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="error-page">
            <h2 className="headline text-warning"> 404</h2>

            <div className="error-content">
              <h3><i className="fas fa-exclamation-triangle text-warning"></i> ¡UPS! Página no encontrada.</h3>

              <p>
              No pudimos encontrar la página que estabas buscando. Mientras tanto, puede volver al panel de control o intentar usar el formulario de búsqueda.
              </p>

              
            </div>
          </div>
        </section>
      </div>


    )

  }



}

export default Error;
