import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import ListadoTable from './ListadoTable';


class PedidoEnRuta extends Component {

  render() {
    let state = this.props.data
     
    return (     
      <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className='row'>
                      <div className='col-9'>

                        <h3 className="card-title">Listado de Pedidos Pendientes por Entregar</h3>
                      </div>

                    </div>

                  </div>

                  <div className="card-body">
                    <ListadoTable
                      registros={state.registros}
                      funcionCrearRegistro={this.props.funcionCrearRegistro}
                      funcioniniciarEntrega = {this.props.funcioniniciarEntrega}
                      funcionMostrarOcultarModalPeso = {this.props.funcionMostrarOcultarModalPeso}
                      funcionMostrarOcultarModalAgencia = {this.props.funcionMostrarOcultarModalAgencia}
                      funcionCambiarAgencia = {this.props.funcionCambiarAgencia}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) 

  }



}

export default PedidoEnRuta;
