import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPhone } from '@fortawesome/free-solid-svg-icons';
import imagen from '../../assets/images/cliente.jpg';

class DetallesEnvio extends Component {

  render() {

    let state = this.props.data
    let cliente = state.pedidos_en_ruta

    return (
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className='row'>
                    <div className='col-12'>

                      <h3 className="card-title">Detalles del Envio</h3>
                    </div>

                  </div>

                </div>

                <div className="card-body" >
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                      <div className="card bg-light d-flex flex-fill">
                        <div className="card-header text-muted border-bottom-0">
                          {cliente.length} pedidos 
                            <a onClick={() => this.props.funcionCancelarEntrega(cliente, 'Cancelar')} className="btn btn-sm btn-danger float-right">
                               Cancelar
                            </a>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-7">
                              <h2 className="lead"><b>{cliente[0].titulo}</b></h2>
                              <p className="text-muted text-sm"><b>Comentario: </b> {cliente[0].comentario} </p>
                              <ul className="ml-4 mb-0 fa-ul text-muted">
                                <li className="small"><span className="fa-li"><FontAwesomeIcon icon={faBuilding} /></span> Tipo de Envio: {cliente[0].shipping_type}</li>
                                <li className="small"><span className="fa-li"><FontAwesomeIcon icon={faBuilding} /></span> Direccion: {cliente[0].direccion_envio}</li>
                                <li className="small"><span className="fa-li"><FontAwesomeIcon icon={faBuilding} /></span> Transportista: {cliente[0].agencia_envio}</li>
                                <li className="small"><span className="fa-li"><FontAwesomeIcon icon={faBuilding}/></span> Transportista: {cliente[0].agencia_envio_2}</li>
                                <li className="small"><span className="fa-li"><FontAwesomeIcon icon={faPhone} /></span> Telefono #: {cliente[0].telefono}</li>
                              </ul>
                            </div>
                            <div className="col-5 text-center">
                              <img src={imagen} alt="user-avatar" className="img-circle img-fluid"></img>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="text-right">
                           
                            <a onClick={() => this.props.funcioniniciarEntrega(cliente, 'Punto de Destino')} className="btn btn-sm btn-warning">
                              Pedido en Punto Destino del Cliente
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )

  }



}

export default DetallesEnvio;
