import React,{ Component }   from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck,faShuttleVan, faMotorcycle, faTruckPickup, faUser} from '@fortawesome/free-solid-svg-icons';
 
class MarkerImagen extends Component {
    render() {
      let vehiculo = this.props.vehiculo

      return <div className='bocadillo-cuadrado' >
                {
                   this.obtenerIcono(vehiculo)
                }
                {vehiculo.plate}
            </div>;
    }


    obtenerIcono(vehiculo){

      let icono= faTruck

      switch (vehiculo.model) {
        case "L300":
          icono = faShuttleVan
          break;

        case "K2700":
          icono = faTruck
          break;

        case "PULSAR":
          icono = faMotorcycle
          break;

        case "135 LS":
          icono = faMotorcycle
          break;
        case "DAMAS":
          icono = faShuttleVan
          break;

        case "HILUX":
          icono = faTruckPickup
          break;
        case "N300":
          icono = faShuttleVan
          break;
 
        case "HD78":
          icono = faTruck
          break;       
            
           
        default:
          break;
      }


      return (<FontAwesomeIcon className='mr-2' icon={icono}/>)
    }
}
  


export default MarkerImagen;