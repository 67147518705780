import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEnvelopeSquare, faLock } from '@fortawesome/free-solid-svg-icons';



class Sucursales extends Component {



    render() {

        let data = this.props.data
        let titulo = `Sucursales de ${data.titulo_modulo}`
        let mostrar_modal = data.modal_sucursal.mostrar_modal
        let sucursales = []

        if (data.agencia) {

            sucursales = data.agencia.sucursales
        }

        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>{this.props.funcionMostrarOcultarModalSucursales(null)}}
                    backdrop="static"
                    keyboard={false}
                    size={'xl'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {
                                sucursales.map((sucursal,key) => {
                                    return (<div className="col-md-6" key={'sucur-'+key}>
                                        <div className="card card-primary">
                                            <div className="card-header">
                                                <h3 className="card-title">{sucursal.name}</h3>
                                                <div className="card-tools">
                                                    <button type="button" title="Eliminar" onClick={()=>{this.props.funcionEliminarSucursal(sucursal.id)}} className="btn btn-tool"  >
                                                        <FontAwesomeIcon icon={faTrash} color="red" />
                                                    </button>
                                                </div>

                                            </div>

                                            <div className="card-body">
                                            <table className="table table-head-fixed text-nowrap table-sm">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>codigo</th>
                                                    <th>Departamento</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {
                                                        sucursal.departementos.map((departemento, key)=>{
                                                        return(<tr>
                                                                <td>{key+1}</td>
                                                                <td>{departemento.code}</td>
                                                                <td>{departemento.name}</td>
                                                                </tr>)
                                                        })
                                                    }
                                                   

                                                    
                                                        
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>)
                                })
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{this.props.funcionMostrarOcultarModalSucursales(null)}} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}




export default Sucursales;