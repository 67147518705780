import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScaleBalanced, faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import imagen_vehiculo from '../../assets/images/vehiculo.png';
import imagen_sin_foto from '../../assets/images/logo.jpg';
import { fomatearFecha} from '../../helpers'
import DetalleProceso from '../panelCliente/DetalleProceso';
import StarRatings from 'react-star-ratings';

//importar funciones helpers
import { obtenerEscala} from '../../helpers'



class Mostrar extends Component {
    
    registros = [];

    render() {
        this.registros = []

        let data = this.props.data
        let pedidos = this.props.data.pedidos
       
        if (!pedidos) {
            return <></>            
        }

        let titulo = `Cargar peso de los pedidos`
        let mostrar_modal = data.modal_peso.mostrar_modal
        let Clientes = [];
 
        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.props.funcionMostrarOcultarModalPeso(null)}
                    backdrop="static"
                    keyboard={false}
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-sm-12'>.
                                {   

                                    pedidos.map((pedido,key)=>{

                                       /*  if (pedido.peso && pedido.bultos) {
                                            return
                                        } */
                                        if (!Clientes.includes(pedido.customer_code)) {
                                            
                                            Clientes.push(pedido.customer_code);
                                            
                                            return(
                                                <div  key={key}>
                                                    <label>Cliente {pedido.customer_code}</label>
                                                    <InputGroup className='mb-3' >
                                                        <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faScaleBalanced}/></InputGroup.Text>
                                                        <FormControl className='form-control-border border-width-2'
                                                            placeholder={pedido.peso ? "Su ultimo Peso fue: "+pedido.peso : "Peso de sus Cliente "+pedido.customer_code}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            name={'peso-pedido-'+pedido.customer_code}
                                                            id={'peso-pedido-'+pedido.customer_code}
                                                            onChange = {this.props.funcionHandleInputChanger}
                                                            value = {(this.props.data.crear_registro_peso['peso-pedido-'+pedido.customer_code] ? this.props.data.crear_registro_peso['peso-pedido-'+pedido.customer_code] : '')}
                                                            />
                                                    </InputGroup>

                                                    <InputGroup className='mb-3' >
                                                        <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faScaleBalanced}/></InputGroup.Text>
                                                        <FormControl className='form-control-border border-width-2'
                                                            placeholder={pedido.bultos ? "Su ultimos bultos fue: "+pedido.bultos : "Ingrese Bultos de Cliente "+pedido.customer_code}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            name={'bulto-pedido-'+pedido.customer_code}
                                                            id={'bulto-pedido-'+pedido.customer_code}
                                                            onChange = {this.props.funcionHandleInputChanger}
                                                            value = {(this.props.data.crear_registro_peso['bulto-pedido-'+pedido.customer_code] ?this.props.data.crear_registro_peso['bulto-pedido-'+pedido.customer_code] : '') }
                                                            />
                                                    </InputGroup>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                
                                
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.props.funcionMostrarOcultarModalPeso(null)} >
                            Close
                        </Button>
                        <a  onClick={()=>this.props.funcionRegistrarPeso(pedidos)} className="btn  btn-primary">
                          Cargar Datos
                        </a>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    
  //obtener los registros de pedido
  obtenerRegistroDeProcesoDePedido(detalle){

    let descripcion = detalle.transit.description_web
    //validar si ya se agrego dicho detalle
    const result = this.registros.filter(registro => registro==descripcion);

    //si ya se agrego salimos de la funcion
    if (result.length>0) {
      return null
    }

    //si no existe lo agregamos al arreglo
    this.registros.push(descripcion);
    
    return {
      id: detalle.id,
      titulo_estado: descripcion,
      fecha: `${detalle.date_of_work} ${detalle.work_time}`,
      descripcion: descripcion+' de Manera Exitosa.',
      estado: 'Aprobado'
    };

  }
}





export default Mostrar;