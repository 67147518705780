import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import imagen_cliente from '../../assets/images/cliente.jpg';
import imagen_contacto from '../../assets/images/contacto.jpg';



class Mostrar extends Component {



    render() {

        let data = this.props.data
        let cliente = data.cliente

        if (!cliente) {
            return <></>
        }

        let titulo = `Datos del Cliente`
        let mostrar_modal = data.modal_cliente.mostrar_modal

        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={() => this.props.funcionMostrarCliente(null)}
                    backdrop="static"
                    keyboard={false}
                    size='xl'

                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className="card card-widget widget-user-2">
                                    <div className="widget-user-header bg-info">
                                        <div className="widget-user-image">
                                            <img className="img-circle elevation-2" src={imagen_cliente} alt="User Avatar"></img>
                                        </div>
                                        <h5 className="widget-user-desc">{cliente.name_social_reason}</h5>
                                    </div>
                                    <div className="card-footer p-0">
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link">
                                                    Codigo As400 <span className="float-right text-dark">{cliente.code}</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link">
                                                    T. Empresa	 <span className="float-right text-dark">{cliente.company.name}</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link">
                                                    Documento <span className="float-right text-dark">{cliente.document_number}</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link">
                                                    Nombres o Razón Social <span className="float-right text-dark">{cliente.name_social_reason}</span>
                                                </a>
                                            </li>


                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className='col-sm-12'>
                                <div className='table-responsive p-0 w-100'>

                                    <h4 className='text-center'>Direcciones</h4>
                                    <table className="table table-head-fixed text-nowrap table-sm">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Tipo Dirección</th>
                                                <th>Región</th>
                                                <th>Dirección</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cliente.direcciones.map((direccion, key) => {
                                                    return (
                                                        <tr key={cliente.id}>
                                                            <td>{key + 1}</td>
                                                            <td>{direccion.tipo_direccion}</td>
                                                            <td>{direccion.region}</td>
                                                            <td>{direccion.direccion_completa}</td> 
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.props.funcionMostrarCliente(null)} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}




export default Mostrar;