import React, { Component } from 'react';

 
class Card extends Component {

  //renderizar componente
  render() {
 
    return (
      <div className="col-sm-6 col-12">
        <div className={`small-box bg-${this.props.color}`}>
            <div className="inner">
                <h3>{this.props.porcentaje}</h3>
                {
                  this.props.titulo == 'Promedio De Entrega' && <p>{this.props.total} Pedidos no entregados</p>
                }
                {
                  this.props.titulo != 'Promedio De Entrega' && <p>{this.props.total} Pedidos</p>
                }
            </div>
            <div className="icon">
                {this.props.icono}
            </div>
            <a  className="small-box-footer"  style={{'cursor': 'pointer'}} onClick={()=>{this.props.mostrarPedidosEstado(this.props.titulo)}}>{this.props.titulo}</a>
        </div>
      </div>
    )
  }



}

export default Card;