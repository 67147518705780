import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faEye,faCheck,faCar,faEdit } from '@fortawesome/free-solid-svg-icons';

//importar funciones helpers
import {obtenerEstadoAccion,obtenerEstado } from '../../helpers'

class ListadoTable extends Component {

  //renderizar componente
  render() {
    return (
         <table className="table table-head-fixed text-nowrap table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Id</th>
                <th>Placa</th>
                <th>Marca</th>
                <th>Tipo</th>
                <th>Color</th>
                <th>Descripcion</th>
                <th>Conductor</th>
                <th>Estado de Accion</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                /* validar que existan registros */
                this.props.registros &&
                  /* iterar los registros para la tabla */
                  this.props.registros.vehiculos.map((vehiculo, key) =>{

                    return(
                      <tr key={vehiculo.id}>
                        <td>{key+1}</td>
                        <td>{vehiculo.id}</td>
                        <td>{vehiculo.placa}</td>
                        <td>{vehiculo.marca ? vehiculo.marca.name :  vehiculo.car_brands_id}</td>
                        <td>{vehiculo.tipo ? vehiculo.tipo.name :  vehiculo.vehicle_type_id}</td>
                        <td>{vehiculo.color}</td>
                        <td>{vehiculo.description}</td>
                        <td>{vehiculo.conductor ? vehiculo.conductor.names : ''}</td>
                        <td>{obtenerEstadoAccion(vehiculo.action_status)}</td>
                        <td>{obtenerEstado(vehiculo.status)}</td>
                        <td>
                          <div>
                            <button  title='Visualizar' className='btn btn-xs btn-success mr-2' onClick={()=>{this.props.funcionMostrarVehiculo(vehiculo)}}><FontAwesomeIcon icon={faEye} color="white" /></button>
                            <button  title='Editar' className='btn btn-xs btn-info mr-2' onClick={()=>{this.props.funcionMostrarOcultarModalCrear(vehiculo)}}><FontAwesomeIcon icon={faEdit} color="white" /></button>
                            <button  title='Eliminar' className={`btn btn-xs btn-${vehiculo.status?'danger':'success'}`} onClick={()=>{this.props.funcionEliminarRegistro(vehiculo.id)}}>
                              <FontAwesomeIcon icon={vehiculo.status==1 ? faTrash : faCheck} color="white" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })
              }
               
            </tbody>
          </table>
 
    )

  }



}

export default ListadoTable;
