import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTruck,faPlus } from '@fortawesome/free-solid-svg-icons';
import DetalleProceso from './DetalleProceso';
import StarRatings from 'react-star-ratings';
//importar configuracion axio
import { laravelAPI } from '../../config.js'
import Card from './Card';
import MapaMYM from './MapaMYM';
//importar funciones helpers
import { mostrarMensajeAlertas, TokenValido, mostrarMensajeAlerta, mostrarMensajeExito, mensajeDeConfirmacion, obtenerEscala } from '../../helpers'
const styleDivPedidos = { maxHeight: "600px", overflow:"auto"};
const styleSpanBtn = {};

class ProgresoPedido extends Component {



  changeRating = (newRating) => {
    this.calificarPedido(newRating, this.props.state.pedido.id)

  }


  registros = [];
  state = null;
  //renderizar componente
  render() {
    console.log("::: mostramos los valores que se validan :::");
    this.state = this.props.state;
    this.registros = []
    if (!this.props.state.cliente) {
      return (<></>)
    }

    if (!this.props.state.cliente.pedidos) {

      return (<>
        <div className='content-header'>
          <div className='row mb-2'>
            <div className='col-sm-10'>
              <h1 className='title'>{this.props.state.cliente.code} - {this.props.state.cliente.name_social_reason}</h1>
            </div>
            <div className='col-sm-2'>


            </div>
          </div>
        </div>
        <div className='row'>
          {this.props.state.cliente.conteo.map((registro, key) => {
            return (
              <Card key={key}
                titulo={registro.titulo}
                icono={<FontAwesomeIcon icon={faTruck} />}
                icono2={<FontAwesomeIcon icon={faPlus} />}
                color={registro.color}
                total={registro.valor}
                estatus={registro.estatus}
                handleInputChange = {this.props.handleInputChange}
              />
            )
          })
          }
        </div>
      </>

      )
    }
    return (
      <>
      <div class="row">
        <div className="col-md-6">
          <div className="card">
            <div className='card-header'>
              <h4>{ this.props.state.titleDetail }</h4>
            </div>
            <div className='card-body'>
              <div className="timeline" style={styleDivPedidos} >
                <div className="time-label">
                  <span className="bg-info">
                    {this.props.state.listar_pedidos ?
                      `Listado de Pedidos` : `Historico del Pedido (${this.props.state.pedido.document_order_number})`}
                  </span>
                  {
                    !this.props.state.listar_pedidos &&
                    <a onClick={() => this.props.state.mostrarPedidos()} className='btn btn-warning btn-sm  ml-2 '>Volver a Pedidos</a>

                  }
                  {
                    this.props.state.listar_pedidos &&
                    <a onClick={() => this.props.handleInputChange('')} className='btn btn-warning btn-sm  ml-2 '>Volver</a>

                  }
                </div>

                {/* mostrar listado de pedidos */}
                {
                  this.props.state.listar_pedidos &&
                  this.props.state.cliente &&
                  /* iterar los registros para ir generado el tracking */
                  this.props.state.cliente.pedidos.map((pedido) => {
                    /* imprimir cada uno de los estados */
                    console.log("recorremos cliente pedidos")
                    return <DetalleProceso key={pedido.id}
                      registro={this.obtenerRegistroPedidos(pedido)}
                      pedido={pedido}
                      mostrarProcesoPedido={this.props.state.mostrarProcesoPedido}
                      mostrarPedido={this.props.state.mostrarPedido} />

                  })
                }

                {/* mostrar detalles del pedido*/}

                {
                  !this.props.state.listar_pedidos &&
                  this.props.state.pedido &&
                  this.props.state.pedido.tracking.details.map((detalle) => {
                    console.log("recorremos pedido tracking details");
                    /* imprimir cada uno de los estados */
                    return <DetalleProceso key={detalle.id}
                      pedido={null}
                      registro={this.obtenerRegistroDeProcesoDePedido(detalle)}
                      mostrarProcesoPedido={null} />

                  })
                }
                {
                  !this.props.state.listar_pedidos &&
                  this.props.state.pedido &&
                  //validar si recoje el cliente
                  //si esta empaquetado
                  //si esta aprobado
                  this.props.state.pedido.tracking.carrier_code == '003151' &&
                  this.props.state.pedido.tracking.is_packed == '1' &&
                  this.props.state.pedido.tracking.is_approved == '1' &&
                  <DetalleProceso key={3151}
                    pedido={null}
                    registro={this.obtenerRegistroDerecojoEnTienda(this.props.state.pedido.tracking.details[this.props.state.pedido.tracking.details.length - 1])}
                    mostrarProcesoPedido={null} />
                }
              </div>
            </div>
          </div>
        </div> {/* fin div col 6*/}
        <div className="col-md-6">
          <MapaMYM state={this.state}/>
        </div>
        </div> {/* fin div row */}
        {
          !this.props.state.listar_pedidos &&
          this.props.state.pedido &&
          this.props.state.pedido.tracking.transit_status_id == 32 &&
          <div className="card card-secondary">
            <div className="card-header">
              <h3 className="card-title">Califique Nuestro Servicio</h3>
            </div>

            <div className="card-body" >
              <h4>{obtenerEscala(this.props.state.calificacion)}</h4>


              <StarRatings
                rating={this.props.state.calificacion}
                starRatedColor="blue"
                changeRating={!this.props.state.pedido.calificacion ? this.changeRating : () => { mostrarMensajeAlerta('Ya Realizo Su Calificación') }}
                numberOfStars={5}
                name='rating'

              />
            </div>

          </div>




        }
                 
        {
          !this.props.state.listar_pedidos &&
          this.props.state.pedido &&
          this.props.state.pedido.tracking.transit_status_id == 11 &&
          <div className="card card-secondary">
            <div className="card-header">
              <h3 className="card-title">Califique Nuestro Servicio</h3>
            </div>

            <div className="card-body" >
              <h4>{obtenerEscala(this.props.state.calificacion)}</h4>


              <StarRatings
                rating={this.props.state.calificacion}
                starRatedColor="blue"
                changeRating={!this.props.state.pedido.calificacion ? this.changeRating : () => { mostrarMensajeAlerta('Ya Realizo Su Calificación') }}
                numberOfStars={5}
                name='rating'

              />
            </div>

          </div>
        }
      </>
    )

  }


  obtenerRegistroPedidos(pedido) {
    if (pedido.tracking) {
      if (pedido.tracking.details.length > 0) {
        let estado = pedido.tracking.details[pedido.tracking.details.length - 1].transit.description_web
        if (pedido.tracking.is_packed == 1 && pedido.tracking.is_approved == 1 && pedido.tracking.carrier_code == "003151") {
          estado = "Listo para su Recojo"
        }
        return {
          id: pedido.id,
          titulo_estado: `Pedido Nº ${pedido.document_order_number}`,
          fecha: pedido.date_of_work,
          descripcion: 'Realizar Seguimiento al Pedido',
          accion: true,
          estado: estado,
        };
      }
    }
  }

  //obtener los registros de pedido
  obtenerRegistroDeProcesoDePedido(detalle) {

    let descripcion = detalle.transit.description_web
    //validar si ya se agrego dicho detalle
    const result = this.registros.filter(registro => registro == descripcion);

    //si ya se agrego salimos de la funcion
    if (result.length > 0) {
      return null
    }

    //si no existe lo agregamos al arreglo
    this.registros.push(descripcion);

    let estado = "Aprobado"
    let mensaje = descripcion + ' de Manera Exitosa.'


    if (detalle.transit.id == "14") {
      estado = ""
      mensaje = "Su Pedido Fue Rechazado"

    }


    return {
      id: detalle.id,
      titulo_estado: descripcion,
      fecha: `${detalle.date_of_work} ${detalle.work_time}`,
      descripcion: mensaje,
      estado: estado
    };



  }


  //obtener los registros de pedido recojo en tienda
  obtenerRegistroDerecojoEnTienda(detalle) {

    let estado = "Aprobado"

    return {
      id: 3151,
      titulo_estado: 'Listo para su Recojo',
      fecha: `${detalle.date_of_work} ${detalle.work_time}`,
      descripcion: "Ya puede Retirar su Pedido",
      estado: estado
    };



  }




  async calificarPedido(valor, pedido) {

    let existeToken = await TokenValido();

    if (existeToken) {
      mostrarMensajeAlertas("Solo el Cliente Puede Calificar")
      return
    }

    this.props.state.calificacion = valor
    let cliete = this.props.state.cliente.code
    let esConfirmar = await mensajeDeConfirmacion('Seguro que Desea Calificar')

    if (!esConfirmar) {
      return;
    }

    return new Promise((resolve, reject) => {

      laravelAPI.get('cliente/pedidos/calificar-pedido', { params: { valor, pedido } })
        .then(function (response) {
          mostrarMensajeExito(response.data.mensaje)
          window.location.replace(`/panel/cliente/${cliete}`)

          resolve(response)

        })
        .catch(function (error) {

          reject(error)
        })
    })

  }


}

export default ProgresoPedido;
