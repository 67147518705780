import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faUser, faCar, faBookmark } from '@fortawesome/free-solid-svg-icons';
import ListadoTable from './ListadoTable';
import DualListBox from 'react-dual-listbox';
import { InputGroup, FormControl, Form,Button } from 'react-bootstrap';


class AsignacionCarga extends Component {


  onChange = (selected) => {
    this.setState({ selected });
  }; 
  
  onChangePrivincia = (selected_provincia) => {
    this.setState({ selected_provincia });
  };
  
  state = {
    selected: [],
    selected_provincia: [],
  };
 
  componentDidMount() {
    this.props.funcionActualizarPreasignado(false)

    if (this.props.data.vehiculo) {
      if (this.props.data.vehiculo.despachos.length>0) {   
        this.props.data.vehiculo.despachos[0].details.map((detale,key)=>{
          if (detale.shipping_type=="Lima") {
            let aux = this.state.selected
            aux.push(detale.dispatch)
            this.setState({ selected: aux })
            console.log(aux)
            this.props.funcionActualizarPreasignado(this.props.data.vehiculo.despachos[0].id)
          }else{

            let aux = this.state.selected_provincia
            aux.push(detale.dispatch)
            this.setState({ selected_provincia: aux })
            this.props.funcionActualizarPreasignado(this.props.data.vehiculo.despachos[0].id)

          }
        })
      }
    }


  }



  render() {

    let state = this.props.data
    let vehiculo = state.vehiculo
    let errores_validacion = state.errores_validacion

    const options_lima =  state.pedidos_lima;
    const options_provincia =  state.pedidos_provincia;

    const selected = this.state.selected;
    const selected_provincia = this.state.selected_provincia;

    this.props.data.crear_registro.envios_lima=selected
    this.props.data.crear_registro.envio_provincias=selected_provincia

    return (
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className='row'>
                    <div className='col-9'>

                      <h3 className="card-title">Asignacion de Carga</h3>
                    </div>
                    <div className='col-sm-2'>

                      <button type="button" onClick={() => this.props.funcionCrearRegistro(null)} className='btn btn-sm btn-warning ' >
                        <FontAwesomeIcon icon={faArrowCircleLeft} className='mr-2'   />
                        Volver
                      </button>
                    </div>
                  </div>

                </div>

                <div className="card-body" >
                  <div className="row">

                    <div className="col-md-4">
                      <InputGroup className='mb-3'>
                        <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faCar} /></InputGroup.Text>
                        <FormControl className='form-control-border border-width-2'
                          placeholder="Ingrese Placa"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name="placa"
                          id="placa"
                          isInvalid={errores_validacion.placa ? true : false}
                          onChange={this.props.funcionHandleInputChanger}
                          value={vehiculo.placa}
                        />
                      </InputGroup>

                      
                        <FormControl className='form-control-border border-width-2'
                          name="vehiculo_id"
                          onChange={this.props.funcionHandleInputChanger}
                          value={vehiculo.placa}
                          type="hidden"
                        />
                      
                    </div>
                    <div className="col-md-4">
                      { errores_validacion.tipo_despacho && <span className="form-text text-danger mb-1">{errores_validacion.tipo_despacho[0]}</span> }

                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faBookmark} /></InputGroup.Text>

                        <Form.Select aria-label="Default select example" isInvalid={errores_validacion.tipo_despacho ? true : false} name='tipo_despacho' className='custom-select form-control-border border-width-2' onChange={this.props.funcionHandleInputChanger}>

                          <option value={''}>Seleccione Tipo de Despacho</option>
                          <option value={'Inmediato'}>Inmediato</option>
                          <option value={'Pre-Asignado'} selected={true}>Pre-Asignado</option>



                        </Form.Select>

                      </InputGroup>
                    </div>


                    <div className="col-md-6">
                      <p><b>Clientes (Lima)</b></p>
                      { errores_validacion.envios_lima && <span className="form-text text-danger mb-1">{errores_validacion.envios_lima[0]}</span> }

                      <DualListBox
                         
                        name='envios_lima'
                        options={options_lima}
                        isInvalid={errores_validacion.envios_lima ? true : false} 
                        selected={selected}
                        onChange={this.onChange}
                        icons={{
                          moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                          moveAllLeft: [
                            <FontAwesomeIcon key={0} icon={faChevronLeft} />,
                            <FontAwesomeIcon key={1} icon={faChevronLeft} />,
                          ],
                          moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                          moveAllRight: [
                            <FontAwesomeIcon key={0} icon={faChevronRight} />,
                            <FontAwesomeIcon key={1} icon={faChevronRight} />,
                          ],

                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <p><b>Clientes (Provincia ó Agencias)</b></p>
                      { errores_validacion.envio_provincias && <span className="form-text text-danger mb-1">{errores_validacion.envio_provincias[0]}</span> }

                      <DualListBox
                        
                        name='envio_provincias'
                        isInvalid={errores_validacion.envio_provincias ? true : false} 
                        options={options_provincia}
                        selected={selected_provincia}
                        onChange={this.onChangePrivincia}
                        icons={{
                          moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                          moveAllLeft: [
                            <FontAwesomeIcon key={0} icon={faChevronLeft} />,
                            <FontAwesomeIcon key={1} icon={faChevronLeft} />,
                          ],
                          moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                          moveAllRight: [
                            <FontAwesomeIcon key={0} icon={faChevronRight} />,
                            <FontAwesomeIcon key={1} icon={faChevronRight} />,
                          ],

                        }}
                      />
                    </div>
                    <div className="offset-md-10 col-md-2 mt-2">
                      <Button variant="primary" type='button' onClick={()=>this.props.funcionGuardarRegistros()}>Guardar Despacho</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )

  }



}

export default AsignacionCarga;
