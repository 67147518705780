import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';


class ListadoTable extends Component {

  //renderizar componente
  render() {
    let registros = this.props.registros;
    return (
         <table className="table table-head-fixed text-nowrap table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Valor</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                /* iterar los registros para la tabla */
                registros.map((registro, key) =>{
                  return(
                    <tr key={registro.id}>
                      <td>{registro.id}</td>
                      <td>{registro.name}</td>
                      <td>{registro.value}</td>
                      <td>
                        <div>
                          <button className='btn btn-xs btn-danger'><FontAwesomeIcon icon={faTrash} color="white" /></button>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
               
            </tbody>
          </table>
 
    )

  }



}

export default ListadoTable;
