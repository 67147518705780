import React, { useState,useEffect  } from 'react';
import ListadoTable from './ListadoTable';
import {PageContent,} from 'react-adminlte-3'
import {Form,Check} from 'react-bootstrap';
import EncabezadoModulo from './EncabezadoModulo';
import Crear from './Crear';
import Mostrar from './Mostrar';
import { useParams,Navigate  } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie} from '@fortawesome/free-solid-svg-icons';
//importar configuracion axio
import { laravelAPI } from '../../config.js'
 
//importar funciones helpers
import { mostrarMensajeExito,cerrarMenuLateral,mostrarMensajeError,mensajeDeConfirmacion } from '../../helpers';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClimbingBoxLoader";
const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };


function Contenido() {
    //estatus
    const [state, setState] = useState({
        registros : null,
        titulo_modulo: 'Conductores',
        modal_crear : { mostrar_modal: false},
        modal_conductor:{ mostrar_modal: false},
        crear_registro:{    nombres:'',
                            apellidos:'',
                            tipo_documento:'',
                            numero_documento:'',
                        },
        errores_validacion : { nombres:null, apellidos:null,tipo_documento:null, numero_documento:null},
        mostrar_inactivos : 0,
        refrescar : 0,
        conductor: null
    });

    //variables y funcion para bloquear pantalla
    const [cargando, setCargando] = useState(false)
    const gestionarCarga = (valor) =>{  setCargando(valor); }

    //funciones que se ejecutaran al iniciar el componente
    useEffect( async() => {
        //cerrar menu lateral
        cerrarMenuLateral()
        
        let datos_consultados = await obtenerRegistros();

        //validar que exista datos de cliente
        if (datos_consultados.data) {
            //actualizar datos de registros
            actualizarDatosDeRegistros(datos_consultados.data);
        } 
        
        
    }, [state.mostrar_inactivos,state.refrescar]);


    
    if (cargando) {
        return (
          <div  className='mx-auto' style={style}>
            <ClipLoader color='rgb(53, 213, 182)' loading={true}  size={16} />
          </div>
        )
    }else{
        return (
            <div>
            <EncabezadoModulo 
                titulo_modulo={state.titulo_modulo}
                funcionMostrarOcultarModalCrear = {mostrarOcultarModalCrear}
            />
            
            <PageContent>
                <section className="content">
                
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='row'>
                                        <div className='col-9'>

                                            <h3 className="card-title">Listado Registros</h3>
                                        </div>
                                        <div className='col-3'>
                                            
                                        </div>
                                        </div>

                                    </div>
                                    
                                    <div className="card-body">
                                        <ListadoTable 
                                            registros={state.registros}
                                            funcionEliminarRegistro = {eliminarRegistro}
                                            funcionMostrarConductor = {mostrarConductor}
                                            
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Crear 
                        data={state}
                        funcionMostrarOcultarModalCrear = {mostrarOcultarModalCrear}
                        funcionGuardarRegistrosUsiario  = {guardarRegistrosUsiario}
                        funcionHandleInputChanger = {handleInputChange}
                    />

                    <Mostrar 
                        data={state}
                        funcionMostrarConductor = {mostrarConductor}
                    
                    />
                </section>
            </PageContent>
            </div>
        )

    }
    


    function obtenerRegistros() {
        
        let parametros = {
            mostrar_inactivos : state.mostrar_inactivos
        };
        return new Promise((resolve, reject)=>{
            //bloquear pantalla
            gestionarCarga(true)    
            
            laravelAPI.get('conductores/obtenerConductores', { params : parametros })
            .then(function (response) {
                //bloquear pantalla
                gestionarCarga(false)
                resolve(response)
                
            })
            .catch(function (error) {

                //bloquear pantalla
                gestionarCarga(false)
                reject(null)
            })
        })

    }

 

    function actualizarDatosDeRegistros(data) {
         
        
        setState(previousState => {
            return { ...previousState,  registros:data}
        });
    }

 

    //craer funcion para que se ejecute al mostrar o ocultar modal 
    function mostrarOcultarModalCrear(){
    
        let valor = state.modal_crear.mostrar_modal ? false : true
        
        setState(previousState => {
            return { ...previousState,  modal_crear : { mostrar_modal: valor}}
        });
      
    }


    
    function guardarRegistrosUsiario(e){
        
        e.preventDefault()
        
        return new Promise((resolve, reject)=>{
            
            laravelAPI.get('conductores/registrarConductor', { params : state.crear_registro })
            .then(function (response) {
                
                mostrarMensajeExito(response.data.mensaje)
                //cerrar modal 
                mostrarOcultarModalCrear()
                //actualizar datos 
                if (state.registros) {

                    let nuevos_conductores = state.registros.conductores
                    nuevos_conductores.push(response.data.conductor)

                    setState(previousState => {
                        return { ...previousState,  registros: { ... state.registros, 'conductores' : nuevos_conductores} } 
                    });
                }
                resolve(response)
                
            })
            .catch(function (error) {
                 
                console.log(error);
                if (error.response.status==422) {
                    //actualizar los errores
                    setState(previousState => {
                        return { ...previousState,  errores_validacion:error.response.data}
                    });
                }

                mostrarMensajeError(error.response)

                reject(null)
            })
        })
         
        
        
    }

    function handleInputChange(event){

        setState(previousState => {
            return { ...previousState,  crear_registro : { ... state.crear_registro, [event.target.name] : event.target.value}}
        });

  
    }

    async function eliminarRegistro(id) {
        
        let esConfirmar = await mensajeDeConfirmacion('Seguro que Desea Eliminar el Registro')

        if(!esConfirmar) return

        return new Promise((resolve, reject)=>{

            laravelAPI.get(`conductores/cambiar-estatus-conductor/${id}`, { params :  {id} })
            .then(function (response) {
                mostrarMensajeExito(response.data.mensaje)
                
                setState(previousState => {
                    return{ ...previousState,  refrescar : state.refrescar+1 }
                });

                resolve(response)
                
            })
            .catch(function (error) {
                reject(null)
            })
        })

    }

    
    async function ActivosInactivos(event) {
        
        let valor = event.target.checked ? 1 : 0;

        setState(previousState => {
            return{ ...previousState,  mostrar_inactivos : valor }
        }); 

    }

    
    //craer funcion para que se ejecute al
    // mostrar o ocultar modal de conductor
    function mostrarConductor(conductor = null){
    
        let valor = state.modal_conductor.mostrar_modal ? false : true
        
        setState(previousState => {
            return { ...previousState,  modal_conductor : { mostrar_modal: valor}, conductor:conductor}
        });
      
    }

 


}
 
 

export default Contenido;