import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import imagen_vehiculo from '../../assets/images/vehiculo.png';
import imagen_sin_foto from '../../assets/images/logo.jpg';
import { fomatearFecha} from '../../helpers'
import DetalleProceso from '../panelCliente/DetalleProceso';
import StarRatings from 'react-star-ratings';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import { laravelAPI } from '../../config.js'
import ReactPanZoom from 'react-image-pan-zoom-rotate';

//importar funciones helpers
import { obtenerEscala} from '../../helpers'



class Mostrar extends Component {
    constructor(props) {
        super(props);
        this.state = {imagenes:null}
    }

    changeImagen = (imagenes) => {
        
        this.setState({imagenes: imagenes});
    
    }

    registros = [];
    
    render() {

        this.registros = []
        let data = this.props.data
        let pedido = this.props.data.pedido
        let mostrar_modal = data.modal_pedido_detalles.mostrar_modal
        
        if (!pedido || !mostrar_modal) {
            return <></>
        }
 
        const images = []; 

        let titulo = `Datos del Pedido`
 
 
        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.cerrar(null)}
                    backdrop="static"
                    keyboard={false}
                    size='xl'
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <h4>{pedido.client?pedido.client.code:''} {pedido.social_reason}</h4>
                                <ul className="products-list product-list-in-card pl-2 pr-2">
                                    <li className="item">
                                        <div className="product">
                                            <a className="product-title">Pedido</a>
                                            <span className="product-description ml-2">
                                                {pedido.document_order_number}
                                            </span>
                                        </div>
                                    </li>
                                    
                                    <li className="item">
                                        <div className="product">
                                            <a className="product-title">Fecha</a>
                                            <span className="product-description ml-2">
                                            {fomatearFecha(pedido.date_of_work,true)}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="item">
                                        <div className="product">
                                            <a className="product-title">Tipo de Entrega</a>
                                            <span className="product-description ml-2">
                                            {pedido.detail ? pedido.detail.tipo_entrega :''}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="item">
                                        <div className="product">
                                            <a className="product-title">Calificación del Cliente</a>
                                            <span className="product-description ml-2">
                                            {obtenerEscala(pedido.calificacion?pedido.calificacion.valor:0)}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="item">
                                        <div className="product">
                                            <a className="product-title">Peso</a>
                                            <span className="product-description ml-2">
                                            {pedido.detail?pedido.detail.peso:<br></br>}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="item">
                                        <div className="product">
                                            <a className="product-title">Bultos</a>
                                            <span className="product-description ml-2">
                                            {pedido.detail?pedido.detail.bultos:<br></br>}
                                            </span>
                                        </div>
                                    </li>
                                    <li className="item">
                                        <div className="product">
                                            <a className="product-title">Observaciones</a>
                                            <span className="product-description ml-2">
                                            <ul className="list-group" dangerouslySetInnerHTML={{ __html: pedido.observaciones }}></ul>
                                             
                                            </span>
                                        </div>
                                    </li>
                                    <li className="item">
                                        <div className="product">
                                            <a className="product-title">Vehiculo</a>
                                            <span className="product-description ml-2">
                                            <ul className="list-group" dangerouslySetInnerHTML={{ __html: pedido.tracking.vehiculo ? pedido.tracking.vehiculo.placa: '' }}></ul>
                                             
                                            </span>
                                        </div>
                                    </li>
                                    <li className="item">
                                        <div className="product">
                                            <a className="product-title">Conductor</a>
                                            <span className="product-description ml-2">
                                            <ul className="list-group" dangerouslySetInnerHTML={{ __html:  pedido.tracking.vehiculo ?  pedido.tracking.vehiculo.conductor ?pedido.tracking.vehiculo.conductor.names : '' :'' }}></ul>
                                             
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                                             
                                            
                                <div className="card card-secondary d-none">
                                    <div className="card-header">
                                    <h3 className="card-title">Calificación del Cliente</h3>
                                    </div>

                                    <div className="card-body" >
                                    <h4>{obtenerEscala(pedido.calificacion?pedido.calificacion.valor:0)}</h4>
                        

                                    <StarRatings
                                        rating={pedido.calificacion?pedido.calificacion.valor:0}
                                        starRatedColor="blue"
                                        numberOfStars={5}
                                        name='rating'
                                        
                                    />
                                    </div>

                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="timeline">
                                    <div className="time-label">
                                        <span className="bg-info">
                                            Historico del Pedido ({pedido.document_order_number})
                                        </span>
                                    </div>
                                    {
                                        pedido.tracking.details.map((pedido,key)=>{
                                            /* imprimir cada uno de los estados */
                                            return <DetalleProceso  key={pedido.id}  
                                                        pedido={null} 
                                                        registro={this.obtenerRegistroDeProcesoDePedido(pedido)} 
                                                        mostrarProcesoPedido={null} 
                                                    />

                                        })  
                                    }

                                    <div className="time-label">
                                        <span className="bg-green">Fin de Historico</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                
                                {
                                    pedido.detail &&
                                    pedido.detail.tipo_entrega != "" &&
                                    !this.state.imagenes &&
                                    <>
                                    <h4 className='text-center'>
                                            Captura de Fotos
                                    </h4>    
                                    <div className='text-center'>
                                        <button className='btn btn-info' onClick={()=>{this.obtenerFotos(pedido.detail.id)}}>Ver Fotos</button>
                                    </div>
                                    </>
                                }
                                {
                                    this.state.imagenes &&
                                    <>
                                    <h4 className='text-center'>
                                        Captura de Fotos
                                    </h4>
                                    <ImageGallery renderItem={this.activarRotacion} showPlayButton={false} showFullscreenButton={false} items={this.state.imagenes} />
                                    </>
                                }
                                
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.cerrar()} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    
  //obtener los registros de pedido
  obtenerRegistroDeProcesoDePedido(detalle){

    let descripcion = detalle.transit.description_web
    //validar si ya se agrego dicho detalle
    const result = this.registros.filter(registro => registro==descripcion);

    //si ya se agrego salimos de la funcion
    if (result.length>0) {
      return null
    }

    //si no existe lo agregamos al arreglo
    this.registros.push(descripcion);
    
    return {
      id: detalle.id,
      titulo_estado: descripcion,
      fecha: `${detalle.date_of_work} ${detalle.work_time}`,
      descripcion: descripcion+' de Manera Exitosa.',
      estado: 'Aprobado'
    };

  }

  async obtenerFotos(id){

    let fotos = await this.buscarFotos(id) 

    console.log(fotos)
    
    this.setState({imagenes: fotos})

  }

  cerrar(){

    this.setState({imagenes: null})

    this.props.funcionMostrarPedido(null)
  }

  
    activarRotacion(data) {

        return(<ReactPanZoom
                    image={data.original}
                     
                />)
        
    }

  buscarFotos(id) {

        return new Promise((resolve, reject) => {
            //bloquear pantalla
            laravelAPI.get(`/despachos/obtener-fotos/${id}`)
                .then(function (response) {
 
                    resolve(response.data.imagenes)

                })
                .catch(function (error) {
                    reject(error)
                })
        })

    }
}





export default Mostrar;