import React, { useState, useEffect } from 'react';
import ListadoTable from './ListadoTable';
import { PageContent } from 'react-adminlte-3'
import EncabezadoModulo from './EncabezadoModulo';
import Crear from './Crear';
import Sucursal from './Sucursales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
//importar funciones helpers
import { mostrarMensajeExito,mensajeDeConfirmacion, cerrarMenuLateral, fomatearFecha,mostrarMensajeError,validarExistenciaDePermiso } from '../../helpers'
//importar configuracion axio
import { laravelAPI } from '../../config.js'

function Contenido() {

    //estatus
    const [state, setState] = useState({
        registros: null,
        titulo_modulo: 'Agencias',
        refrescar: 0,
        otros_filtros: {
            busqueda: '',

        },
        crear_registro : {
            agencia_id: null,
            agencia_name:'',
            sucursal_name:'',
            departamentos: [],
             
        },
        modal_crear : { mostrar_modal: false},
        modal_sucursal : { mostrar_modal: false},
        agencia:null,  
        errores_validacion : { 
            agencia_id: '',
            agencia_name:'',
            sucursal_name:'',
            departamentos: [],

        },

    });

    //funciones que se ejecutaran al iniciar el componente
    useEffect(async () => {
        //cerrar menu lateral
        cerrarMenuLateral()

        await obtenerRegistros();

    }, [state.refrescar]);



    let titulo_modulo = state.titulo_modulo

    return (
        <div>
            <EncabezadoModulo
                titulo_modulo={titulo_modulo}
            />

            <PageContent>
                <section className="content">

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                         
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <div className="form-group">
                                                    <label>Buscar</label>
                                                    <input className='form-control' name='busqueda' onKeyPress={onKeyUp} value={state.otros_filtros.busqueda} onChange={modificarInputChange}></input>
                                                </div>
                                            </div>
                                            <div className='col-sm-4 my-auto'>
                                                <button type="button" onClick={obtenerRegistros} className="btn btn-primary ">
                                                    <FontAwesomeIcon icon={faSearch} /> Buscar
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className='table-responsive p-0 w-100'>
                                            <ListadoTable 
                                                registros={state.registros}
                                                funcionObtenerPedidos={obtenerRegistros}
                                                funcionMostrarOcultarModalCrear = {mostrarOcultarModalCrear}
                                                funcionMostrarOcultarModalSucursales ={mostrarOcultarModalSucursal}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Crear 
                        data={state}
                        funcionMostrarOcultarModalCrear = {mostrarOcultarModalCrear}
                        funcionGuardarRegistrosUsiario  = {guardarRegistrosUsiario}
                        funcionHandleInputChanger = {handleInputChange}
                        funcionHandleCheckboxChange = {handleCheckboxChange}
                        
                    />

                    <Sucursal 
                        data={state}
                        funcionMostrarOcultarModalSucursales ={mostrarOcultarModalSucursal}
                        funcionEliminarSucursal = {eliminarSucursal}
                    />

                </section>
            </PageContent>
        </div>
    )


    function obtenerRegistros(pageNumber = 1) {

        let parametros = {
            busqueda: state.otros_filtros.busqueda,
        };

        return new Promise((resolve, reject) => {

            laravelAPI.get(`agencias/obtenerAgencias?page=${pageNumber}`, { params: parametros })
                .then(function (response) {

                    //actualizar datos de registros
                    actualizarDatosDeRegistros(response.data);

                    resolve(response)

                })
                .catch(function (error) {
                    reject(null)
                })
        })

    }


    function actualizarDatosDeRegistros(data) {
        setState(previousState => {
            return { ...previousState, registros: data }
        });
    }

    async function onKeyUp(event) {
        if (event.charCode === 13) {
            await obtenerRegistros();
        }
    }

    function modificarInputChange(event) {

        setState(previousState => {
            return { ...previousState, otros_filtros: { ...state.otros_filtros, [event.target.name]: event.target.value } }
        });


    }

    //craer funcion para que se ejecute al mostrar o ocultar modal 
    function mostrarOcultarModalCrear(agencia){
    
        let valor = state.modal_crear.mostrar_modal ? false : true
       
        let formulario = {
            agencia_id: null,
            agencia_name:'',
            sucursal_name:'',
            departamentos:[],
            
        }

        if (agencia) {
            //vallidar si tiene departamentos
            formulario = {    
                agencia_id: agencia.id,
                agencia_name: agencia.name,
                sucursal_name:'',
                departamentos:[],
            }
        }

        setState(previousState => {
            return { ...previousState,  modal_crear : { mostrar_modal: valor}, agencia: agencia, crear_registro : formulario}
        });
      
    }

    //craer funcion para que se ejecute al mostrar o ocultar modal 
    function mostrarOcultarModalSucursal(agencia){

        let valor = state.modal_sucursal.mostrar_modal ? false : true

        setState(previousState => {
            return { ...previousState,  modal_sucursal : { mostrar_modal: valor}, agencia: agencia}
        });
        
    }   
    function guardarRegistrosUsiario(e){
        
        e.preventDefault()
        
        return new Promise((resolve, reject)=>{
            
            laravelAPI.get('agencias/crear-sucursal', { params : state.crear_registro })
            .then(function (response) {
                
                mostrarMensajeExito(response.data.mensaje)
                //cerrar modal 
                mostrarOcultarModalCrear(null, null)
                
                setState(previousState => {
                    return{ ...previousState,  refrescar : state.refrescar+1 }
                });

                resolve(response)
                
            })
            .catch(function (error) {
                 
           
                if (error.response.status==422) {
                    //actualizar los errores
                    setState(previousState => {
                        return { ...previousState,  errores_validacion:error.response.data}
                    });
                }

                mostrarMensajeError(error.response)

                reject(null)
            })
        })
         
        
        
    }

    function handleInputChange(event){

        setState(previousState => {
            return { ...previousState,  crear_registro : { ... state.crear_registro, [event.target.name] : event.target.value}}
        });

  
    }

    function handleCheckboxChange (event){

        let newArray = [... state.crear_registro.departamentos, event.target.value];
       
        if (state.crear_registro.departamentos.includes(event.target.value)) {
          newArray = newArray.filter(rol => rol !== event.target.value);
        }

        setState(previousState => {
            return { ...previousState,  crear_registro : { ... state.crear_registro, departamentos : newArray}}
        });

        console.log(state.crear_registro.departamentos)
    }

    async function eliminarSucursal(id) {
        
        let esConfirmar = await mensajeDeConfirmacion('Seguro que Desea Eliminar el Registro')

        if(!esConfirmar) return

        return new Promise((resolve, reject)=>{

            laravelAPI.get(`agencias/eliminar-sucursal/${id}`, { params :  {id} })
            .then(function (response) {
                mostrarMensajeExito(response.data.mensaje)
                
                //cerrar modal 
                mostrarOcultarModalSucursal(null)

                setState(previousState => {
                    return{ ...previousState,  refrescar : state.refrescar+1 }
                });
                 
                resolve(response)
                
            })
            .catch(function (error) {
                reject(null)
            })
        })

    }


}

export default Contenido;