import React, { useState,useEffect  } from 'react';
import ListadoTable from './ListadoTable';
import {PageContent,} from 'react-adminlte-3'
import {Form,Check} from 'react-bootstrap';
import EncabezadoModulo from './EncabezadoModulo';
import Crear from './Crear';
import { useParams,Navigate  } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck} from '@fortawesome/free-solid-svg-icons';
//importar configuracion axio
import { laravelAPI } from '../../../config.js'

//importar funciones helpers
import { mostrarMensajeExito,cerrarMenuLateral,mostrarMensajeError,mensajeDeConfirmacion } from '../../../helpers'

import ClipLoader from "react-spinners/ClimbingBoxLoader";
const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };


function Contenido() {
    //estatus
    const [state, setState] = useState({
        registros : null,
        titulo_modulo: 'Tracking',
        modal_crear : { mostrar_modal: false},
        modal_vehiculo:{ mostrar_modal: false},
        crear_registro:{    id: null,
                            descripcion_as:'',
                            descripcion_web:'',
                            codigo_as:'',
                            mensaje:'',
                             
                        },
        errores_validacion : { 
                                descripcion_as:'',
                                descripcion_web:'',
                                codigo_as:'',
                                mensaje:''


                            },
        mostrar_inactivos : 0,
        refrescar : 0,
        transito : null
    });

    //variables y funcion para bloquear pantalla
    const [cargando, setCargando] = useState(false)
    const gestionarCarga = (valor) =>{  setCargando(valor); }

    //funciones que se ejecutaran al iniciar el componente
    useEffect( async() => {
        //cerrar menu lateral
        cerrarMenuLateral()
        
        let datos_consultados = await obtenerRegistros();

        //validar que exista datos de cliente
        if (datos_consultados.data) {
            //actualizar datos de registros
            actualizarDatosDeRegistros(datos_consultados.data);
        } 
        
        
    }, [state.mostrar_inactivos,state.refrescar]);


    

    if (cargando) {
        return (
          <div  className='mx-auto' style={style}>
            <ClipLoader color='rgb(53, 213, 182)' loading={true}  size={16} />
          </div>
        )
    }else{

        return (
            <div>
            <EncabezadoModulo 
                titulo_modulo={state.titulo_modulo}
                funcionMostrarOcultarModalCrear = {()=>mostrarOcultarModalCrear(null)}
            />
            
            <PageContent>
                <section className="content">
                
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='row'>
                                        <div className='col-9'>

                                            <h3 className="card-title">Listado Registros</h3>
                                        </div>
                                        <div className='col-3'>
                                            <Form>
                                                <Form.Check 
                                                    type="switch"
                                                    id="mostrar-inactivos"
                                                    label="Mostrar Inactivos"
                                                    onClick= {ActivosInactivos}
                                                />
                                                
                                            </Form>
                                        </div>
                                        </div>

                                    </div>
                                    
                                    <div className="card-body">
                                        <div className='table-responsive p-0 w-100'>
                                            <ListadoTable 
                                                registros={state.registros}
                                                funcionEliminarRegistro = {eliminarRegistro}
                                                funcionMostrarOcultarModalCrear = {mostrarOcultarModalCrear}
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Crear 
                        data={state}
                        funcionMostrarOcultarModalCrear = {mostrarOcultarModalCrear}
                        funcionGuardarRegistro  = {guardarRegistro}
                        funcionHandleInputChanger = {handleInputChange}
                    />
    
                    
                </section>
            </PageContent>
            </div>
        )
    }
    
    


    function obtenerRegistros() {
        
        let parametros = {
            mostrar_inactivos : state.mostrar_inactivos
        };

        return new Promise((resolve, reject)=>{
            //bloquear pantalla
            gestionarCarga(true)
            laravelAPI.get('configuracion/tracking', { params : parametros })
            .then(function (response) {
                //desbloquear pantalla
                gestionarCarga(false)
                resolve(response)
                
            })
            .catch(function (error) {
                //desbloquear pantalla
                gestionarCarga(false)
                reject(null)
            })
        })

    }

 

    function actualizarDatosDeRegistros(data) {
         
        
        setState(previousState => {
            return { ...previousState,  registros:data}
        });
    }

 

    //craer funcion para que se ejecute al mostrar o ocultar modal 
    function mostrarOcultarModalCrear(transito=null){
    
        let valor = state.modal_crear.mostrar_modal ? false : true
         
        let formulario = {  id: null,
                            description_as:'',
                            descripcion_web:'',
                            codigo_as:'',
                            mensaje:''

                        }

        /* VALIDAR SI ES EL FORMULARIO DE EDICION */
        if (transito) {
            formulario = {
                id: null,
                descripcion_as: transito.description_as,
                descripcion_web: transito.description_web,
                codigo_as: transito.id,
                mensaje:transito.mensaje,

            }
        }else{

            setState(previousState => {
                return { ...previousState,  errores_validacion : { 
                    descripcion_as:'',
                    descripcion_web:'',
                    codigo_as:'',
                    mensaje:''

    
                }}
            });

            
        }

                
        setState(previousState => {
            return { ...previousState,  modal_crear : { mostrar_modal: valor}, transito: transito, crear_registro : formulario}
        });
      
    }


    
    function guardarRegistro(e){
        
        e.preventDefault()
        
        return new Promise((resolve, reject)=>{
            //bloquear pantalla
            gestionarCarga(true)
            laravelAPI.get('configuracion/tracking/registrar', { params : state.crear_registro })
            .then(function (response) {
               
                mostrarMensajeExito(response.data.mensaje)
                //cerrar modal 
                mostrarOcultarModalCrear(null)
                
                setState(previousState => {
                    return{ ...previousState,  refrescar : state.refrescar+1 }
                });

                //desbloquear pantalla
                gestionarCarga(false)
                resolve(response)
                
            })
            .catch(function (error) {
                 
                
                if (error.response.status==422) {
                    //actualizar los errores
                    setState(previousState => {
                        return { ...previousState,  errores_validacion:error.response.data}
                    });
                }

                mostrarMensajeError(error.response)
                //desbloquear pantalla
                gestionarCarga(false)
                reject(null)
            })
        })
         
        
        
    }

    function handleInputChange(event){

        setState(previousState => {
            return { ...previousState,  crear_registro : { ... state.crear_registro, [event.target.name] : event.target.value}}
        });

  
    }

    async function eliminarRegistro(id) {
        
        let esConfirmar = await mensajeDeConfirmacion('Seguro que Desea Continuar')

        if(!esConfirmar) return

        return new Promise((resolve, reject)=>{
            //bloquear pantalla
            gestionarCarga(true)
            laravelAPI.get(`configuracion/tracking/cambiar-estatus/${id}`, { params :  {id} })
            .then(function (response) {
                mostrarMensajeExito(response.data.mensaje)
                
                setState(previousState => {
                    return{ ...previousState,  refrescar : state.refrescar+1 }
                });

                //desbloquear pantalla
                gestionarCarga(false)
                resolve(response)
                
            })
            .catch(function (error) {
                
                //desbloquear pantalla
                gestionarCarga(false)
                
                reject(null)
            })
        })

    }

    
    async function ActivosInactivos(event) {
        
        let valor = event.target.checked ? 1 : 0;

        setState(previousState => {
            return{ ...previousState,  mostrar_inactivos : valor }
        }); 

    }

    
 



}
 
 

export default Contenido;