import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEye, faCheck, faCar, faCarSide } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";

//importar funciones helpers
import {
  obtenerEstadoAccion,
  obtenerEstadoPedido,
  validarExistenciaDePermiso,
  obtenerEstado,
  fomatearFecha, mensajeDeConfirmacion,
  confirmarVehiculo,
  mostrarMensajeExito
} from '../../../helpers'
//importar configuracion axio
import { laravelAPI } from '../../../config.js'

class ListadoTable extends Component {

  //renderizar componente
  render() {
    let pedidos = null;
    let registros = this.props.registros;
    /* validar que existan registros */
    if (registros) {
      pedidos = registros.data;
    }

    return (
      <>
        <table className="table table-head-fixed text-nowrap table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>codigo_sucursal</th>
              <th>nombre_sucursal</th>
              <th>codigo_almacen</th>
              <th>nombre_almacen</th>
              <th>origen</th>
              <th>numero_orden</th>
              <th>numero_pedido</th>
              <th>codigo_usuario</th>
              <th>vendedor</th>
              <th>codigo_cliente</th>
              <th>rason_social</th>
              <th>numero_documento</th>
              <th>codigo_transportista</th>
              <th>transportista</th>
              <th>forma_pago</th>
              <th>metodo_paga</th>
              <th>condicion_pago</th>
              <th>tipo_documento</th>
              <th>nombre_documento</th>
              <th>fecha_pedido</th>
              <th>hora_documento</th>
              <th>pedido_recibido</th>
              <th>pedido_recibido_codigo</th>
              <th>pedido_recibido_descripcion</th>
              <th>pedido_recibido_fecha</th>
              <th>pedido_recibido_hora</th>
              <th>pedido_recibido_usuario</th>
              <th>pedido_aprobado</th>
              <th>pedido_aprobado_codigo</th>
              <th>pedido_aprobado_descripcion</th>
              <th>pedido_aprobado_fecha</th>
              <th>pedido_aprobado_hora</th>
              <th>pedido_aprobado_usuario</th>
              <th>pedido_sacador</th>
              <th>pedido_sacador_codigo</th>
              <th>pedido_sacador_descripcion</th>
              <th>pedido_sacador_fecha</th>
              <th>pedido_sacador_hora</th>
              <th>pedido_sacador_usuario</th>
              <th>pedido_empaquetado</th>
              <th>pedido_empaquetado_codigo</th>
              <th>pedido_empaquetado_descripcion</th>
              <th>pedido_empaquetado_fecha</th>
              <th>pedido_empaquetado_hora</th>
              <th>pedido_empaquetado_usuario</th>
              <th>pedido_guia</th>
              <th>pedido_guia_codigo</th>
              <th>pedido_guia_descripcion</th>
              <th>pedido_guia_fecha</th>
              <th>pedido_guia_hora</th>
              <th>pedido_guia_usuario</th>
              <th>pedido_facturado</th>
              <th>pedido_facturado_codigo</th>
              <th>pedido_facturado_descripcion</th>
              <th>pedido_facturado_fecha</th>
              <th>pedido_facturado_hora</th>
              <th>pedido_facturado_usuario</th>
              <th>pedido_en_ruta</th>
              <th>pedido_en_ruta_codigo</th>
              <th>pedido_en_ruta_descripcion</th>
              <th>pedido_en_ruta_fecha</th>
              <th>pedido_en_ruta_hora</th>
              <th>pedido_en_ruta_usuario</th>
              <th>pedido_entregado</th>
              <th>pedido_entregado_codigo</th>
              <th>pedido_entregado_descripcion</th>
              <th>pedido_entregado_fecha</th>
              <th>pedido_entregado_hora</th>
              <th>pedido_entregado_usuario</th>

            </tr>
          </thead>
          <tbody>
            {
              /* validar que existan registros */
              this.props.registros &&
              /* iterar los registros para la tabla */
              pedidos.map((pedido, key) => {
                return (
                  <tr key={'lista-' + key}>
                    <td>{key + 1}</td>
                    <td>{pedido.codigo_sucursal}</td>
                    <td>{pedido.nombre_sucursal}</td>
                    <td>{pedido.codigo_almacen}</td>
                    <td>{pedido.nombre_almacen}</td>
                    <td>{pedido.origen}</td>
                    <td>{pedido.numero_orden}</td>
                    <td>{pedido.numero_pedido}</td>
                    <td>{pedido.codigo_usuario}</td>
                    <td>{pedido.vendedor}</td>
                    <td>{pedido.codigo_cliente}</td>
                    <td>{pedido.rason_social}</td>
                    <td>{pedido.numero_documento}</td>
                    <td>{pedido.codigo_transportista}</td>
                    <td>{pedido.transportista}</td>
                    <td>{pedido.forma_pago}</td>
                    <td>{pedido.metodo_paga}</td>
                    <td>{pedido.condicion_pago}</td>
                    <td>{pedido.tipo_documento}</td>
                    <td>{pedido.nombre_documento}</td>
                    <td>{pedido.fecha_documento}</td>
                    <td>{pedido.hora_documento}</td>
                    <td>{pedido.pedido_recibido ? 'SI' : 'NO'}</td>
                    <td>{pedido.pedido_recibido ? pedido.pedido_recibido_codigo : ''}</td>
                    <td>{pedido.pedido_recibido ? pedido.pedido_recibido_descripcion : ''}</td>
                    <td>{pedido.pedido_recibido ? pedido.pedido_recibido_fecha : ''}</td>
                    <td>{pedido.pedido_recibido ? pedido.pedido_recibido_hora : ''}</td>
                    <td>{pedido.pedido_recibido ? pedido.pedido_recibido_usuario : ''}</td>
                    <td>{pedido.pedido_aprobado ? 'SI' : 'NO'}</td>
                    <td>{pedido.pedido_aprobado ? pedido.pedido_aprobado_codigo : ''}</td>
                    <td>{pedido.pedido_aprobado ? pedido.pedido_aprobado_descripcion : ''}</td>
                    <td>{pedido.pedido_aprobado ? pedido.pedido_aprobado_fecha : ''}</td>
                    <td>{pedido.pedido_aprobado ? pedido.pedido_aprobado_hora : ''}</td>
                    <td>{pedido.pedido_aprobado ? pedido.pedido_aprobado_usuario : ''}</td>
                    <td>{pedido.pedido_sacador ? 'SI' : 'NO'}</td>
                    <td>{pedido.pedido_sacador ? pedido.pedido_sacador_codigo : ''}</td>
                    <td>{pedido.pedido_sacador ? pedido.pedido_sacador_descripcion : ''}</td>
                    <td>{pedido.pedido_sacador ? pedido.pedido_sacador_fecha : ''}</td>
                    <td>{pedido.pedido_sacador ? pedido.pedido_sacador_hora : ''}</td>
                    <td>{pedido.pedido_sacador ? pedido.pedido_sacador_usuario : ''}</td>
                    <td>{pedido.pedido_empaquetado  ? 'SI' : 'NO'}</td>
                    <td>{pedido.pedido_empaquetado  ? pedido.pedido_empaquetado_codigo : '' }</td>
                    <td>{pedido.pedido_empaquetado  ? pedido.pedido_empaquetado_descripcion : '' }</td>
                    <td>{pedido.pedido_empaquetado  ? pedido.pedido_empaquetado_fecha : '' }</td>
                    <td>{pedido.pedido_empaquetado  ? pedido.pedido_empaquetado_hora : '' }</td>
                    <td>{pedido.pedido_empaquetado  ? pedido.pedido_empaquetado_usuario : '' }</td>
                    <td>{pedido.pedido_guia ? 'SI' : 'NO'}</td>
                    <td>{pedido.pedido_guia ? pedido.pedido_guia_codigo : '' }</td>
                    <td>{pedido.pedido_guia ? pedido.pedido_guia_descripcion : '' }</td>
                    <td>{pedido.pedido_guia ? pedido.pedido_guia_fecha : '' }</td>
                    <td>{pedido.pedido_guia ? pedido.pedido_guia_hora : '' }</td>
                    <td>{pedido.pedido_guia ? pedido.pedido_guia_usuario : '' }</td>
                    <td>{pedido.pedido_facturado ? 'SI' : 'NO'}</td>
                    <td>{pedido.pedido_facturado ? pedido.pedido_facturado_codigo  : '' }</td>
                    <td>{pedido.pedido_facturado ? pedido.pedido_facturado_descripcion  : '' }</td>
                    <td>{pedido.pedido_facturado ? pedido.pedido_facturado_fecha  : '' }</td>
                    <td>{pedido.pedido_facturado ? pedido.pedido_facturado_hora  : '' }</td>
                    <td>{pedido.pedido_facturado ? pedido.pedido_facturado_usuario  : '' }</td>
                    <td>{pedido.pedido_en_ruta ? 'SI' : 'NO'}</td>
                    <td>{pedido.pedido_en_ruta ? pedido.pedido_en_ruta_codigo : '' }</td>
                    <td>{pedido.pedido_en_ruta ? pedido.pedido_en_ruta_descripcion : '' }</td>
                    <td>{pedido.pedido_en_ruta ? pedido.pedido_en_ruta_fecha : '' }</td>
                    <td>{pedido.pedido_en_ruta ? pedido.pedido_en_ruta_hora : '' }</td>
                    <td>{pedido.pedido_en_ruta ? pedido.pedido_en_ruta_usuario : '' }</td>
                    <td>{pedido.pedido_entregado ? 'SI' : 'NO'}</td>
                    <td>{pedido.pedido_entregado ? pedido.pedido_entregado_codigo : '' }</td>
                    <td>{pedido.pedido_entregado ? pedido.pedido_entregado_descripcion : '' }</td>
                    <td>{pedido.pedido_entregado ? pedido.pedido_entregado_fecha : '' }</td>
                    <td>{pedido.pedido_entregado ? pedido.pedido_entregado_hora : '' }</td>
                    <td>{pedido.pedido_entregado ? pedido.pedido_entregado_usuario : '' }</td>
                  </tr>
                )
              })
            }

          </tbody>
        </table>
        <div className='mt-4'>
          <Pagination
            activePage={registros ? registros.current_page : 0}
            itemsCountPerPage={registros ? registros.per_page : 0}
            totalItemsCount={registros ? registros.total : 0}

            onChange={(pageNumber) => {
              this.props.funcionObtenerPedidos(pageNumber)
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="Primera página"
            lastPageText="Último página"
          />
        </div>
      </>
    )

  }

  validarDespacho(tracking) {

    if (tracking) {
      if (tracking.vehicles_id) {

        return (<><span className='badge badge-info'>Despachado </span>

          {
            tracking.transit_status_id != 32 &&
            tracking.transit_status_id != 31 &&
            validarExistenciaDePermiso(['generar-transbordo']) &&
            <>
              <button title='Generar Transbordo' onClick={async () => {
                await this.generarTransbordo(tracking.dispatch_id)
                this.props.funcionRefrescar()
              }
              } className='btn btn-sm btn-primary ml-2'>
                <FontAwesomeIcon icon={faCarSide} />
              </button>
              <button title='Descargar Unidad' onClick={async () => {
                await this.descargarUnidad(tracking.dispatch_id, tracking.vehicles_id)
                this.props.funcionRefrescar()
              }
              } className='btn btn-sm btn-danger ml-2'>
                <FontAwesomeIcon icon={faCarSide} />
              </button>
            </>
          }
        </>)
      }
    }

    return <span className='badge badge-danger'> Sin Despacho</span>

  }


  async generarTransbordo(pedido_id) {
    let confirmacion = await confirmarVehiculo('Si Desea Generar un Transbordo Seleccione el Vehiculo')

    if (confirmacion) {
      return new Promise((resolve, reject) => {

        laravelAPI.get(`despachos/registrarTransbordo`, { params: { vehiculo_id: confirmacion, pedido_id: pedido_id } })
          .then(function (response) {

            mostrarMensajeExito(response.mensaje)
            resolve(response)

          })
          .catch(function (error) {
            reject(null)
          })
      })
    }


  }

  async descargarUnidad(pedido_id, vehiculo_id) {
    let confirmacion = await mensajeDeConfirmacion('Si Desea descargar el Vehiculo')

    if (confirmacion) {
      return new Promise((resolve, reject) => {

        laravelAPI.get(`despachos/registrarDescarga`, { params: { pedido_id: pedido_id, vehiculo_id: vehiculo_id } })
          .then(function (response) {

            mostrarMensajeExito(response.mensaje)

            resolve(response)

          })
          .catch(function (error) {
            reject(null)
          })
      })
    }


  }

}

export default ListadoTable;
