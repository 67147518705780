import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCode, faComment,faBookmark, faTint, faTachometerAlt,faShoppingBag,faCircle } from '@fortawesome/free-solid-svg-icons';

 

class Crear extends Component {

    
    
    render() {
        
        let data = this.props.data
        let formulario = data.crear_registro
        let titulo = `${formulario.id ? 'Editar':'Crear'} ${data.titulo_modulo}`
        let mostrar_modal = data.modal_crear.mostrar_modal
        let marcas = []
        let tipos = []
        let conductores = []
        let sedes = []
        let errores_validacion = data.errores_validacion

        //validar que existan registros
        if(data.registros){
            marcas = data.registros.marcas_vehiculo
            tipos = data.registros.tipos_vehiculo
            conductores = data.registros.conductores
            sedes = data.registros.sedes
        }
 
        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.props.funcionMostrarOcultarModalCrear()}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={this.props.funcionGuardarRegistro}>
                         
                    <Modal.Body>

                                              
                            { errores_validacion.descripcion_web && <span className="form-text text-danger mb-1">{errores_validacion.descripcion_web[0]}</span> }
                             
                            <InputGroup className='mb-3'>
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faComment}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Descripción Web"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="descripcion_web"
                                    id="placa"
                                    isInvalid={errores_validacion.descripcion_web ? true : false}
                                    onChange = {this.props.funcionHandleInputChanger}
                                    value={formulario.descripcion_web}
                                />
                            </InputGroup>

                            { errores_validacion.descripcion_as && <span className="form-text text-danger mb-1">{errores_validacion.descripcion_as[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faComment}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Descripción As400"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="descripcion_as"
                                    id="descripcion_as"
                                    isInvalid={errores_validacion.descripcion_as ? true : false}
                                    onChange = {this.props.funcionHandleInputChanger}
                                    value={formulario.descripcion_as}


                                />
                            </InputGroup>
                            
                            { errores_validacion.codigo_as && <span className="form-text text-danger mb-1">{errores_validacion.codigo_as[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faCode}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Codigo As400"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="codigo_as"
                                    id="codigo_as"
                                    isInvalid={errores_validacion.codigo_as ? true : false}
                                    onChange = {this.props.funcionHandleInputChanger}
                                    value={formulario.codigo_as}


                                />
                            </InputGroup>    

                            { errores_validacion.mensaje && <span className="form-text text-danger mb-1">{errores_validacion.mensaje[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faComment}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Codigo As400"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="mensaje"
                                    id="mensaje"
                                    isInvalid={errores_validacion.mensaje ? true : false}
                                    onChange = {this.props.funcionHandleInputChanger}
                                    value={formulario.mensaje}


                                />
                            </InputGroup>

                          


                            
                            
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.props.funcionMostrarOcultarModalCrear(null)} >
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>Guardar</Button>
                    </Modal.Footer>
                    </Form>
                </Modal>
            </>
        );
    }
}




export default Crear;