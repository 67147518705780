import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCar, faEnvelope, faBookmark, faIdCard, faTachometerAlt, faShoppingBag, faCircle } from '@fortawesome/free-solid-svg-icons';



class Crear extends Component {



    render() {

        let data = this.props.data
        let formulario = data.crear_registro
        let titulo = `${formulario.id ? 'Editar' : 'Crear'} ${data.titulo_modulo}`
        let mostrar_modal = data.modal_crear.mostrar_modal
        let tipos = []
        let errores_validacion = data.errores_validacion

        //validar que existan registros
        if (data.registros) {
            tipos = data.registros.tipos
        }

        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={() => this.props.funcionMostrarOcultarModalCrear()}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={this.props.funcionGuardarRegistrosUsiario}>

                        <Modal.Body>


                            {errores_validacion.nombre && <span className="form-text text-danger mb-1">{errores_validacion.nombre[0]}</span>}

                            <InputGroup className='mb-3'>
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Nombres"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="nombre"
                                    id="nombre"
                                    isInvalid={errores_validacion.nombre ? true : false}
                                    onChange={this.props.funcionHandleInputChanger}
                                    value={formulario.nombre}
                                />
                            </InputGroup>

                            {errores_validacion.apellido && <span className="form-text text-danger mb-1">{errores_validacion.apellido[0]}</span>}

                            <InputGroup className='mb-3'>
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese apellidos"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="apellido"
                                    id="apellido"
                                    isInvalid={errores_validacion.apellido ? true : false}
                                    onChange={this.props.funcionHandleInputChanger}
                                    value={formulario.apellido}
                                />
                            </InputGroup>

                            
                            {errores_validacion.tipo_documento && <span className="form-text text-danger mb-1">{errores_validacion.tipo_documento[0]}</span>}

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faBookmark} /></InputGroup.Text>

                                <Form.Select value={formulario.tipo_documento} isInvalid={errores_validacion.tipo_documento ? true : false} aria-label="Default select example" name='tipo_documento' className='custom-select form-control-border border-width-2' onChange={this.props.funcionHandleInputChanger}>

                                    <option>Seleccione una Tipo de Documento</option>

                                    {
                                        tipos.map((tipo) => {
                                            return (
                                                <option key={tipo.id} value={tipo.id}>{tipo.name}</option>
                                            )
                                        })
                                    }

                                </Form.Select>

                            </InputGroup>

                            
                            {errores_validacion.numero_documento && <span className="form-text text-danger mb-1">{errores_validacion.numero_documento[0]}</span>}

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faIdCard} /></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Numero de Documento"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="numero_documento"
                                    id="numero_documento"
                                    onChange={this.props.funcionHandleInputChanger}
                                    isInvalid={errores_validacion.numero_documento ? true : false}
                                    value={formulario.numero_documento}

                                />
                            </InputGroup>     

                            {errores_validacion.correo && <span className="form-text text-danger mb-1">{errores_validacion.correo[0]}</span>}

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faEnvelope } /></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Correo"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="correo"
                                    id="correo"
                                    onChange={this.props.funcionHandleInputChanger}
                                    isInvalid={errores_validacion.correo ? true : false}
                                    value={formulario.correo}

                                />
                            </InputGroup>
                             
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.props.funcionMostrarOcultarModalCrear(null)} >
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>Guardar</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        );
    }
}




export default Crear;