import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import imagen_pedido from '../../assets/images/logo.jpg';



class Mostrar extends Component {



    render() {

        let data = this.props.data
        let pedido = this.props.data.pedido
        let cliente = this.props.data.cliente
        if (!pedido) {
            return <></>
        }

        let titulo = `Guia de Remision`
        let mostrar_modal = data.modal_pedido.mostrar_modal

        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={() => this.props.funcionMostrarPedido(null)}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>

                            <div className='col-sm-6 col-md-12 col-lg-6'>
                                <div className="card card-widget widget-user-2">
                                    <div className="widget-user-header bg-info">
                                        <div className="widget-user-image">
                                            <img className="img-circle elevation-2" src={imagen_pedido} alt="User Avatar"></img>
                                        </div>
                                        <h3 className="widget-user-username">Nº {pedido.referral_guide_number}</h3>
                                        <h5 className="widget-user-desc">{titulo} Remitente</h5>
                                    </div>
                                    <div className="card-footer p-0">
                                        <div className='table-responsive p-0 w-100'>

                                            <table className="table table-head-fixed text-nowrap table-sm">
                                                <thead>
                                                <tr >
                                                    <th className='bg-secondary'>Cod. Cliente</th>
                                                    <th className='bg-secondary' colSpan='3'>Nombre o Rason Social</th>
                                                    
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{cliente ? cliente.code : pedido.client?pedido.client.code :''}</td>
                                                        <td colSpan='3'>{cliente ? cliente.name_social_reason : pedido.social_reason }</td>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-secondary'>R.U.C Nº</td>
                                                        <td colSpan='3'>{cliente ? cliente.document_number : pedido.client?pedido.client.document_number : '' }</td>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-secondary'>Fecha</td>
                                                        <td colSpan='3'>{pedido.document_date}</td>
                                                         
                                                    </tr>  
                                                    
                                                    <tr>
                                                        <td className='bg-secondary'>Nº Pedido</td>
                                                        <td colSpan='3'>{pedido.document_order_number}</td>
                                                         
                                                    </tr>                                                    
                                                    <tr>
                                                         
                                                        <td className='bg-secondary'>Nº O/Compra</td>
                                                        <td colSpan='3'>{pedido.order_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-secondary'>Transportista</td>
                                                        <td colSpan='3'>{pedido.transportista}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-secondary'>Transportista</td>
                                                        <td colSpan='3'>{pedido.transportista2}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-secondary'>Direccion</td>
                                                        <td colSpan='3'>{pedido.direccion_envio}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-secondary'>Punto de Partida</td>
                                                        <td colSpan='3'></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-secondary'>Punto de Llegada</td>
                                                        <td colSpan='3'></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-secondary'>Fecha de Traslado</td>
                                                        <td colSpan='3'></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-secondary'>Nombre de Contacto</td>
                                                        <td colSpan='3'>{pedido.contacto_nombre}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='bg-secondary'>Numero de Contacto</td>
                                                        <td colSpan='3'>{pedido.contacto_numero}</td>
                                                    </tr>
                                                </tbody>
                                                    
                                            </table>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-12 col-lg-6'>
                                <div className='table-responsive p-0 w-100'>

                                    <table className="table table-head-fixed text-nowrap table-sm">
                                        <thead>
                                            <tr >
                                                <th className='bg-secondary'>Item</th>
                                                <th className='bg-secondary'>Cant.</th>
                                                <th className='bg-secondary'>Descripciòn</th>
                                            
                                                
                                            </tr>
                                        </thead>
                                    <tbody>
                                        {
                                            pedido.details.map((detalle, key) =>{

                                                return (
                                                        <tr key={key}>
                                                            <td>{key+1}</td>
                                                            <td>{detalle.cecandsp}</td>
                                                            <td>{detalle.cedscart}</td>
                                                        </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                                    
                                    </table>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.props.funcionMostrarPedido(null)} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}




export default Mostrar;