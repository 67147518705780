import React, { useState, useEffect } from 'react';
import { PageContent, } from 'react-adminlte-3'
import EncabezadoModulo from './EncabezadoModulo';
import axios from 'axios';
import { cerrarMenuLateral,fomatearFecha } from '../../../helpers.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faLocationArrow, faMap } from '@fortawesome/free-solid-svg-icons';
import { Manager } from "socket.io-client";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { MarkerIcon } from '../../../assets/js/react-leaflet-icon';
import MarkerImagen from '../Marker/MarkerImagen';
import Markers from "../Marker/Markers";

//importar configuracion axio
import { DIRECCION_SOCKET } from '../../../config.js'
 
const ENDPOINT = DIRECCION_SOCKET;



function Contenido() {
    //estatus
    const [state, setState] = useState({
        registros: null,
        titulo_modulo: 'Rastreo Flotas',
        seccion: null,
        vehiculos: null,
        dispositivos: null
    });

    const manager = new Manager(ENDPOINT);
    const socket = manager.socket("/configuracion-gps");

    //funciones que se ejecutaran al iniciar el componente
    useEffect(async () => {
        //cerrar menu lateral
        cerrarMenuLateral()

        socket.emit("suscribir");

        //respuesta de suscripcion
        socket.on('resultado-suscripcion', resultadoSuscripcion);

        //respuesta de estado de plataforma gps
        socket.on('estatus-plataforma-gps', sincronizarEstatusPlataformaGps);

        //escuchar cambios de secciones
        socket.on('cambio-en-seccion', sincronizarEstatusPlataformaGps);
        
        setInterval(() => {
            socket.emit("actualizar-ubicaciones-gps")
          }, 30000);

    }, []);




    return (
        <div>
            <PageContent>
                
                <MapContainer center={[-12.046141514664367, -77.04262613595078]} zoom={13}>
                    <TileLayer
                         //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                         url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                         maxZoom= {20}
                         subdomains={['mt0','mt1','mt2','mt3']}
                    />
                    {state.dispositivos &&
                        state.dispositivos.items &&
                        state.dispositivos.items.map((dispositivo, key) => {
                            let latitude = null
                            let longitude = null

                            if (dispositivo.vehiculo) {
                                if(dispositivo.vehiculo.ultima_ubicacion){
                                   
                                    latitude = dispositivo.vehiculo.ultima_ubicacion ? dispositivo.vehiculo.ultima_ubicacion.latitude : null
                                    longitude = dispositivo.vehiculo.ultima_ubicacion ? dispositivo.vehiculo.ultima_ubicacion.longitude : null
                                }
                            }
                            
                            if (!latitude) {
                                return(<></>)
                            }

                            return <Markers key={key}
                                            position={[latitude, longitude]} 
                                            icon={<MarkerImagen vehiculo={dispositivo.vehiculo}   />}>

                                        <Popup>
                                            <div>
                                                <p><b>Placa:</b> {dispositivo.vehiculo.plate}</p>
                                                <p><b>Modelo:</b> {dispositivo.vehiculo.model}</p>
                                                <p><b>Color:</b> {dispositivo.vehiculo.color}</p>
                                                <p><b>Marca:</b> {dispositivo.vehiculo.brand}</p>
                                            </div>

                                            actualizado a las {fomatearFecha(dispositivo.vehiculo.ultima_ubicacion.messageTime)}
                                        </Popup>
                                    </Markers>
                        })

                    }

                </MapContainer>
            </PageContent>
        </div>
    )



    function imprimirLog(data) {
        console.log(data)
    }

    function sincronizarEstatusPlataformaGps(data) {

        console.log(data)

        setState(previousState => {
            return { ...previousState, seccion: data.sessionGPS, dispositivos: data.dispositivosGps }
        });


    }

    function resultadoSuscripcion(data) {

        //validar que se suscribio correctamente
        if (data.ok) {
            socket.emit("consultar-estatus-plataforma-gps");
        }
    }

 

}




export default Contenido;