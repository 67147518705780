import React, { Component } from 'react';


class CardVehiculo extends Component {

  //renderizar componente
  render() {

    return (
      <div className="info-box shadow-none bg-dark">
        <span className={`info-box-icon bg-${this.props.color}`}>{this.props.icono}</span>
        <div className="info-box-content">
          <span className="info-box-text">{this.props.titulo}</span>
          <div className="product-info">{this.props.total}
            <a className="product-title" style={{'cursor': 'pointer'}} onClick={()=>{this.props.mostrarVehiculos(this.props.filtro)}}> 
              <span className="badge badge-info float-right text-dark">Detalles</span>
            </a>
          </div>
        </div>
      </div>
    )
  }



}

export default CardVehiculo;
