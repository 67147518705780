import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faEye,faCheck } from '@fortawesome/free-solid-svg-icons';

//importar funciones helpers
import {obtenerEstado } from '../../helpers'

class ListadoTable extends Component {

  //renderizar componente
  render() {
    return (
         <table className="table table-head-fixed text-nowrap table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Id</th>
                <th>Conductor</th>
                <th>Correo</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                /* validar que existan registros */
                this.props.registros &&
                  /* iterar los registros para la tabla */
                  this.props.registros.conductores.map((conductor, key) =>{

                    return(
                      <tr key={conductor.id}>
                        <td>{key+1}</td>
                        <td>{conductor.id}</td>
                        <td>{conductor.user.name}</td>
                        <td>{conductor.user.email}</td>
                        <td>{obtenerEstado(conductor.status)}</td>
                        <td>
                          <div>
                            <button  title='Visualizar' className='btn btn-xs btn-info mr-2' onClick={()=>{this.props.funcionMostrarConductor(conductor)}}><FontAwesomeIcon icon={faEye} color="white" /></button>
                            <button  title='Eliminar' className={`btn btn-xs btn-${conductor.status?'danger':'success'}`} onClick={()=>{this.props.funcionEliminarRegistro(conductor.id)}}>
                              <FontAwesomeIcon icon={conductor.status==1 ? faTrash : faCheck} color="white" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })
              }
               
            </tbody>
          </table>
 
    )

  }



}

export default ListadoTable;
