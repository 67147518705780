import React,  { useState,useEffect  } from 'react';
import ProgresoPedido from './ProgresoPedido';
import {PageContent} from 'react-adminlte-3'
import { useParams,Navigate  } from 'react-router-dom';
//importar componentes
import EncabezadoModulo from './EncabezadoModulo';
import BuscarCliente from './BuscarCliente';
import MapaMYM from './MapaMYM';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch,faUserTie} from '@fortawesome/free-solid-svg-icons';
import Mostrar from './Mostrar';
import { Manager } from "socket.io-client";
//importar configuracion axio
import { laravelAPI,DIRECCION_SOCKET } from '../../config.js'

//importar funciones helpers
import { mostrarMensajeExito,cerrarMenuLateral,fechaActual } from '../../helpers'
import ClipLoader from "react-spinners/ClimbingBoxLoader";
const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
const ENDPOINT = DIRECCION_SOCKET;


function Contenido() {

    const manager = new Manager(ENDPOINT);
    const socket = manager.socket("/configuracion-gps");
    //obtener parametros por url 
    let parametros = useParams()
        
    //variables y funcion para bloquear pantalla
    const [cargando, setCargando] = useState(false)
    const gestionarCarga = (valor) =>{  setCargando(valor); }
    
    //estatus
    const [state, setState] = useState({
        cliente : null,
        pedido : null,
        mostrar_panel_busqueda:true,
        modal_pedido:{ mostrar_modal: false},
        titulo_modulo: <div><FontAwesomeIcon icon={faUserTie} className='mr-2 text-primary'  />Panel de Cliente</div>,
        listar_pedidos: true,
        mostrarProcesoPedido : mostrarProcesoPedido,
        mostrarPedidos : mostrarPedidos,
        mostrarPedido : mostrarPedido,  
        obtenerRegistros: obtenerRegistros,  
        sincronizarEstatusPlataformaGps,
        resultadoSuscripcion,
        dispositivos: null,
        refrescar :0,
        center_mapa:[-12.046141514664367, -77.04262613595078],
        calificacion: 0,
        filtros: '',
        titleDetail: '',
        otros_filtros: {
                                numero_pedido: '',
                                codigo_cliente: '',
                                fecha_desde: '',
                                fecha_hasta: ''
                        },
        socket,
    });

    //funciones que se ejecutaran al iniciar el componente
    useEffect( async() => {
        //bloquear pantalla
        gestionarCarga(true)

        //cerrar menu lateral
        cerrarMenuLateral()

        //validar que existan parametros
        if (Object.keys(parametros).length==0) {
            //mostrar panel de busqueda
            mostrarUocultarPanelBusqueda(true)
            //desbloquear pantalla
            gestionarCarga(false)
            return;
        }

        let datos_consultados = await obtenerRegistros(parametros);

         //validar que exista datos de cliente
        if (datos_consultados.data.cliente) {
            //mostrar panel de seguimiento
            mostrarUocultarPanelBusqueda(false)
            //actualizar datos del cliente
            actualizarDatosDelCliente(datos_consultados.data.cliente,datos_consultados.data.titleDetail);
        }else{
            mostrarMensajeExito('No Existen Pedidos Registrados para el Cliente')
            mostrarUocultarPanelBusqueda(true)

        } 

        //if (state.pedido && state.pedido.tracking.transit_status_id == 31) {
             
            //respuesta de suscripcion
            socket.on('resultado-suscripcion', resultadoSuscripcion);
            
            //respuesta de estado de plataforma gps
            socket.on('estatus-plataforma-gps', sincronizarEstatusPlataformaGps);
            
            //escuchar cambios de secciones
            socket.on('cambio-en-seccion', sincronizarEstatusPlataformaGps);
            
            socket.emit("suscribir");

            setInterval(() => {
                socket.emit("actualizar-ubicaciones-gps")
            }, 10000);
        //} 

        //desbloquear pantalla
        gestionarCarga(false)
 
        
    }, [state.refrescar ]);


    
    if (cargando) {
        return (
          <div  className='mx-auto' style={style}>
            <ClipLoader color='rgb(53, 213, 182)' loading={true}  size={16} />
          </div>
        )
    }else{
        return <div>
                    <EncabezadoModulo 
                        titulo_modulo={state.titulo_modulo}
                    />
        
                    <PageContent>
                        <section className="content">
                            <div class="card">
                                {
                                    state.cliente && !state.cliente.pedidos &&
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-md-7" style={{textAlign:'right'}}>
                                                &nbsp;
                                            </div>
                                            <div class="col-md-2" style={{textAlign:'right'}}>
                                            <div className="form-group" title='En caso no seleccionar el rango de fecha, se tomara desde la semana anterior'>
                                                    <label>Desde</label>
                                                    <input type='date' className='form-control' name='fecha_desde' value={state.otros_filtros.fecha_desde} onChange={modificarInputChange}></input>
                                                </div>
                                            </div>
                                            <div class="col-md-2" style={{textAlign:'right'}}>
                                                <div className="form-group" title='En caso no seleccionar el rango de fecha, se tomara desde la semana anterior'>
                                                    <label>Hasta</label>
                                                    <input type='date' className='form-control' name='fecha_hasta' value={state.otros_filtros.fecha_hasta} onChange={modificarInputChange}></input>
                                                </div>
                                            </div>
                                            <div class="col-md-1" style={{paddingTop:'2%'}}>
                                                <button className='form-control btn btn-primary' type="button"  onClick={obtenerRegistroBusqueda}>
                                                    <FontAwesomeIcon icon={faSearch}  /> Buscar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                                <div class="card-body">
                                    <div className="container-fluid">
                                        <div className="row"> 
                                        {
                                            !state.mostrar_panel_busqueda &&
                                            <>
                                                <div className="col-md-12">
                                                    <ProgresoPedido state={state} handleInputChange={handleInputChange} />
                                                </div>
                                                
                                                {/* <div className="col-md-6">
                                                    <MapaMYM state={state}/>
                                                </div> */}
                                            </>
                                        }
                                            
                                        {
                                            state.mostrar_panel_busqueda &&
                                            
                                            <div className="col-md-12">
                                                <BuscarCliente />
                                            </div>
                                        }
                                    
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Mostrar 
                                data={state}
                                funcionMostrarPedido = {mostrarPedido}
                        />
                    </PageContent>
                    
                </div>
    }
    
    


    function obtenerRegistros(parametros) {
        parametros.estatus = state.filtros
        parametros.desde = state.otros_filtros.fecha_desde
        parametros.hasta = state.otros_filtros.fecha_hasta
        return new Promise((resolve, reject)=>{

            laravelAPI.get('cliente/pedidos', { params : parametros })
            .then(function (response) {
                resolve(response)
                
            })
            .catch(function (error) {
                
                reject(null)
            })
        })

    }

    function obtenerRegistroBusqueda() {
        //parametros.estatus = state.filtros
        //parametros.desde = state.otros_filtros.fecha_desde
        //parametros.hasta = state.otros_filtros.fecha_hasta
        let parametros2 = {
                cliente_id: state.cliente.code,
                estatus: state.filtros,
                fecha_desde: state.otros_filtros.fecha_desde,
                fecha_hasta: state.otros_filtros.fecha_hasta,
                //codigo_as400 : !EsAdministrador() ? obtenerCodigoAs400() : '',
        };
        //console.log(parametros2);
        gestionarCarga(true)
        return new Promise((resolve, reject)=>{

            laravelAPI.get('cliente/pedidos', { params : parametros2 })
            .then(function (response) {
                gestionarCarga(false)
                resolve(response)
                handleInputChange('');
                
            })
            .catch(function (error) {
                gestionarCarga(false)
                reject(null)
            })
        })

    }

    function mostrarUocultarPanelBusqueda(valor) {
        setState(previousState => {
            return { ...previousState,  mostrar_panel_busqueda:valor}
        });
    }

    function actualizarDatosDelCliente(cliente, titleDetail) {
         
        
        setState(previousState => {
            return { ...previousState,  cliente,titleDetail:titleDetail, titulo_modulo : cliente.social_reason }
        });
    }


    function mostrarProcesoPedido(pedido){
        
        //actualizar los datos del pedido
        setState(previousState => {
            return { ...previousState, calificacion:pedido.calificacion ?pedido.calificacion.valor : 0  , pedido : pedido,listar_pedidos: false,refrescar: state.refrescar+1 }
        });

    }

    function mostrarPedidos(){
        
        //actualizar los datos del pedido
        setState(previousState => {
            return { ...previousState, pedido : null,listar_pedidos: true,refrescar: state.refrescar+1 }
        });

    }

    //craer funcion para que se ejecute al
    // mostrar o ocultar modal de pedido
    function mostrarPedido(pedido = null){
        

        let valor = state.modal_pedido.mostrar_modal ? false : true
        
        setState(previousState => {
            return { ...previousState,  modal_pedido : { mostrar_modal: valor}, pedido:pedido,refrescar: state.refrescar+1}
        });
      
    }

    function sincronizarEstatusPlataformaGps(data) {
        let filter_mapa = null;
        if (state.pedido) {
            filter_mapa = data.dispositivosGps.items.filter(data => {
                return data.vehiculo.plate == state.pedido.tracking.vehiculo.placa
            })  
        }

 
        let lat = filter_mapa ? filter_mapa[0].vehiculo.ultima_ubicacion.latitude : data.dispositivosGps.items[0].vehiculo.ultima_ubicacion.latitude
        let long = filter_mapa ? filter_mapa[0].vehiculo.ultima_ubicacion.longitude : data.dispositivosGps.items[0].vehiculo.ultima_ubicacion.longitude

 
        setState(previousState => {
            return { ...previousState, seccion: data.sessionGPS, dispositivos: data.dispositivosGps, center_mapa: [lat,long] }
        });


    }

    function resultadoSuscripcion(data) {

        //validar que se suscribio correctamente
        if (data.ok) {
            socket.emit("consultar-estatus-plataforma-gps");
        }
    }

    function modificarInputChange(event){

        setState(previousState => {
            return { ...previousState,  otros_filtros : { ... state.otros_filtros, [event.target.name] : event.target.value}}
        });

    }

    async function handleInputChange(filtro) {
        
        setState(previousState => {
            return { ...previousState, filtros: filtro, refrescar: state.refrescar + 1 }
        });
    }

    function refrescar() {
        setState(previousState => {
            return { ...previousState, refrescar: state.refrescar + 1 }
        });
    }


}
 
 

export default Contenido;