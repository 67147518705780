import React from 'react';
import {Line} from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js/auto'
   


export default class App extends React.Component {
  render() {
    
    const state = {
      labels: this.props.datos.labels,
      datasets: [
        {
          label: 'Recoje en Tienda',
          backgroundColor: 'rgba(91,192,222)',
          borderColor: 'rgba(91,192,222)',
          borderWidth: 1,
          data: this.props.datos.cliente
        },
        {
          label: 'Entregas En Lima',
          backgroundColor: 'rgba(34,187,51)',
          borderColor: 'rgba(34,187,51)',
          borderWidth: 1,
          data: this.props.datos.lima
        },
        {
          label: 'Entregas en Provincia',
          backgroundColor: 'rgba(187,33,36)',
          borderColor: 'rgba(187,33,36)',
          borderWidth: 1,
          data: this.props.datos.provincia
        } 
      ]
    }

    return (
      <div style ={{width : '100%'}}>
        <Line
          data={state}
          style ={{width : '100%'}}
          options={{
            title:{
              display:true,
              text:'Average Rainfall per month',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
      </div>
    );
  }
}