import React from 'react';
import {Bar} from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js/auto'
import { Button } from 'react-bootstrap';

   


export default class App extends React.Component {
  render() {
    console.log(this.props.datos);
    const state = {
      labels: this.props.datos.labels,
      datasets: [
        {
          label: 'P. Totales',
          backgroundColor: 'rgba(91,192,222)',
          borderColor: 'black',
          borderWidth: 1,
          data: this.props.datos.totales
        },
        {
          label: 'P. Recibidos',
          backgroundColor: 'rgba(240,173,78)',
          borderColor: 'black',
          borderWidth: 1,
          data: this.props.datos.recibidos
        },
        {
          label: 'P. Delivery',
          backgroundColor: 'rgba(51, 153, 255)',
          borderColor: 'black',
          borderWidth: 1,
          data: this.props.datos.delivery
        },
        {
          label: 'P. En ruta',
          backgroundColor: 'rgba(255, 102, 0)',
          borderColor: 'black',
          borderWidth: 1,
          data: this.props.datos.en_ruta
        },
        {
          label: 'P. En Preparación',
          backgroundColor: 'rgba(187,33,36)',
          borderColor: 'black',
          borderWidth: 1,
          data: this.props.datos.en_preparacion
        },
        {
          label: 'P. Entregados',
          backgroundColor: 'rgba(34,187,51)',
          borderColor: 'black',
          borderWidth: 1,
          data: this.props.datos.entregados
        }
      ]
    }

    return (
      <div style ={{width : '100%'}}>
        <Bar
          data={state}
          style ={{width : '100%'}}
          options={{
            plugins: {
              datalabels: {
                display: true,
                color: '#FF0000',
              }
            },
            title:{
              display:true,
              text:'Average Rainfall per month',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            },
            onClick: (e, elements) => {
              
              this.props.mostrarPedidosDeGrafica(elements[0].datasetIndex,elements[0].index)
              
 
            },
          }}
          
        />
      </div>
    );
  }
  
}
 