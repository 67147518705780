import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

 
export default class App extends React.Component {
  render() {
    const data = {
      labels: this.props.datos.labels,
      datasets: [
        {
          label: 'Pedidos',
          data: this.props.datos.total,
          backgroundColor: [
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
            `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 200)}, ${Math.floor(Math.random() * 300)})`,
          ],
          borderWidth: 1,
        },
      ],
      options:{
        legend: {
          display: false,
          position: "left"
        }
      }

      
    };

 

    return (<Doughnut style ={{width : '100%'}} data={data} />)
  }
   
}
