import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import imagen_conductor from '../../assets/images/conductor.png';



class Mostrar extends Component {



    render() {

        let data = this.props.data
        let conductor = this.props.data.conductor
        
        if (!conductor) {
            return <></>
        }

        let titulo = `Datos del Conductor`
        let mostrar_modal = data.modal_conductor.mostrar_modal

        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.props.funcionMostrarConductor(null)}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="card card-widget widget-user-2">
                            <div className="widget-user-header bg-info">
                                <div className="widget-user-image">
                                    <img className="img-circle elevation-2" src={imagen_conductor} alt="User Avatar"></img>
                                </div>
                                <h3 className="widget-user-username">{conductor.names}</h3>
                                <h5 className="widget-user-desc">{conductor.surnames}</h5>
                            </div>
                            <div className="card-footer p-0">
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <a  className="nav-link">
                                            Tipo de Documento <span className="float-right text-dark">{conductor.document_type_id}</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a  className="nav-link">
                                            Numero de Documento <span className="float-right text-dark">{conductor.document_number}</span>
                                        </a>
                                    </li>
                                      
                                </ul>
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.props.funcionMostrarConductor(null)} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}




export default Mostrar;