import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faEye,faCheck,faCar,faEdit } from '@fortawesome/free-solid-svg-icons';

//importar funciones helpers
import {obtenerEstadoAccion,obtenerEstado } from '../../../helpers'

class ListadoTable extends Component {

  //renderizar componente
  render() {
   
    return (
         <table className="table table-head-fixed text-nowrap table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Codigo As400</th>
                <th>Descripción As400</th>
                <th>Descripción Web</th>
                <th>Estatus</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                /* validar que existan registros */
                this.props.registros &&
                  /* iterar los registros para la tabla */
                  this.props.registros.configuraciones.map((transito, key) =>{

                    return(
                      <tr key={transito.id}>
                        <td>{key+1}</td>
                        <td>{transito.id}</td>
                        <td>{transito.description_as}</td>
                        <td>{transito.description_web}</td>
                        <td>{obtenerEstado(transito.status)}</td>
                        <td>
                          <div>
                            <button className='btn btn-xs btn-info mr-2' onClick={()=>{this.props.funcionMostrarOcultarModalCrear(transito)}}><FontAwesomeIcon icon={faEdit} color="white" /></button>
                            <button className={`btn btn-xs btn-${transito.status?'danger':'success'}`} onClick={()=>{this.props.funcionEliminarRegistro(transito.id)}}>
                              <FontAwesomeIcon icon={transito.status==1 ? faTrash : faCheck} color="white" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })
              }
               
            </tbody>
          </table>
 
    )

  }



}

export default ListadoTable;
