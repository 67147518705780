import './assets/css/App.css';
import { useState,useEffect  } from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './Router';
//import ComponenteDos from './components/ComponenteDos';
import 'react-dual-listbox/lib/react-dual-listbox.css';




const App = () => {
  

 
  return (
    
          <>
              {/* Componente del cuerpo del modulo para tabla*/}
              <Router />
          </>
      
      )

}

export default App;
