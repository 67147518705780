import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEye, faCheck, faCar, faEdit } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";

//importar funciones helpers
import { obtenerEstado } from '../../helpers'

class ListadoTable extends Component {

  //renderizar componente
  render() {
    let clientes = null;
    /* validar que existan registros */
    if (this.props.registros) {
      clientes = this.props.registros.clientes;
    }
    
    return (
      <>
        <table className="table table-head-fixed text-nowrap table-sm ">
          <thead>
            <tr>
              <th>#</th>
              <th>Codigo</th>
              <th>Documento</th>
              <th>Nombres o Razón Social</th>
              <th>Estatus</th>
              <th>Mostrar</th>
            </tr>
          </thead>
          <tbody>
            {
              /* validar que existan registros */
              clientes &&
              /* iterar los registros para la tabla */
              clientes.data.map((cliente, key) => {

                return (
                  <tr key={cliente.id}>
                    <td>{key + 1}</td>
                    <td>{cliente.code}</td>
                    <td>{cliente.document?cliente.document.abrv:''} {cliente.document_number}</td>
                    <td>{cliente.name_social_reason}</td>
                    <td>{obtenerEstado(cliente.reg_status)}</td>

                    <td>
                      <div>
                        <button  title='Visualizar' className='btn btn-xs btn-success mr-2' onClick={() => { this.props.funcionMostrarCliente(cliente) }}><FontAwesomeIcon icon={faEye} color="white" /></button>

                      </div>
                    </td>
                  </tr>
                )
              })
            }

          </tbody>
        </table>
        <div className='mt-4'>
          <Pagination 
            activePage={clientes ? clientes.current_page : 0}
            itemsCountPerPage={clientes ? clientes.per_page : 0}
            totalItemsCount={clientes ? clientes.total : 0}
            
            onChange={(pageNumber) => {
              this.props.funcionObtenerClientes(pageNumber)
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="Primera página"
            lastPageText="Último página"
          />
        </div>
      </>
    )

  }



}

export default ListadoTable;
