import React, {Component} from 'react';

//importar iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPowerOff, faBars,faRotate,faKey } from '@fortawesome/free-solid-svg-icons';
//importando componentes de la plantilla admin LTE
import { 
    NavHeader,
    Nav,
    NavToggle,
    NavLink,

} from 'react-adminlte-3'

import {eliminarToken,sincronizarAs400,validarExistenciaDePermiso,cambiarClaveUsuario } from '../helpers'

class MenuSuperior extends Component{

    //renderizar componente
    render(){
        return(
          <>
          <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="#" role="button">
                  <FontAwesomeIcon icon={faBars} />
                </a>
              </li>
               
            </ul>
        
            <ul className="navbar-nav ml-auto">
              
              {/*
              {
                validarExistenciaDePermiso(['sincronizar-pedidos']) &&

                <li className="nav-item">
                  <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                    <FontAwesomeIcon icon={faRotate} onClick={sincronizarAs400} title="Sincronizar" color="blue" />
                  </a>
                </li>              
              } */}
            
              <li className="nav-item">
                <a className="nav-link" onClick={()=>{cambiarClaveUsuario(null)}} data-widget="control-sidebar" data-slide="true" href="#" role="button">
                  <span><FontAwesomeIcon icon={faKey} title="Cambiar Clave"   />  Cambiar Clave</span>
                </a>
              </li>  
              <li className="nav-item">
                <a className="nav-link" data-widget="control-sidebar"  onClick={eliminarToken}  data-slide="true" href="#" role="button">
                  <span><FontAwesomeIcon icon={faPowerOff} title="Cerrar Sesión" color="red" /> Cerrar Sesión</span>
                </a>
              </li>
            </ul>
          </nav>
          </>
             
        )
    }



}

export default MenuSuperior;
