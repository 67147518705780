import React, { Component } from 'react';

 
class Card extends Component {

  //renderizar componente
  render() {
 
    return (
      <div className="col-lg-6 col-6">
        <div className={`small-box bg-${this.props.color}`}>
            <div className="inner">
                <h3>{this.props.total}</h3>
                <p>{this.props.titulo}</p>
            </div>
            <div className="icon">
                {this.props.icono}
            </div>
            {this.props.total>0 &&
              <a  role="button" onClick={()=>{this.props.handleInputChange(this.props.estatus)}} className="small-box-footer ">{this.props.icono2} Ver Detalles</a>
            }

            {this.props.total==0 &&
              <a  role="button" className="small-box-footer ">{this.props.icono2} Sin Pedidos</a>
            }
        </div>
      </div>
    )
  }



}

export default Card;
