import React, { Component } from 'react';

//importar iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import {confirmarVehiculo,mostrarMensajeExito,obtenerUsuarioId,cambiarVehiculoConductor } from '../../helpers'
import { laravelAPI } from '../../config.js'

class EncabezadoModulo extends Component {
  

  //renderizar componente
  render() {
 
    let titulo = this.props.titulo_modulo
    let placa = this.props.registro ? (this.props.registro.vehiculo ? this.props.registro.vehiculo.placa : null) : null
    let vehiculo_id = this.props.registro ?  (this.props.registro.vehiculo ? this.props.registro.vehiculo.id : null)  : null
     
    return (
      <div className='content-header'>
        <div className='row mb-2'>
          <div className='col-sm-10'>
            <h1 className='title'>{titulo} <button onClick={()=>{ window.location.replace(`/entrega/pedidos`)}}  title='Refrescar' className='btn   btn-info'>Actualizar Datos</button></h1>
            
            <h1 className='title'>
              <span className='text-info'>Vehiculo: </span>
              {placa}
              {
                placa &&
                <button onClick={()=>{this.CambiarVehiculo(vehiculo_id)}}
                        title='Editar'
                        className='btn btn-xs btn-info ml-2'>

                  <FontAwesomeIcon icon={faEdit} color="white" />
                </button>
              }
            </h1>
          </div>
          <div className='col-sm-2'>
            
            
          </div>
        </div>
      </div>
    )
  }

  async CambiarVehiculo(vehiculo_id){
    let confirmacion = await confirmarVehiculo('Si Desea Cambiar el Vehiculo')

    if(confirmacion){
      return new Promise((resolve, reject) => {

        laravelAPI.get(`vehiculo/cambiar-vehiculo`, { params: {usuario_id :obtenerUsuarioId() ,vehiculo_id:confirmacion, vehiculo_id_anterior : vehiculo_id } })
            .then(function (response) {

                mostrarMensajeExito(response.mensaje)
                cambiarVehiculoConductor(confirmacion)
                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
      })
    }


  }

}

export default EncabezadoModulo;
