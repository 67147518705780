import React, { useState, useEffect } from 'react';
import ListadoTable from './ListadoTable';
import { PageContent, } from 'react-adminlte-3'
import { Form, Check } from 'react-bootstrap';
import EncabezadoModulo from './EncabezadoModulo';
import Mostrar from './Mostrar';
import { useParams, Navigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
//importar configuracion axio
import { laravelAPI } from '../../config.js'

//importar funciones helpers
import { mostrarMensajeExito, cerrarMenuLateral, mostrarMensajeError, mensajeDeConfirmacion } from '../../helpers'
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClimbingBoxLoader";
const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };


function Contenido() {
    //estatus
    const [state, setState] = useState({
        registros: null,
        titulo_modulo: 'Clientes',
        modal_cliente: { mostrar_modal: false },
        mostrar_modal: false,
        mostrar_inactivos: 0,
        refrescar: 0,
        cliente: null,
        busqueda: ''
    });

    const [cargando, setCargando] = useState(false)
    
    const gestionarCarga = (valor) =>{
  
        setCargando(valor);
  
    }




    //funciones que se ejecutaran al iniciar el componente
    useEffect(async () => {
        //cerrar menu lateral
        cerrarMenuLateral()

        await obtenerRegistros();



    }, [state.mostrar_inactivos, state.refrescar]);



    if (cargando) {
        return (
          <div  className='mx-auto' style={style}>
            <ClipLoader color='rgb(53, 213, 182)' loading={true}  size={16} />
          </div>
        )
    }else{

        return (
            <div>
                <EncabezadoModulo
                    titulo_modulo={state.titulo_modulo}
                />

                <PageContent>
                    <section className="content">

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='row'>
                                                <div className='col-9'>

                                                    <h3 className="card-title">Listado Registros</h3>
                                                </div>
                                                <div className='col-3'>
                                                {/*  <Form>
                                                        <Form.Check
                                                            type="switch"
                                                            id="mostrar-inactivos"
                                                            label="Mostrar Inactivos"
                                                            onClick={ActivosInactivos}
                                                        />

                                                    </Form> */}
                                                    <form onSubmit={obtenerRegistrosFormulario} >
                                                        <div className="input-group input-group-sm">
                                                            <input type="text" name="table_search" onChange={handleInputChange} className="form-control float-right" placeholder="Search"></input>
                                                                <div className="input-group-append">
                                                                    <button type="button"  onClick={obtenerRegistros} className="btn btn-default">
                                                                        <FontAwesomeIcon icon={faSearch}  />
                                                                    </button>
                                                                </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="card-body">
                                            <div className='table-responsive p-0 w-100'>

                                                <ListadoTable
                                                    registros={state.registros}
                                                    funcionMostrarCliente={mostrarCliente}
                                                    funcionObtenerClientes={obtenerRegistros}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <Mostrar
                            data={state}
                            funcionMostrarCliente={mostrarCliente}

                        />


                    </section>
                </PageContent>
            </div>
        )
    }

    function obtenerRegistrosFormulario(e) {
        e.preventDefault();
        obtenerRegistros()
    }

    function obtenerRegistros(pageNumber = 1) {

        let parametros = {
            mostrar_inactivos: state.mostrar_inactivos,
            busqueda : state.busqueda
        };
        return new Promise((resolve, reject) => {
            //bloquear pantalla
            gestionarCarga(true)

            laravelAPI.get(`clientes/obtenerClientes?page=${pageNumber}`, { params: parametros })
                .then(function (response) {

                    //actualizar datos de registros
                    actualizarDatosDeRegistros(response.data);

                    //desbloquear pantalla
                    gestionarCarga(false)
                    resolve(response.data)
                })
                .catch(function (error) {
                    //desbloquear pantalla
                    gestionarCarga(false)
                    reject(null)
                })
        })

    }



    function actualizarDatosDeRegistros(data) {


        setState(previousState => {
            return { ...previousState, registros: data }
        });
    }



    async function ActivosInactivos(event) {

        let valor = event.target.checked ? 1 : 0;

        setState(previousState => {
            return { ...previousState, mostrar_inactivos: valor }
        });

    }


    //craer funcion para que se ejecute al
    // mostrar o ocultar modal de cliente
    function mostrarCliente(cliente = null) {

        let valor = state.modal_cliente.mostrar_modal ? false : true

        setState(previousState => {
            return { ...previousState, modal_cliente: { mostrar_modal: valor }, cliente: cliente }
        });

    }

    function handleInputChange(event){

        setState(previousState => {
            return { ...previousState,  busqueda :  event.target.value}
        });

  
    }



}



export default Contenido;