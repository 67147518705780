import React, { Component  } from 'react';
import axios from 'axios';
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faLocationArrow, faMap } from '@fortawesome/free-solid-svg-icons';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { MarkerIcon } from '../../assets/js/react-leaflet-icon';
import MarkerImagen from '../rastreo/Marker/MarkerImagen';
import Markers from "../rastreo/Marker/Markers";
//importar funciones helpers
import { cerrarMenuLateral, fomatearFecha } from '../../helpers.js';
import imagen_sin_foto from '../../assets/images/logo.jpg';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';

class MapaMYM extends Component {


    render() {

        let registros = this.props.data
        let vehiculo = this.props.data.vehiculo
        
        registros.dispositivo = this.props.dispositivos

        if (!vehiculo) {
            return <></>
        }

        const images = [];

        let titulo = `Ubicación del Vehiculo ${vehiculo.placa}`
        let mostrar_modal = registros.modal_mapa.mostrar_modal
        
        if (registros.dispositivos) {
            if (registros.dispositivos.items) {
                registros.dispositivos.items.map((dispositivo, key) => {
                    if (dispositivo.vehiculo.plate == vehiculo.placa) {
                        let latitude = dispositivo.vehiculo.ultima_ubicacion.latitude
                        let longitude = dispositivo.vehiculo.ultima_ubicacion.longitude
                        registros.center_mapa = [latitude,longitude]
                    }
                })
            }
        }
       
       
        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={() => this.props.funcionMostrarUbicacion(null)}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-sm-12' style={{ height: '500px', width: '100%' }}>
                                <MapContainer style={{ height: '100%', width: '100%' }} center={registros.center_mapa} zoom={15}>
                                    <TileLayer
                                        //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                                        maxZoom={20}
                                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                    />
                                    {registros.dispositivos &&
                                        registros.dispositivos.items &&

                                        registros.dispositivos.items.map((dispositivo, key) => {
                                            if (dispositivo.vehiculo.plate == vehiculo.placa) {


                                                let latitude = dispositivo.vehiculo.ultima_ubicacion.latitude
                                                let longitude = dispositivo.vehiculo.ultima_ubicacion.longitude

                                                return <Markers key={key}
                                                    position={[latitude, longitude]}
                                                    icon={<MarkerImagen vehiculo={dispositivo.vehiculo} />}>

                                                    <Popup>
                                                        <div>
                                                            <p><b>Placa:</b> {dispositivo.vehiculo.plate}</p>
                                                            <p><b>Modelo:</b> {dispositivo.vehiculo.model}</p>
                                                            <p><b>Color:</b> {dispositivo.vehiculo.color}</p>
                                                            <p><b>Marca:</b> {dispositivo.vehiculo.brand}</p>
                                                        </div>

                                                        actualizado a las {fomatearFecha(dispositivo.vehiculo.ultima_ubicacion.messageTime)}
                                                    </Popup>
                                                </Markers>
                                            }
                                        })
                                    }

                                </MapContainer>
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.props.funcionMostrarUbicacion(null)} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }


    //obtener los registros de pedido
    obtenerRegistroDeProcesoDePedido(detalle) {

        let descripcion = detalle.transit.description_web
        //validar si ya se agrego dicho detalle
        const result = this.registros.filter(registro => registro == descripcion);

        //si ya se agrego salimos de la funcion
        if (result.length > 0) {
            return null
        }

        //si no existe lo agregamos al arreglo
        this.registros.push(descripcion);

        return {
            id: detalle.id,
            titulo_estado: descripcion,
            fecha: `${detalle.date_of_work} ${detalle.work_time}`,
            descripcion: descripcion + ' de Manera Exitosa.',
            estado: 'Aprobado'
        };

    }
}
export default MapaMYM;




