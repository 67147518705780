import React, { Component } from 'react';
import logo from '../assets/images/logo.jpg';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartColumn,faPlane,faAngleLeft,faComments,faFile, faTh,faDashboard, faUserTie,faTruck, faCubes,faWrench,faLocationArrow,faMapMarked,faUser} from '@fortawesome/free-solid-svg-icons';

import { validarExistenciaDePermiso,obtenerNombreUsuario } from '../helpers'

//importando componentes de la plantilla admin LTE
import {
    SidebarWrapper,
    MainLogo,
    Sidebar,
    UserPanel

} from 'react-adminlte-3'

class MenuPrincipal extends Component {

    //renderizar componente
    render() {
        return (
            
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
              <a className="brand-link">
                <span className="brand-text font-weight-light">MMTrack</span>
              </a>
          
              <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src={logo} className="img-circle elevation-2" alt="User image"></img>
                    </div>
                    <div className="info d-block text-white">
                        {obtenerNombreUsuario()}
                    </div>
                </div>
          
                
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            
                        {
                            validarExistenciaDePermiso(['mostrar-dashboard']) &&

                            <li className="nav-item">
                                <Link to="/" className='nav-link' >
                                    <FontAwesomeIcon icon={faDashboard} className='mr-2' />
                                    Dashboard 
                                </Link>
                                    
                            </li>    
                        }
                        {
                            validarExistenciaDePermiso(['mostrar-usuarios']) &&
                            <li className="nav-item">
                                <Link to="/panel/cliente" className='nav-link' >
                                    <FontAwesomeIcon icon={faUserTie} className='mr-2' />
                                    Panel Cliente
                                </Link>
                            </li>                               

                        }    

                       
                        {
                            validarExistenciaDePermiso(['mostrar-asignar-carga']) &&
                            <li className="nav-item">
                                <Link to="/asignar/carga" className='nav-link' >
                                    <FontAwesomeIcon icon={faCubes} className='mr-2' />
                                    Asignar Carga
                                </Link>
                            </li>
                        
                        }   
                        {
                            validarExistenciaDePermiso(['mostrar-entrega-pedido']) &&
                            <li className="nav-item">
                                <Link to="/entrega/pedidos" className='nav-link' >
                                    <FontAwesomeIcon icon={faCubes} className='mr-2' />
                                    Entrega de Pedidos
                                </Link>
                            </li>

                        }
                        {
                            validarExistenciaDePermiso(['mostrar-regularizar-pedido']) &&
                            <li className="nav-item">
                                <Link to="/entrega/regularizar-pedidos" className='nav-link' >
                                    <FontAwesomeIcon icon={faCubes} className='mr-2' />
                                    Regularizar Pedidos
                                </Link>
                            </li>

                        }                           
                        {
                            validarExistenciaDePermiso(['mostrar-pedidos']) &&
                            <li className="nav-item">
                                <Link to="/pedidos" className='nav-link' >
                                    <FontAwesomeIcon icon={faCubes} className='mr-2' />
                                    Pedidos
                                </Link>
                            </li>                                 

                        }                            
                        {
                            validarExistenciaDePermiso(['mostrar-pedidos-vehiculos']) &&
                            <li className="nav-item">
                                <Link to="/pedidos-por-vehiculos" className='nav-link' >
                                    <FontAwesomeIcon icon={faCubes} className='mr-2' />
                                    Pedidos Por Vehiculos
                                </Link>
                            </li>                                 

                        }                              
                        {
                            validarExistenciaDePermiso(['mostrar-pedidos-vehiculos']) &&
                            <li className="nav-item">
                                <Link to="/pedidos-por-ayudantes" className='nav-link' >
                                    <FontAwesomeIcon icon={faCubes} className='mr-2' />
                                    Pedidos Por Ayudantes
                                </Link>
                            </li>                                 

                        }                           
                                                      
                        {
                            validarExistenciaDePermiso(['mostrar-clientes']) &&
                            <li className="nav-item">
                                <Link to="/clientes" className='nav-link' >
                                    <FontAwesomeIcon icon={faUserTie} className='mr-2' />
                                    Clientes
                                </Link>
                            </li>                                 

                        }
                        
                        {
                            validarExistenciaDePermiso(['mostrar-conductores','mostrar-vehiculos','mostrar-seguimiento','mostrar-ayudantes']) && 
                            
                            <li className="nav-item">
                                <a href="#" className="nav-link" >
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>
                                        <FontAwesomeIcon icon={faTh} className='mr-2' />
                                        Flotas
                                        <FontAwesomeIcon icon={faAngleLeft} className='right' />
    
                                    </p>
                                </a>
                                {
                                    validarExistenciaDePermiso(['mostrar-vehiculos']) && 
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/vehiculos" className='nav-link ' >
                                                <FontAwesomeIcon icon={faTruck} className='mr-2'  />
                                                Vehiculos
                                            </Link>
                                        </li>
                                    </ul>
                                }
                                {
                                    validarExistenciaDePermiso(['mostrar-ayudantes']) && 
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/ayudantes" className='nav-link ' >
                                                <FontAwesomeIcon icon={faUser} className='mr-2'  />
                                                Ayudantes
                                            </Link>
                                        </li>
                                    </ul>
                                }

                                {
                                    validarExistenciaDePermiso(['mostrar-conductores']) && 
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/conductores" className='nav-link ' >
                                                <FontAwesomeIcon icon={faUserTie} className='mr-2'  />
                                                Conductores
                                            </Link>
                                        </li>
                                    </ul>
                                }

                                {
                                    validarExistenciaDePermiso(['mostrar-seguimiento']) && 
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/rastreo/flota" className='nav-link ' >
                                                <FontAwesomeIcon icon={faMapMarked} className='mr-2'  />
                                                Seguimiento
                                            </Link>
                                        </li>
                                    </ul>
                                }
                            </li>               
                            
                        }
                        {
                            validarExistenciaDePermiso(['mostrar-usuarios']) &&
                            <li className="nav-item">
                                <a href="#" className="nav-link" >
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>
                                        <FontAwesomeIcon icon={faUser} className='mr-2' />
                                        Administración 
                                        <FontAwesomeIcon icon={faAngleLeft} className='right' />

                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="/administracion/usuarios" className='nav-link ' >
                                            <FontAwesomeIcon icon={faUser} className='mr-2'  />
                                            Usuarios  
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/administracion/agencias" className='nav-link ' >
                                            <FontAwesomeIcon icon={faPlane} className='mr-2'  />
                                            Agencias  
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>
                        }    
                                                {
                            validarExistenciaDePermiso(['mostrar-usuarios']) &&
                            <li className="nav-item">
                                <a href="#" className="nav-link" >
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>
                                        <FontAwesomeIcon icon={faFile} className='mr-2' />
                                        Reportes 
                                        <FontAwesomeIcon icon={faAngleLeft} className='right' />

                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="/reportes/pedidos" className='nav-link ' >
                                            <FontAwesomeIcon icon={faFile} className='mr-2'  />
                                            Pedidos
                                        </Link>
                                    </li>
                                     
                                </ul>
                            </li>
                        }

                        {
                            validarExistenciaDePermiso(['mostrar-configuracion-gps','mostrar-configuracion-ws']) &&
                        
                            <li className="nav-item">
                                <a href="#" className="nav-link" >
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>
                                        <FontAwesomeIcon icon={faWrench} className='mr-2' />
                                        Configuraciones
                                        <FontAwesomeIcon icon={faAngleLeft} className='right' />

                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    {
                                       validarExistenciaDePermiso(['mostrar-configuracion-gps']) && 
                                        <li className="nav-item">
                                            <Link to="/configuraciones/gps" className='nav-link ' >
                                                <FontAwesomeIcon icon={faLocationArrow} className='mr-2'  />
                                                GPS  
                                            </Link>
                                        </li>
                                    }
                                    {
                                        validarExistenciaDePermiso(['mostrar-configuracion-ws']) &&
                                        <li className="nav-item">
                                            <Link to="/configuraciones/whatsapp" className='nav-link ' >
                                                <FontAwesomeIcon icon={faComments} className='mr-2'  />
                                                whatsapp  
                                            </Link>
                                        </li>
                                    }
                                    {
                                        validarExistenciaDePermiso(['mostrar-configuracion-transito']) &&
                                        <li className="nav-item">
                                            <Link to="/configuraciones/transito" className='nav-link ' >
                                                <FontAwesomeIcon icon={faChartColumn} className='mr-2'  />
                                                Transito   
                                            </Link>
                                        </li>
                                    }
                                    
                                </ul>
                                
                            </li>
                        }

                    </ul>
                </nav>
              </div>
            </aside>
             

        )
    }



}

export default MenuPrincipal;
