import React, { useState, useEffect } from 'react';
import ListadoTable from './ListadoTable';
import { PageContent, } from 'react-adminlte-3'
import MapaMYM from './MapaMYM';
import Mostrar from './MostrarVehiculo';
import MostrarTrackng from './../pedidos/Mostrar';

import EncabezadoModulo from './EncabezadoModulo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
//importar configuracion axio
import { laravelAPI,DIRECCION_SOCKET } from '../../config.js'

import { Manager } from "socket.io-client";

//importar funciones helpers
import { mostrarMensajeExito, EsAdministrador, obtenerCodigoAs400, cerrarMenuLateral, mostrarMensajeError, mensajeDeConfirmacion, fechaActual} from '../../helpers'
import ClipLoader from "react-spinners/ClimbingBoxLoader";
const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
 

function Contenido() {

    const ENDPOINT = DIRECCION_SOCKET;
    
    const manager = new Manager(ENDPOINT);
    const socket = manager.socket("/configuracion-gps");
    //estatus
    const [state, setState] = useState({
        registros: null,
        titulo_modulo: 'Listado de Pedidos Por Vehiculos',
        refrescar: 0,
        pedido: null,
        pedido_tracking:null,
        filtros: 'todos',
        modal_mapa:{ mostrar_modal: true},
        modal_pedido:{ mostrar_modal: false},
        modal_vehiculo:{ mostrar_modal: false},
        modal_pedido_detalles:{ mostrar_modal: false},
        otros_filtros: {
                        numero_pedido: '',
                        codigo_cliente: '',
                        fecha_actual: ''
                    },
        mostrar_foto : null,
        dispositivos: null,
        vehiculo:null,
        center_mapa:[-12.046141514664367, -77.04262613595078],

    });

    //variables y funcion para bloquear pantalla
    const [cargando, setCargando] = useState(false)
    const gestionarCarga = (valor) =>{  setCargando(valor); }


    //funciones que se ejecutaran al iniciar el componente
    useEffect(async () => {
        //cerrar menu lateral
        cerrarMenuLateral()

        await obtenerRegistros();

        //respuesta de suscripcion
        socket.on('resultado-suscripcion', resultadoSuscripcion);
        
        socket.on('estatus-plataforma-gps', sincronizarEstatusPlataformaGps);

        //respuesta de estado de plataforma gps
        socket.emit("suscribir");
        
        setInterval(() => {
            socket.emit("actualizar-ubicaciones-gps")
        }, 10000);
    }, [state.refrescar]);


    if (cargando) {
        return (
          <div  className='mx-auto' style={style}>
            <ClipLoader color='rgb(53, 213, 182)' loading={true}  size={16} />
          </div>
        )
    }else{

        return (
            <div>
                <EncabezadoModulo
                    titulo_modulo={state.titulo_modulo}
                    funcionMostrarOcultarModalCrear={() => mostrarOcultarModalCrear(null)}
                />

                <PageContent>
                    <section className="content">

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <div className="form-group">
                                                        <div>
                                                            <label>Estado del Pedido</label> 
                                                        </div>
                                                        

                                                        <div>
                                                            <input type="radio" name='radio'  onChange={(checked)=>handleInputChange(checked)} value='todos' checked={state.filtros==='todos'}></input>
                                                            <span htmlFor="todos">Todos</span> 
                                                        </div>
                                                        <div>
                                                            <input type="radio" name='radio' onChange={(checked)=>handleInputChange(checked)} value="En Ruta" checked={state.filtros==='En Ruta'}></input>
                                                            <span htmlFor="En Ruta">En Ruta</span> 
                                                        </div>
                                                        <div>
                                                            <input type="radio" name='radio' onChange={(checked)=>handleInputChange(checked)} value="Disponible" checked={state.filtros==='Disponible'}></input>
                                                            <span htmlFor="Disponible">Disponible</span> 
                                                        </div>
                                                        <div>
                                                            <input type="radio" name='radio' onChange={(checked)=>handleInputChange(checked)} value="PRE-ASIGNADO" checked={state.filtros==='PRE-ASIGNADO'}></input>
                                                            <span htmlFor="PRE-ASIGNADO">Pre-Asignado</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <div className="form-group">
                                                        <label>Nº Placa</label>
                                                        <input className='form-control' name='numero_pedido' onKeyPress={onKeyUp} value={state.otros_filtros.numero_pedido} onChange={modificarInputChange}></input>
                                                    </div>
                                                    
                                                </div>
                                                <div className='col-sm-4'>
                                                    
                                                    <div className="form-group">
                                                        <label>Fecha</label>
                                                        <input type='date' className='form-control' name='fecha_actual' onKeyPress={onKeyUp} value={state.otros_filtros.fecha_actual} onChange={modificarInputChange}></input>
                                                    </div>
                                                    <button type="button"  onClick={obtenerRegistros} className="btn btn-primary ">
                                                        <FontAwesomeIcon icon={faSearch}  /> Buscar
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='table-responsive p-0'>
                                                <ListadoTable
                                                    registros={state.registros}
                                                    funcionMostrarPedido={mostrarPedido}
                                                    funcionMostrarOcultarModalCrear={mostrarOcultarModalCrear}
                                                    funcionObtenerPedidos={obtenerRegistros}
                                                    funcionRefrescar = {refrescarVista}
                                                    funcionMostrarUbicacion = {mostrarUbicacion}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <MapaMYM 
                            data={state}
                            dispositivos = {state.dispositivos}
                            funcionMostrarUbicacion = {mostrarUbicacion}
                        />

                        <Mostrar 
                            data={state}
                            funcionMostrarPedido = {mostrarPedido}
                            funcionRefrescar = {refrescarVista}
                            funcionMostrarTrackingPedido={mostrarTrackingPedido}
                         />
                         <MostrarTrackng
                            data={state}
                            funcionMostrarPedido = {mostrarTrackingPedido}
                             
                          />

                         
                        

                    </section>
                </PageContent>
            </div>
        )

    }



    function obtenerRegistros(pageNumber = 1) {

            let parametros = {
                mostrar_inactivos: state.mostrar_inactivos,
                estado_pedido: state.filtros,
                numero_pedido: state.otros_filtros.numero_pedido,
                codigo_cliente: state.otros_filtros.codigo_cliente,
                fecha: state.otros_filtros.fecha_actual,
                codigo_as400 : !EsAdministrador() ? obtenerCodigoAs400() : '',
        };

        return new Promise((resolve, reject) => {
            //bloquear pantalla
            gestionarCarga(true)

            laravelAPI.get(`pedidos/vehiculos-con-pedidos?page=${pageNumber}`, { params: parametros })
                .then(function (response) {

                    //actualizar datos de registros
                    actualizarDatosDeRegistros(response.data);
                    //desbloquear pantalla
                    gestionarCarga(false)
                    resolve(response)

                })
                .catch(function (error) {
                    //desbloquear pantalla
                    gestionarCarga(false)
                    reject(null)
                })
        })

    }



    function actualizarDatosDeRegistros(data) {
        setState(previousState => {
            return { ...previousState, registros: data }
        });
    }

    /**
     * metodo encargado de refrescar la vista
     *
     */
    function refrescarVista() {
        setState(previousState => {
            return { ...previousState, refrescar: state.refrescar + 1  }
        });
    }

    
    //craer funcion para que se ejecute al mostrar o ocultar modal 
    function mostrarOcultarModalCrear(vehiculo = null) {

        let valor = state.modal_crear.mostrar_modal ? false : true
        let formulario = {
                            id: null,
                            placa: '',
                            description: '',
                            modelo: '',
                            tipo: '',
                            marca: '',
                            color: '',
                            maxima_velocidad: '',
                            conductor_id: '',
                            sede: '',
                            capacidad_kilogramos: '',
                            capacidad_volumen: '',
                        }

        /* VALIDAR SI ES EL FORMULARIO DE EDICION */
        if (vehiculo) {
            formulario = {
                id: vehiculo.id,
                placa: vehiculo.placa,
                description: vehiculo.description,
                modelo: vehiculo.model,
                tipo: vehiculo.vehicle_type_id,
                marca: vehiculo.car_brands_id,
                color: vehiculo.color,
                sede: vehiculo.sede,
                capacidad_volumen: vehiculo.capacidad_volumen,
                capacidad_kilogramos: vehiculo.capacidad_kilogramos,
                maxima_velocidad: vehiculo.maximum_speed,
                conductor_id: vehiculo.driver_id ? vehiculo.driver_id : ''
            }
        }


        setState(previousState => {
            return {...previousState, modal_crear: {mostrar_modal: valor }, vehiculo: vehiculo, crear_registro: formulario }
        });

    }



    async function handleInputChange(event) {
        
        setState(previousState => {
            return { ...previousState, filtros: event.target.value, refrescar: state.refrescar + 1 }
        });
    }

    function modificarInputChange(event){

        setState(previousState => {
            return { ...previousState,  otros_filtros : { ... state.otros_filtros, [event.target.name] : event.target.value}}
        });

  
    }

    //craer funcion para que se ejecute al
    // mostrar o ocultar modal de pedido
    function mostrarPedido(pedido = null) {

        let valor = state.modal_vehiculo.mostrar_modal ? false : true
        
        setState(previousState => {
            return {...previousState, modal_vehiculo: {mostrar_modal: valor }, pedido: pedido }
        });

    }

    
    //craer funcion para que se ejecute al
    // mostrar o ocultar modal de tracking de pedido
    async function mostrarTrackingPedido(pedido = null) {

        let valor = state.modal_pedido_detalles.mostrar_modal ? false : true
        let valor2 = state.modal_vehiculo.mostrar_modal 
        let datos_pedido = pedido

        if (pedido) {
            valor2 = state.modal_vehiculo.mostrar_modal ? false : true
            datos_pedido = await obtenerDatosPedido(pedido.id);
        }

    
        setState(previousState => {
            return {...previousState, modal_pedido_detalles: {mostrar_modal: valor },modal_vehiculo: {mostrar_modal: valor2 }, pedido: datos_pedido }
        });

    }

    //craer funcion para que se ejecute al
    // mostrar o ocultar modal de pedido
    function mostrarUbicacion(vehiculo = null) {
        
        let valor = state.modal_mapa.mostrar_modal ? false : true

        if (vehiculo) {
            console.log('consultar-')
            socket.emit("consultar-estatus-plataforma-gps");
        }

        setState(previousState => {
            return {...previousState, modal_mapa: {mostrar_modal: valor }, vehiculo: vehiculo }
        });

    }
    
 
    async function onKeyUp(event) {
        if (event.charCode === 13) {
            //alert('hola'); 
        }
    }
    

    function sincronizarEstatusPlataformaGps(data) {
        console.log('sincronizarEstatusPlataformaGps')
        setState(previousState => {
            return { ...previousState, seccion: data.sessionGPS, dispositivos: data.dispositivosGps }
        });

    }

    function resultadoSuscripcion(data) {

        console.log(data)
        //validar que se suscribio correctamente
        if (data.ok) {
            socket.emit("consultar-estatus-plataforma-gps");
        }
    }

    function obtenerDatosPedido(id) {

    return new Promise((resolve, reject) => {
        //bloquear pantalla
        gestionarCarga(true)

        laravelAPI.get(`pedidos/obtener-datos-pedido`, { params: {id} })
            .then(function (response) {
                gestionarCarga(false)
                resolve(response.data)
            })
            .catch(function (error) {
                gestionarCarga(false)
                reject(null)
            })
    })

}
}



export default Contenido;