import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarked,faEye,faCheck,faCar,faCarSide,faShuttleVan,faMotorcycle,faTruck } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";

//importar funciones helpers
import {obtenerEstadoAccion,obtenerEstadoPedido,validarExistenciaDePermiso,obtenerEstado,fomatearFecha,mensajeDeConfirmacion,confirmarVehiculo,mostrarMensajeExito } from '../../helpers'
//importar configuracion axio
import { laravelAPI } from '../../config.js'

class ListadoTable extends Component {

  //renderizar componente
  render() {
    let pedidos = null;
    /* validar que existan registros */
    if (this.props.registros) {
      pedidos = this.props.registros.pedidos;
     }
    return (
      <>
         <table className="table table-head-fixed text-nowrap table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Mostrar</th>
                <th>Placa</th>
                <th>Conductor</th>
                <th>Pedidos</th>
                <th>Entregados</th>
                <th>En Ruta</th>
                <th>Pendientes</th>
                <th>Estatus</th>
                <th>Acciones</th>
                
              </tr>
            </thead>
            <tbody>
              {
                /* validar que existan registros */
                this.props.registros &&
                  /* iterar los registros para la tabla */
                  this.props.registros.pedidos.data.map((vehiculo, key) =>{
                    return(
                      <tr key={vehiculo.id}>
                        <td>{key+1}</td>
                        <td>
                          <div>
                            <button title='Visualizar' className='btn btn-xs btn-success mr-2' onClick={()=>{this.props.funcionMostrarPedido(vehiculo)}}><FontAwesomeIcon icon={faEye} color="white" /></button>
                            <button title='Mostrar Ubicación' className='btn btn-xs btn-info mr-2' onClick={()=>{this.props.funcionMostrarUbicacion(vehiculo)}}><FontAwesomeIcon icon={faMapMarked} color="white" /></button>
                          </div>
                        </td>
                        <td>
                          <FontAwesomeIcon icon={this.icono(vehiculo.tipo.id)} className='mr-2 text-info'  />
                          {vehiculo.placa}
                        </td>
                        <td>{vehiculo.conductor ? vehiculo.conductor.names : ''}</td>
                        <td>{vehiculo.total_pedidos}</td>
                        <td>{vehiculo.total_pedidos_entregados} (<b>{((vehiculo.total_pedidos_entregados * 100 )/vehiculo.total_pedidos).toFixed(2)}%</b>)</td>
                        <td>{vehiculo.total_pedidos_en_ruta} (<b>{((vehiculo.total_pedidos_en_ruta * 100 )/vehiculo.total_pedidos).toFixed(2)}%</b>)</td>
                        <td>{vehiculo.total_pedidos_pendientes} (<b>{((vehiculo.total_pedidos_pendientes * 100 )/vehiculo.total_pedidos).toFixed(2)}%</b>)</td>
                        <td>{obtenerEstadoAccion(vehiculo.action_status)}</td>
                        <td>{this.validarDespacho(vehiculo)}</td>

                      </tr>
                    )
                  })
              }
               
            </tbody>
          </table>
          <div className='mt-4'>
            <Pagination 
              activePage={pedidos ? pedidos.current_page : 0}
              itemsCountPerPage={pedidos ? pedidos.per_page : 0}
              totalItemsCount={pedidos ? pedidos.total : 0}
              
              onChange={(pageNumber) => {
                this.props.funcionObtenerPedidos(pageNumber)
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="Primera página"
              lastPageText="Último página"
            />
          </div>
      </>
    )

  }

  validarDespacho(vehiculo) {
 
    if (vehiculo.total_pedidos_pendientes==0 || vehiculo.total_pedidos_en_ruta > 0) {
      return
    }
    
   

 
    return (<> 
              {
                  validarExistenciaDePermiso(['generar-transbordo']) &&
                  <>
                  {/* <button  title='Generar Transbordo' onClick={async()=>{
                                              await this.generarTransbordo(vehiculo)
                                              this.props.funcionRefrescar()
                                            }
                                  } className='btn btn-sm btn-primary ml-2'>
                    <FontAwesomeIcon icon={faCarSide}  />
                  </button> */}
                  <button  title='Descargar Unidad' onClick={async()=>{ await this.descargarUnidad(vehiculo)
                                              this.props.funcionRefrescar()
                                        }
                                  } className='btn btn-sm btn-danger ml-2'>
                    <FontAwesomeIcon icon={faCarSide}  />
                  </button>
                  </>
              }
            </>)
     

 
  }


  async generarTransbordo(pedido_id){
    let confirmacion = await confirmarVehiculo('Si Desea Generar un Transbordo Seleccione el Vehiculo')

    if(confirmacion){
      return new Promise((resolve, reject) => {

        laravelAPI.get(`despachos/registrarTransbordo`, { params: {vehiculo_id:confirmacion, pedido_id:pedido_id} })
            .then(function (response) {

                mostrarMensajeExito(response.mensaje)
                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
      })
    }


  }
  
  async descargarUnidad(vehiculo){
    let confirmacion = await mensajeDeConfirmacion('Si Desea descargar el Vehiculo')

    if(confirmacion){

      let pedidos = vehiculo.pedidos.map((pedido) =>{return pedido.dispatch})

      return new Promise((resolve, reject) => {

        laravelAPI.get(`despachos/registrarDescargaMasiva`, { params: {pedidos:pedidos, vehiculo_id:vehiculo.id} })
            .then(function (response) {

                mostrarMensajeExito(response.mensaje)

                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
      })
    }


  }

  
  icono(tipo) {
 
    switch (tipo) {
      case 490:
        
       return faShuttleVan
      case 489:
      
        return faMotorcycle
      case 491:
    
        return faTruck
          
      default:
        return faTruck
    }
}

}

export default ListadoTable;
