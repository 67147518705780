import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';

// componentes de boostrap
import { InputGroup, FormControl, Form, Button } from 'react-bootstrap';

class BuscarCliente extends Component {
  state = {
    cliente_id: ''
  }

  //cambiar valor del id de cliente
  cambiarValorCliente =  (evt) =>{
    let valor = evt.target.value

    this.setState({
      cliente_id : valor
    })
    
  }
  
 

  //renderizar componente
  render() {
    


    return (


      <div>
        <h2 className="text-center display-4">Buscar Pedidos</h2>
        <div className="row">
          <div className="col-md-8 offset-md-2 text-center">
              <form onSubmit={(e)=>{e.preventDefault(); window.location.replace(`/panel/cliente/${this.state.cliente_id}`)}} >
                <div className="mb-3 input-group">
                  <span className="input-group-text" id="basic-addon1"> 
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                    <input 
                      placeholder="Ingrese Su numero de Documento"
                      aria-label="Ruc" 
                      aria-describedby="basic-addon1" 
                      className="form-control" 
                      onChange={this.cambiarValorCliente} 
                      
                    >
                    </input>
                </div>
              </form>
              <a href={`/panel/cliente/${this.state.cliente_id}`}>
                <Button variant="primary m-auto">Buscar</Button>
              </a>
          </div>
        </div>
      </div>

    )

  }



}

export default BuscarCliente;
