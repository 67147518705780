import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import imagen_vehiculo from '../../assets/images/vehiculo.png';



class Vehiculos extends Component {

    render() {

        let data = this.props.data
        let vehiculos = this.props.data.vehiculos
         
        if (!vehiculos) {
            return <></>
        }
        
        let color = "success"

        switch (data.tipo) {
            case "En Taller":
                color = "warning"
                break;
        
            default:
                color = "success"
                break;
        }

        let titulo = `Listado de Vehiculos`
        let mostrar_modal = data.modal_vehiculos.mostrar_modal

        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.props.funcionMostrarVehiculo(null)}
                    backdrop="static"
                    keyboard={false}
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="card card-widget widget-user-2">
                            <div className={`widget-user-header bg-${color}`}>
                                <div className="widget-user-image">
                                    <img className="img-circle elevation-2" src={imagen_vehiculo} alt="User Avatar"></img>
                                </div>
                                <h3 className="widget-user-username"><b>{data.tipo}</b></h3> 
                            </div>
                            <div className="card-footer p-0">
                                {
                                    vehiculos.map((vehiculo,key) =>{
                                        return(
                                            <ul className="nav flex-column" key={'vehiculo-'+key}>
                                                <li className="nav-item">
                                                    <a  className="nav-link">
                                                        {vehiculo.placa} <span className="float-right text-dark">{vehiculo.conductor ? `${vehiculo.conductor.names || vehiculo.conductor.surnames}`  : 'Sin Conductor'}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        )
                                    })
                                }
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.props.funcionMostrarVehiculo(null)} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}




export default Vehiculos;