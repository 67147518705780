import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck,faShuttleVan,faMotorcycle } from '@fortawesome/free-solid-svg-icons';


class ListadoTable extends Component {

  //renderizar componente
  render() {
    let existen_registros = this.props.data.pedidos_lima ? true: false;

    return (
      <div className="row">
         {
            /* validar que existan registros */
            this.props.registros &&
            /* iterar los registros para la tabla */
            this.props.registros.vehiculos.map((vehiculo, key) =>{
            return(
                    (vehiculo.conductor) &&
                    <div className="col-12 col-sm-6 col-md-3" key={vehiculo.id}>
                    <div className={vehiculo.action_status == 'Disponible' ? "info-box info-box bg-secondary" : "info-box info-box bg-dark"}>
                      <span className="info-box-icon bg-success elevation-1">
                      <FontAwesomeIcon icon={this.icono(vehiculo.vehicle_type_id)} className='mr-2'  />
                    </span>
                    <div className="info-box-content">
                      <span className="info-box-text" title={vehiculo.action_status}>{vehiculo.placa} ({vehiculo.action_status})</span>
                      <span className="info-box-text" > {vehiculo.conductor ? vehiculo.conductor.names : 'Sin Conductor'}</span>
                      <span className="info-box-number">
                        {
                          existen_registros &&
                          <button className='btn btn-primary btn-xs' onClick={()=>this.props.funcionCrearRegistro(vehiculo)}>Seleccionar</button>  
                        }
                        {
                          vehiculo.action_status=='PRE-ASIGNADO' &&
                          <button className='btn btn-info btn-xs ml-2' onClick={()=>this.props.funcionIniciarDespacho(vehiculo.id)}>Iniciar</button>  
                        }
                      </span>
                    </div>
                  </div>
                </div> 
              )
            })
        }
      </div>


    )

    

  }


  icono(tipo) {
 
      switch (tipo) {
        case 490:
          
         return faShuttleVan
        case 489:
        
          return faMotorcycle
        case 491:
      
          return faTruck
            
        default:
          return faTruck
      }
  }


}

export default ListadoTable;
