import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { fomatearFecha} from '../../helpers'


class DetalleProceso extends Component {

  //renderizar componente
  render() {
    let registro = this.props.registro;
    let pedido = this.props.pedido;
    let fecha_corta = pedido ? true : false;
    console.log(this.props)
    if (!registro) {
      return <></>
    }

    let clase_color = registro.estado=='Aprobado' ? '':''
    let estilo_opacity =  registro.estado=='Aprobado' ? '1':'1'

    return (
      <div id={`div-detalle-estado-${registro.id}`} key={registro.id}>
        <span className={`fas bg-${clase_color}`}  style={{opacity: estilo_opacity}}>
          <FontAwesomeIcon icon={faCheck} className='' color="white" />
        </span>

        <div className="timeline-item" style={{opacity: estilo_opacity}}>
          <span className="time text-dark text-bold"><i className="fas fa-clock "></i> <b>{fomatearFecha(registro.fecha,fecha_corta)}</b></span>
          <h3 className="timeline-header">
            <a href='#' onClick={()=>this.props.mostrarPedido(pedido)}>{registro.titulo_estado}</a>
            <span className={`badge badge-pull badge-${clase_color} ml-2`}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            {pedido &&
              <a onClick={() => this.props.mostrarProcesoPedido(pedido)} className='btn btn-primary btn-xs ml-2'>Ver Tracking</a>
            }
          </h3>

          {/* <div className="timeline-body">
            {registro.descripcion}
            {pedido &&
              <a onClick={() => this.props.mostrarProcesoPedido(pedido)} className='btn btn-primary btn-xs ml-2'>Ver Tracking</a>
            }
          </div> */}

        </div>
        
      </div>
      
    )

  }



}

export default DetalleProceso;
