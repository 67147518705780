import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPhone, faCar } from '@fortawesome/free-solid-svg-icons';
import imagen from '../../assets/images/vehiculo.png';
// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl,ProgressBar } from 'react-bootstrap';

//importar funciones helpers
import { confirmarAyudante,mostrarMensajeExito,mensajeDeConfirmacion} from '../../helpers'
//importar configuracion axio
import { laravelAPI } from '../../config.js'


class EntregarPedido extends Component {

  render() {

    let state = this.props.data
    let cliente = state.pedidos_en_destino
    let errores_validacion = state.errores_validacion
    let Clientes = [];
    let ids = [];
     
    return (
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className='row'>
                    <div className='col-12'>

                      <h3 className="card-title">Cerrar el Envio</h3>
                    </div>

                  </div>

                </div>

                <div className="card-body" >
                  <div className="row">
                    <form onSubmit={this.props.guardarEntrega} encType="multipart/form-data" id="imageForm">
                      <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                        <div className="card bg-light d-flex flex-fill">
                          <div className="card-header text-muted border-bottom-0">
                            {
                              !this.props.data.rol_ayudante &&
                              cliente[0].agencia_envio != "M & M  REPUESTOS Y SERVICIOS S.A." &&
                              <a  onClick={()=>this.props.funcionMostrarOcultarModalAgencia(cliente)} className="btn btn-sm btn-warning mr-2">
                                  Cambiar Agencia
                              </a>
                            }
                            {
                              !this.props.data.rol_ayudante &&
                              <a onClick={() => this.props.funcionCancelarEntrega(cliente, 'Cancelar')} className="btn btn-sm btn-danger float-right">
                                Cancelar
                              </a>
                            }
                            <p className='mt-3'>{cliente.length} pedidos</p>
                            <p><b>{cliente[0].agencia_envio}</b></p>
                          </div>
                          <div className="card-body pt-0">
                            <div className="row">
                              <div className="col-7">
                                <h2 className="lead"><b>{cliente[0].social_reason}</b></h2>
                                <p className="text-muted text-sm"><b>Comentario: </b> {cliente[0].comentario} </p>

                              </div>
                              <div className="col-5 text-center">
                                <img src={imagen} alt="user-avatar" className="img-circle img-fluid"></img>
                              </div>

                              <div className="col-12" id='formulario'>
                                <h4 className='text-center mt-2'>Llenar Formulario</h4>
                                
                                <Form.Group controlId="formFile"  className="mb-3">
                                  <Form.Label>Tipo De Entrega:</Form.Label>
                                  <Form.Select isInvalid={errores_validacion[`tipo_entrega`] ? true : false} aria-label="Default select example" name='tipo_entrega' className='custom-select form-control-border border-width-2' onChange = {this.props.modificarTipoEntrega}>
                                      <option key={0} value="">Seleccione Tipo De Entrega</option>
                                      <option key={1} value="TOTAL">TOTAL</option>
                                      <option key={2} value="PARCIAL">PARCIAL</option>
                                  </Form.Select>      
                                </Form.Group>
                                <div className="form-group">
                                  <label>Fecha</label>
                                  <input className='form-control'  onChange = {this.props.modificarTipoEntrega} type={'date'} defaultValue={state.crear_registro.fecha} name='fecha' ></input>
                                </div>
                                <div className="form-group">
                                  <label>Hora</label>
                                  <input className='form-control'  onChange = {this.props.modificarTipoEntrega} type={'time'} defaultValue={state.crear_registro.hora} name='hora' ></input>
                                </div>

                                {
                                   
                                  cliente.map((client, key) => {
                                    ids.push(client.order_delivery_detail_id);

                                    if (!Clientes.includes(client.customer_id)) {
                                      Clientes.push(client.customer_id);
                                                                            
                                      return (
                                        <div key={key}>
                                        <p><label className='text-info' style={{'font-size': 'large'}}>Cliente {client.customer_code}</label></p>
                                        <p>
                                          <label className='text-info' style={{'font-size': 'large'}}>Doc. {client.document_number}</label>
                                        </p>
                                        <Form.Group key={"p"+key} controlId="formFile"  className="mb-3">
                                          <Form.Label>Cargar foto del documento :</Form.Label>
                                          <Form.Control isInvalid = {errores_validacion[`imagen-${client.customer_id}`] ? true : false}   type="file" name={`imagen-${client.customer_id}`} onChange={(e) => {this.props.modificarImagen(e,client.customer_id,client.id,'peso')}} />
                                          <ProgressBar style={{'border': 'black 1px solid'}} now={this.props.data.progress_bar[`imagen-${client.customer_id}`] ? this.props.data.progress_bar[`imagen-${client.customer_id}`] : 0} />

                                        </Form.Group>
                                        <Form.Group key={"b"+key}  controlId="formFile"  className="mb-3">
                                          <Form.Label>Cargar Foto de bulto:</Form.Label>
                                          <Form.Control isInvalid = {errores_validacion[`imagen-bulto-${client.customer_id}`] ? true : false}   type="file" name={`imagen-bulto-${client.customer_id}`} onChange={(e) => {this.props.modificarImagen(e,client.customer_id,client.id,'bulto')}} />
                                          <ProgressBar   style={{'border': 'black 1px solid'}} now={this.props.data.progress_bar[`imagen-bulto-${client.customer_id}`] ? this.props.data.progress_bar[`imagen-bulto-${client.customer_id}`] : 0} />

                                        </Form.Group>
                                        
                                        </div>
                                      )
                                    }
                                  })
                                }

                               
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                          <div className="text-center">
                                {
                                  !this.props.data.rol_ayudante &&
                                  <Button  type='button' onClick={()=>{this.generarTransbordo(cliente[0].id,ids)}} className="btn btn-sm btn-primary" >Trasbordar </Button>
                                }
                                {
                                  this.props.data.rol_ayudante &&
                                  <Button  type='button' onClick={()=>{this.posponerEnvio(cliente[0].id,ids)}} className="btn btn-sm btn-primary" >Posponer </Button>
                                }
                                
                                <Button  type='submit' className="btn btn-sm btn-success ml-2" >Cerrar Entrega </Button>
                                
                          </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )

  }

  async generarTransbordo(pedido_id,ids){

    let confirmacion = await confirmarAyudante('Si Desea Generar un Transbordo Seleccione el Ayudante')
     
    if(confirmacion.id){

      let esConfirmar = await mensajeDeConfirmacion(`Para ${confirmacion.names} ${confirmacion.surnames}?`);
  
      if (!esConfirmar) {
        return;
      }
      return new Promise((resolve, reject) => {

        laravelAPI.get(`ayudantes/trasbordar-ayudante/${pedido_id}/${confirmacion.id}`,{params : {ordenes:ids}})
            .then(function (response) {

                mostrarMensajeExito(response.mensaje)
                window.location.replace(`/entrega/pedidos`)
                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
      })
    }


  }

  
  async posponerEnvio(pedido_id,ids){

    
    let esConfirmar = await mensajeDeConfirmacion(
      "Seguro de realizar la acción?"
    );

    if (!esConfirmar) {
      return;
    }

     
    if(esConfirmar){
      return new Promise((resolve, reject) => {

        laravelAPI.get(`ayudantes/posponer-entrega`,{params : {ordenes:ids}})
            .then(function (response) {

                mostrarMensajeExito(response.mensaje)
                window.location.reload()
                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
      })
    }


  }

}

export default EntregarPedido;
