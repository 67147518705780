import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,  faBookmark,  faTachometerAlt, faIdCard  } from '@fortawesome/free-solid-svg-icons';

 

class Crear extends Component {

    
    
    render() {
        let data = this.props.data
        console.log(data)
        let titulo = `Crear ${data.titulo_modulo}`
        let mostrar_modal = data.modal_crear.mostrar_modal
        let formulario = data.crear_registro
        let marcas = []
        let tipos = []
        let errores_validacion = data.errores_validacion
        //validar que existan registros
        if(data.registros){
             
            tipos = data.registros.tipos_documento
        }

 
        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={this.props.funcionMostrarOcultarModalCrear}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={this.props.funcionGuardarRegistrosUsiario}>
                         
                    <Modal.Body>
                            
                            { errores_validacion.nombres && <span className="form-text text-danger mb-1">{errores_validacion.nombres[0]}</span> }
                             
                            <InputGroup className='mb-3'>
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faUser}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Nombres"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="nombres"
                                    id="nombres"
                                    isInvalid={errores_validacion.nombres ? true : false}
                                    onChange = {this.props.funcionHandleInputChanger}
                                />
                            </InputGroup>

                            { errores_validacion.apellidos && <span className="form-text text-danger mb-1">{errores_validacion.apellidos[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faUser}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese pellidos"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="apellidos"
                                    id="apellidos"
                                    isInvalid={errores_validacion.apellidos ? true : false}
                                    onChange = {this.props.funcionHandleInputChanger}

                                />
                            </InputGroup>

                            { errores_validacion.tipo_documento && <span className="form-text text-danger mb-1">{errores_validacion.tipo_documento[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faBookmark}/></InputGroup.Text>

                                <Form.Select  isInvalid={errores_validacion.tipo_documento ? true : false} aria-label="Default select example" name='tipo_documento' className='custom-select form-control-border border-width-2' onChange = {this.props.funcionHandleInputChanger}>
                                    
                                    <option>Seleccione un Tipo</option>

                                    {
                                        tipos.map((tipo)=>{
                                            return (
                                                <option  key={tipo.id} value={tipo.id}>{tipo.name}</option>
                                            )                                          
                                        })
                                    }
                                    
                                </Form.Select>                                
                                    
                            </InputGroup>

                          

  
                            { errores_validacion.numero_documento && <span className="form-text text-danger mb-1">{errores_validacion.numero_documento[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faIdCard}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Numero de Documento"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="numero_documento"
                                    id="numero_documento"
                                    onChange = {this.props.funcionHandleInputChanger}
                                    isInvalid={errores_validacion.numero_documento ? true : false}

                                />
                            </InputGroup>
                            
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.funcionMostrarOcultarModalCrear} >
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>Guardar</Button>
                    </Modal.Footer>
                    </Form>
                </Modal>
            </>
        );
    }
}




export default Crear;