import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import imagen_vehiculo from '../../assets/images/vehiculo.png';
import imagen_sin_foto from '../../assets/images/logo.jpg';
import { fomatearFecha} from '../../helpers'
import DetalleProceso from '../panelCliente/DetalleProceso';
import StarRatings from 'react-star-ratings';

//importar funciones helpers
import { obtenerEscala} from '../../helpers'



class Cancelar extends Component {
    
    registros = [];

    render() {
        this.registros = []

        let data = this.props.data
        let pedidos = this.props.data.pedidos
       
        if (!pedidos) {
            return <></>            
        }

        let titulo = `Cancelar Entrega`
        let mostrar_modal = data.modal_cancelar.mostrar_modal
        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.props.funcioncancelarEntrega(null)}
                    backdrop="static"
                    keyboard={false}
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-sm-12'>.
                                <InputGroup className='mb-3' >
                                    <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faComment}/></InputGroup.Text>
                                    <FormControl className='form-control-border border-width-2'
                                        placeholder={"Ingrese Observacion"}
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="observacion"
                                        onChange = {this.props.funcionHandleInputChanger}
                                        value = {this.props.data.crear_registro_cancelar['observacion'] ? this.props.data.crear_registro_peso['observacion'] : '' }
                                        />
                                </InputGroup>
                                    
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.props.funcioncancelarEntrega(null)} >
                            Close
                        </Button>
                        <a  onClick={()=>this.props.funcioncancelarEnvio(pedidos,'Cancelar')} className="btn  btn-primary">
                            Cancelar Pedido
                        </a>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    
   
}





export default Cancelar;