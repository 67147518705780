import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import imagen_vehiculo from '../../assets/images/vehiculo.png';



class PedidosEstado extends Component {

    render() {

        let data = this.props.data
        let pedidos = this.props.data.pedidos
         
        if (!pedidos) {
            return <></>
        }
        
        let color = "info"
        let tipo_pedido = ""

        switch (data.estado) {
            case 'Pedidos por Delivery':
                tipo_pedido = "Pedidos por Delivery"
                color = "warning"
                break;
            case 'Recojo en Tienda':
                tipo_pedido = "Recojo en Tienda"
                color = "info"
                
                break;
            case 'Entrega a tiempo':
                tipo_pedido = "Entrega a tiempo"
                color = "success"
                
                break;
            case 'Promedio De Entrega':
                tipo_pedido = "Pedidos no entregados"
                color = "danger"
                
                break;
        
            default:
                break;
        }

        let titulo = tipo_pedido;
        let mostrar_modal = data.modal_pedidos_estado.mostrar_modal

        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.props.funcionMostrarPedidos(null)}
                    backdrop="static"
                    keyboard={false}
                    size={'xl'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="card card-widget widget-user-2">
                            <div className={`widget-user-header bg-${color}`}>
                                <div className="widget-user-image">
                                    <img className="img-circle elevation-2" src={imagen_vehiculo} alt="User Avatar"></img>
                                </div>
                                <h3 className="widget-user-username"><b>{tipo_pedido}</b></h3> 
                            </div>
                            <div className="card-footer p-0">
                                <table className="table table-head-fixed text-nowrap table-sm">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>CLIENTE</th>
                                        <th>NÚM. PEDIDO</th>
                                        <th>FECHA</th>
                                        <th>ESTADO</th>
                                        <th>ALMACEN</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        pedidos.map((pedido,key) =>{
                                            return(
                                                <tr>
                                                    <td>{key}</td>
                                                    <td>{pedido.customer_code} {pedido.social_reason}</td>
                                                    <td>{pedido.document_order_number}</td>
                                                    <td>{pedido.date_of_work}</td>
                                                    <td>{pedido.description_web}</td>
                                                    <td>{pedido.warehouse_code}</td>
                                                </tr>
                                            )
                                        })
                                      }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.props.funcionMostrarPedidos(null)} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}




export default PedidosEstado;