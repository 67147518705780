import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faEye,faCheck,faCar,faEdit } from '@fortawesome/free-solid-svg-icons';

//importar funciones helpers
import {obtenerEstadoAccion,obtenerEstado } from '../../helpers'

class ListadoTable extends Component {

  //renderizar componente
  render() {
    return (
         <table className="table table-head-fixed text-nowrap table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Nombres</th>
                <th>Apellidos</th>
                <th>Documento</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                /* validar que existan registros */
                this.props.registros &&
                  /* iterar los registros para la tabla */
                  this.props.registros.ayudantes.map((ayudante, key) =>{

                    return(
                      <tr key={key}>
                        <td>{key+1}</td>
                        <td>{ayudante.names}</td>
                        <td>{ayudante.surnames}</td>
                        <td>{ayudante.document ? ayudante.document.abrv : ''} {ayudante.document_number}</td>
                        <td>{obtenerEstado(ayudante.status)}</td>
                        <td>
                          <div>
                            <button  title='Editar' className='btn btn-xs btn-info mr-2' onClick={()=>{this.props.funcionMostrarOcultarModalCrear(ayudante)}}><FontAwesomeIcon icon={faEdit} color="white" /></button>
                            <button  title='Eliminar' className={`btn btn-xs btn-${ayudante.status?'danger':'success'}`} onClick={()=>{this.props.funcionEliminarRegistro(ayudante.id)}}>
                              <FontAwesomeIcon icon={ayudante.status==1 ? faTrash : faCheck} color="white" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })
              }
               
            </tbody>
          </table>
 
    )

  }



}

export default ListadoTable;
