import React, { useState, useEffect } from "react";
import { PageContent } from "react-adminlte-3";
import { Form } from "react-bootstrap";
import EncabezadoModulo from "./EncabezadoModulo";
import axios from "axios";

//importar funciones helpers
import {
  mostrarMensajeExito,
  cerrarMenuLateral,
  mostrarMensajeError,
  mensajeDeConfirmacion,
  mostrarMensajeAlertas,
  verificarRolAyudante,
  obtenerAyudanteId,
  obtenerVehiculoId,
  mostrarMensajeAlerta,
  obtenerCodigoAs400
} from "../../helpers";
import PedidoEnRuta from "./PedidoEnRuta";
import DetallesEnvio from "./DetallesEnvio";
import EntregarPedido from "./EntregarPedido";
import CargarPeso from "./CargarPeso";
import CargarAgencia from "./CargarAgencia";
import Cancelar from "./Cancelar";
//importar configuracion axio
import { laravelAPI } from "../../config.js";
import ClipLoader from "react-spinners/ClimbingBoxLoader";
import { fechaActual,horaActual } from './../../helpers';
const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function Contenido() {
  //estatus
  const [state, setState] = useState({
    registros: null,
    titulo_modulo: "Entrega de Pedidos",
    formularios: { crear: false },
    modal_vehiculo: { mostrar_modal: false },
    modal_peso: { mostrar_modal: false },
    modal_agencia: { mostrar_modal: false },
    modal_cancelar: { mostrar_modal: false },
    crear_registro: {fecha:fechaActual, hora:horaActual},
    crear_registro_peso: {},
    crear_registro_agencia: {},
    crear_registro_cancelar: {},
    errores_validacion: {},
    refrescar: 0,
    pedidos_en_ruta: null,
    pedidos_en_destino: null,
    vehiculo_id: obtenerVehiculoId(),
    rol_ayudante: verificarRolAyudante(),
    ayudante_id: obtenerAyudanteId(),
    pedidos: null,
    progress_bar: {},
    activar_boton: false,
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  //variables y funcion para bloquear pantalla
  const [cargando, setCargando] = useState(false);
  const [imagenes_cargadas, setModificarImagenes] = useState([]);
  const gestionarCarga = (valor) => {
    setCargando(valor);
  };

  //funciones que se ejecutaran al iniciar el componente
  useEffect(async () => {
    //cerrar menu lateral
    cerrarMenuLateral();
    let datos_consultados = null;

    if (!state.rol_ayudante) {
      datos_consultados = await obtenerRegistros();
    } else {
      datos_consultados = await obtenerRegistrosAyudante();
    }

    //actualizar datos de registros
    actualizarDatosDeRegistros(datos_consultados);
  }, [state.refrescar]);

  if (cargando) {
    return (
      <div className="mx-auto" style={style}>
        <ClipLoader color="rgb(53, 213, 182)" loading={true} size={16} />
      </div>
    );
  } else {
    return (
      <div>
        <EncabezadoModulo
          titulo_modulo={state.titulo_modulo}
          registro={state.registros}
        />

        {!state.pedidos_en_ruta && !state.pedidos_en_destino && (
          <PedidoEnRuta
            data={state}
            funcionCrearRegistro={crearRegistro}
            funcioniniciarEntrega={iniciarEntrega}
            funcionMostrarOcultarModalPeso={mostrarOcultarModalPeso}
            funcionMostrarOcultarModalAgencia={mostrarOcultarModalAgencia}
            funcionCambiarAgencia={cambiarAgencia}
          />
        )}

        {state.pedidos_en_ruta && (
          <DetallesEnvio
            data={state}
            funcionCrearRegistro={crearRegistro}
            funcionHandleInputChanger={handleInputChange}
            funcionGuardarRegistros={guardarRegistro}
            funcioniniciarEntrega={iniciarEntrega}
            funcionCancelarEntrega={cancelarEntrega}
          />
        )}

        {state.pedidos_en_destino && (
          <EntregarPedido
            data={state}
            funcionCrearRegistro={crearRegistro}
            funcionHandleInputChanger={handleInputChange}
            funcionGuardarRegistros={guardarRegistro}
            funcioniniciarEntrega={iniciarEntrega}
            guardarEntrega={guardarEntrega}
            modificarImagen={modificarImagen}
            modificarTipoEntrega={modificarTipoEntrega}
            modificarContadorImagenes={modificarContadorImagenes}
            funcionCancelarEntrega={cancelarEntrega}
            imagenes_cargadas={imagenes_cargadas}
            funcionCambiarAgencia={cambiarAgencia}
            funcionMostrarOcultarModalAgencia={mostrarOcultarModalAgencia}


          />
        )}
        <PageContent></PageContent>

        <CargarPeso
          data={state}
          funcioniniciarEntrega={iniciarEntrega}
          funcionMostrarOcultarModalPeso={mostrarOcultarModalPeso}
          funcionHandleInputChanger={handleInputChangePeso}
          funcionHandleInputChangePesoCambio={handleInputChangePesoCambio}
          funcionRegistrarPeso={registrarPeso}
        />

        <CargarAgencia
          data={state}
          funcioniniciarEntrega={iniciarEntrega}
          funcionMostrarOcultarModalAgencia={mostrarOcultarModalAgencia}
          funcionHandleInputChangerAgencia={handleInputChangeAgencia}
          funcionCambiarAgencia={cambiarAgencia}
        />
        <Cancelar
          data={state}
          funcionHandleInputChanger={handleInputChangeObservacion}
          funcioncancelarEnvio={cancelarEnvio}
          funcioncancelarEntrega={cancelarEntrega}
          funcioniniciarEntrega={iniciarEntrega}
        />
      </div>
    );
  }

  function obtenerRegistros() {
    return new Promise((resolve, reject) => {
      //bloquear pantalla
      gestionarCarga(true);
      laravelAPI
        .get(`despachos/obtenerDespachosPorConductor/${state.vehiculo_id}`)
        .then(function (response) {
          //desbloquear pantalla
          gestionarCarga(false);
          resolve(response.data);
        })
        .catch(function (error) {
          //desbloquear pantalla
          gestionarCarga(false);
          reject(null);
        });
    });
  }

  function obtenerRegistrosAyudante() {
    return new Promise((resolve, reject) => {
      //bloquear pantalla
      gestionarCarga(true);
      laravelAPI
        .get(`despachos/obtenerDespachosPorAyudante/${state.ayudante_id}`)
        .then(function (response) {
          //desbloquear pantalla
          gestionarCarga(false);
          resolve(response.data);
        })
        .catch(function (error) {
          //desbloquear pantalla
          gestionarCarga(false);
          reject(null);
        });
    });
  }

  function actualizarDatosDeRegistros(data) {
    setState((previousState) => {
      return { ...previousState, registros: data };
    });

    let filtrado = null;
    let filtrado2 = null;

    //validar si existe algun pedido en ruta
    Object.values(data.despachos).map((cliente, key) => {
      if (!filtrado || filtrado.length == 0) {
        filtrado = cliente.filter((client) => client.status_order == "En Ruta");
      }

      if (!filtrado2 || filtrado2.length == 0) {
        filtrado2 = cliente.filter(
          (client) => client.status_order == "Punto de Destino"
        );
      }
    });

    //si existe un cliente en ruta se le asignan sus pedidos
    if (filtrado && filtrado.length) {
      setState((previousState) => {
        return { ...previousState, pedidos_en_ruta: filtrado };
      });
    }

    //si existe un cliente en ruta se le asignan sus pedidos
    if (filtrado2 && filtrado2.length) {
      setState((previousState) => {
        return { ...previousState, pedidos_en_destino: filtrado2 };
      });
    }
  }

  function guardarRegistro() {
    return new Promise((resolve, reject) => {
      //bloquear pantalla
      gestionarCarga(true);
      laravelAPI
        .get("despachos/registrarDespacho", { params: state.crear_registro })
        .then(function (response) {
          //desbloquear pantalla
          gestionarCarga(false);
          mostrarMensajeExito(response.data.mensaje);

          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status == 422) {
            //actualizar los errores
            setState((previousState) => {
              return {
                ...previousState,
                errores_validacion: error.response.data,
              };
            });
          }

          //desbloquear pantalla
          gestionarCarga(false);

          mostrarMensajeError(error.response);

          reject(null);
        });
    });
  }

  function handleInputChange(event) {
    setState((previousState) => {
      return {
        ...previousState,
        crear_registro: {
          ...state.crear_registro,
          [event.target.name]: event.target.value,
        },
      };
    });
  }

  function modificarContadorImagenes(id, sumar) {
    if (sumar) {
      let arreglo = imagenes_cargadas;
      //validar si ya existe el id
      if (!arreglo.includes(id)) {
        arreglo.push(id);

        setModificarImagenes(arreglo);
      }
    }
  }

  function handleInputChangePeso(event) {
    setState((previousState) => {
      return {
        ...previousState,
        crear_registro_peso: {
          ...state.crear_registro_peso,
          [event.target.name]: event.target.value,
        },
      };
    });
  }

  function handleInputChangePesoCambio(nombre, valor) {
    setState((previousState) => {
      return {
        ...previousState,
        crear_registro_peso: { ...state.crear_registro_peso, [nombre]: valor },
      };
    });
  }

  function handleInputChangeAgencia(selectedOption) {
    setState((previousState) => {
      return {
        ...previousState,
        crear_registro_agencia: {
          ...state.crear_registro_agencia,
          agencia: selectedOption,
        },
      };
    });
  }

  function handleInputChangeObservacion(event) {
    setState((previousState) => {
      return {
        ...previousState,
        crear_registro_cancelar: {
          ...state.crear_registro_cancelar,
          [event.target.name]: event.target.value,
        },
      };
    });
  }

  //craer funcion para que se ejecute al
  // mostrar o ocultar modal de vehiculo
  function crearRegistro(vehiculo = null) {
    let valor = state.modal_vehiculo.mostrar_modal ? false : true;

    setState((previousState) => {
      return {
        ...previousState,
        formulario: { crear: valor },
        vehiculo: vehiculo,
        crear_registro: {
          ...state.crear_registro,
          vehiculo_id: vehiculo ? vehiculo.id : null,
        },
      };
    });
  }

  async function cambiarAgencia(pedidos) {
    //bloquear pantalla
    gestionarCarga(true);

    let parametros = {
      pedidos: pedidos.map((pedido) => {
        return pedido.dispatch;
      }),
      agencia: state.crear_registro_agencia.agencia.value,
    };

    if (!state.crear_registro_agencia.agencia.value) {
      mostrarMensajeAlertas("Seleccionar Agencia");
      //desbloquear pantalla
      gestionarCarga(false);
      return;
    }

    let esConfirmar = await mensajeDeConfirmacion(
      "Seguro de realizar la acción?"
    );

    if (!esConfirmar) {
      //desbloquear pantalla
      gestionarCarga(false);
      return;
    }

    return new Promise((resolve, reject) => {
      laravelAPI
        .get(`despachos/cambiar-agencia`, { params: parametros })
        .then(function (response) {
          mostrarMensajeExito(response.data.mensaje);

          setState((previousState) => {
            return {
              ...previousState,
              refrescar: state.refrescar + 1,
              modal_agencia: { mostrar_modal: false },
              pedidos_en_ruta: null,
              pedidos_en_destino: null,
            };
          });

          //desbloquear pantalla
          gestionarCarga(false);
          resolve(response);
        })
        .catch(function (error) {
          //desbloquear pantalla
          gestionarCarga(false);
          reject(null);
        });
    });
  }

  /**
   * metodo encargado de registrar el peso y los bultos
   * @param {*} pedidos
   * @returns
   */

  async function registrarPeso(pedidos) {
    //bloquear pantalla
    gestionarCarga(true);
    console.log(pedidos);
    let existenErrores = false;

    let parametros = {
      order_delivery_id: pedidos[0].order_delivery_id,
      placa: pedidos[0].placa,
      customer_id: pedidos[0].customer_id,
      pedidos: pedidos.map((pedido) => {
        return pedido.dispatch;
      }),
      clientes: pedidos.map((pedido) => {
        return { cliente: pedido.customer_code, pedido: pedido.dispatch };
      }),
    };

    pedidos.forEach((pedido) => {
      if (pedido.peso && pedido.bultos) {
        return;
      }

      let variable  = "peso-pedido-" + pedido.customer_code;
      let variable2 = "bulto-pedido-" + pedido.customer_code;
      let dispatch  = "dispatch-pedido-" + pedido.customer_code;
      console.log('crear_registro_peso');
      console.log(state.crear_registro_peso);
      if (pedido.peso > 0) {
        if (!state.crear_registro_peso[variable]) {
          console.log("peso > 0 -> if");
          // state.crear_registro_peso.push({variable: pedido.peso});
          state.crear_registro_peso[variable]=pedido.peso;
          console.log(state.crear_registro_peso);
        }else{
          console.log("peso > 0 -> else");
          state.crear_registro_peso[variable]=pedido.peso;
          console.log(state.crear_registro_peso);
        }
      }else{
        if (!state.crear_registro_peso[variable]) {
          console.log("peso < 0 -> if");
          state.crear_registro_peso[variable]=1;
          console.log(state.crear_registro_peso);
        }else{
          console.log("peso < 0 -> else");
          state.crear_registro_peso[variable]=1;
          console.log(state.crear_registro_peso);
        }
      }
      //validar si esta el peso para el pedido
      // if (!state.crear_registro_peso[variable]) {
      //   mostrarMensajeAlertas(
      //     "Agregar Peso del pedido del cliente" + pedido.customer_code
      //   );
      //   existenErrores = true;
      // }

      parametros[variable] = state.crear_registro_peso[variable];
      parametros[variable2] = state.crear_registro_peso[variable2];
    });

    if (existenErrores) {
      gestionarCarga(false);
      return;
    }

    let esConfirmar = await mensajeDeConfirmacion(
      "Seguro de realizar la acción?"
    );
    if (!esConfirmar) {
      //desbloquear pantalla
      gestionarCarga(false);
      return;
    }
    
    return new Promise((resolve, reject) => {
      laravelAPI
        .get(`despachos/registrar-peso`, { params: parametros })
        .then(function (response) {
          mostrarMensajeExito(response.data.mensaje);

          setState((previousState) => {
            return {
              ...previousState,
              refrescar: state.refrescar + 1,
              modal_peso: { mostrar_modal: false },
              pedidos_en_ruta: null,
              pedidos_en_destino: null,
            };
          });
          //desbloquear pantalla
          gestionarCarga(false);
          resolve(response);
        })
        .catch(function (error) {
          //desbloquear pantalla
          gestionarCarga(false);
          reject(null);
        });
    });
  }

  async function iniciarEntrega(pedidos, estatus) {
    //bloquear pantalla
    gestionarCarga(true);

    let existenErrores = false;

    let parametros = {
      order_delivery_id: pedidos[0].order_delivery_id,
      estatus: estatus,
      customer_id: pedidos[0].customer_id,
      pedidos: pedidos.map((pedido) => {
        return pedido.dispatch;
      }),
    };

    /*    
            pedidos.forEach(pedido => {
            let variable = 'peso-pedido-'+pedido.dispatch
            let variable2 = 'bulto-pedido-'+pedido.dispatch

            //validar si esta el peso para el pedido
            if (!state.crear_registro_peso[variable]) {
                mostrarMensajeAlertas("Agregar Peso del pedido"+pedido.document_order_number)
                existenErrores = true
            }

            parametros[variable] = state.crear_registro_peso[variable];
            parametros[variable2] = state.crear_registro_peso[variable2];

            
        }); */

    if (estatus == "Cancelar") {
      if (!state.crear_registro_cancelar.observacion) {
        mostrarMensajeAlertas("Agregar Observacion");

        return;
      }
    }

    let esConfirmar = await mensajeDeConfirmacion(
      "Seguro de realizar la acción?"
    );

    if (!esConfirmar) {
      //desbloquear pantalla
      gestionarCarga(false);
      return;
    }

    return new Promise((resolve, reject) => {
      laravelAPI
        .get(`despachos/cambiar-estado-envio`, { params: parametros })
        .then(function (response) {
          mostrarMensajeExito(response.data.mensaje);
          enviarMensajeWhatsapp(response.data.mensajews,response.data.numerows,response.data.flagws);
          setState((previousState) => {
            return {
              ...previousState,
              refrescar: state.refrescar + 1,
              modal_peso: { mostrar_modal: false },
              pedidos_en_ruta: null,
              pedidos_en_destino: null,
            };
          });
          //desbloquear pantalla
          gestionarCarga(false);
          resolve(response);
        })
        .catch(function (error) {
          //desbloquear pantalla
          gestionarCarga(false);
          reject(null);
        });
    });
  }

  async function cancelarEnvio(pedidos, estatus) {
    //bloquear pantalla
    gestionarCarga(true);

    let parametros = {
      order_delivery_id: pedidos[0].order_delivery_id,
      estatus: estatus,
      customer_id: pedidos[0].customer_id,
      pedidos: pedidos.map((pedido) => {
        return pedido.dispatch;
      }),
      observacion: state.crear_registro_cancelar.observacion,
    };

    if (estatus == "Cancelar") {
      console.log(state.crear_registro_cancelar.observacion);
      if (!state.crear_registro_cancelar.observacion) {
        mostrarMensajeAlertas("Agregar Observacion");
        //desbloquear pantalla
        gestionarCarga(false);
        return;
      }
    }

    let esConfirmar = await mensajeDeConfirmacion(
      "Seguro de realizar la acción?"
    );

    if (!esConfirmar) {
      //desbloquear pantalla
      gestionarCarga(false);
      return;
    }

    return new Promise((resolve, reject) => {
      laravelAPI
        .get(`despachos/cancelar-envio`, { params: parametros })
        .then(function (response) {
          mostrarMensajeExito(response.data.mensaje);

          setState((previousState) => {
            return {
              ...previousState,
              refrescar: state.refrescar + 1,
              modal_cancelar: { mostrar_modal: false },
              pedidos_en_ruta: null,
              pedidos_en_destino: null,
            };
          });

          //desbloquear pantalla
          gestionarCarga(false);
          resolve(response);
        })
        .catch(function (error) {
          //desbloquear pantalla
          gestionarCarga(false);
          reject(null);
        });
    });
  }
  // image onchange hander
  async function modificarImagen(e, customer_id, order_id, tipo) {
    const data = new FormData();
    let file = e.target.files[0];
    let imagen_64 = file;
    let nombre_imagen =
      tipo == "peso" ? `imagen-${customer_id}` : `imagen-bulto-${customer_id}`;

    const progreso = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let precentage = Math.floor((loaded * 100) / total);

        if (precentage == 100) {
          modificarContadorImagenes(nombre_imagen, true);
        }
        setState((previousState) => {
          return {
            ...previousState,
            progress_bar: {
              ...state.progress_bar,
              [nombre_imagen]: precentage,
            },
          };
        });
      },
    };

    data.append("order_id", order_id);
    data.append("customer_id", customer_id);
    data.append("imagen", imagen_64);
    data.append("tipo", tipo);

    laravelAPI
      .post(`despachos/guardar-imagen`, data, progreso)
      .then((res) => {
        console.log("imagen guardada");
      })
      .catch(function (error) {
        //desbloquear pantalla
        console.log(error);
      });
  }

  // image onchange hander
  function modificarTipoEntrega(e) {
    setState((previousState) => {
      return {
        ...previousState,
        crear_registro: {
          ...state.crear_registro,
          [e.target.name]: e.target.value,
        },
      };
    });
  }

  function guardarEntrega(e) {
    e.preventDefault();

    let cantidad_requrida_ids = [];
    let todos_pedidos = []

    state.pedidos_en_destino.map(ped =>{
      todos_pedidos.push(ped.dispatch);
      if (!cantidad_requrida_ids.includes(ped.customer_id)){
        cantidad_requrida_ids.push(ped.customer_id);
      }
    });


    let cantidad_requrida = cantidad_requrida_ids.length;
    let cantidad_guardada = imagenes_cargadas.length;

    if (cantidad_requrida > cantidad_guardada) {
      mostrarMensajeAlertas("Debe guardar todas las imagenes requeridas");

      return;
    }

    gestionarCarga(true);
    const data = new FormData();

    if (!state.crear_registro.tipo_entrega) {
      mostrarMensajeAlertas("Seleccionar Tipo de Entrega");
      gestionarCarga(false);
      return;
    }
    if (!state.crear_registro.fecha) {
      mostrarMensajeAlertas("Ingrese Fecha de Entrega");
      gestionarCarga(false);
      return;
    }
    if (!state.crear_registro.hora) {
      mostrarMensajeAlertas("Ingrese Hora de Entrega");
      gestionarCarga(false);
      return;
    }

    data.append( "order_delivery_id", state.pedidos_en_destino[0].order_delivery_id );
    data.append("customer_id", state.pedidos_en_destino[0].customer_id);
    data.append("tipo_entrega", state.crear_registro.tipo_entrega);
    data.append("fecha", state.crear_registro.fecha);
    data.append("hora", state.crear_registro.hora);
    data.append('dispatch', todos_pedidos)

    //imagenes_cargadas

    laravelAPI
      .post("despachos/cerrar-envio", data)
      .then((response) => {
        mostrarMensajeExito(response.data.mensaje);
        enviarMensajeWhatsapp(response.data.mensajews,response.data.numerows,response.data.flagws);
        gestionarCarga(false);

        setState((previousState) => {
          return {
            ...previousState,
            refrescar: state.refrescar + 1,
            pedidos_en_destino: null,
          };
        });
      })
      .catch((error) => {
        console.log(error);
        gestionarCarga(false);

        if (error.response.status == 422) {
          gestionarCarga(false);

          //actualizar los errores
          setState((previousState) => {
            return {
              ...previousState,
              errores_validacion: error.response.data,
            };
          });
        }

        mostrarMensajeError(error.response);
        gestionarCarga(false);
      });
  }

  //craer funcion para que se ejecute al mostrar o ocultar modal
  function mostrarOcultarModalPeso(pedidos = null, tipo = "") {
    let valor = state.modal_peso.mostrar_modal ? false : true;

    setState((previousState) => {
      return {
        ...previousState,
        modal_peso: { mostrar_modal: valor },
        pedidos: pedidos,
      };
    });
  }

  //craer funcion para que se ejecute al mostrar o ocultar modal
  function mostrarOcultarModalAgencia(pedidos = null) {
    let valor = state.modal_agencia.mostrar_modal ? false : true;

    setState((previousState) => {
      return {
        ...previousState,
        modal_agencia: { mostrar_modal: valor },
        pedidos: pedidos,
      };
    });
  }

  async function enviarMensajeWhatsapp(mensaje, numero, flag_envio) {
    console.log('enviarMensajeWhatsapp');
    console.log(flag_envio);
    if (flag_envio) {
      console.log('enviando mensaje de whatsapp ...');
      const data = new FormData();
      data.append("numero", numero);
      data.append("mensaje", mensaje);
      console.log(data);
      laravelAPI
        .post(`despachos/enviarMensajeWSV2`, data)
        .then((res) => {
          console.log("mensaje de whatsapp enviado");
        })
        .catch(function (error) {
          //desbloquear pantalla
          console.log(error);
        });
    }
  }

  //craer funcion para que se ejecute al querer cancelar una entrega
  function cancelarEntrega(pedidos = null) {
    let valor = state.modal_cancelar.mostrar_modal ? false : true;

    setState((previousState) => {
      return {
        ...previousState,
        crear_registro_cancelar: {
          ...state.crear_registro_cancelar,
          observacion: null,
        },
        modal_cancelar: { mostrar_modal: valor },
        pedidos: pedidos,
      };
    });
  }
}

export default Contenido;
