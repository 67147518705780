import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPhone } from '@fortawesome/free-solid-svg-icons';
import imagen from '../../assets/images/cliente.jpg';


class ListadoTable extends Component {
  
  //renderizar componente
  render() {

    return (
      <div className="row">
        {
          /* validar que existan registros */
          this.props.registros &&
          /* iterar los registros para la tabla */
          Object.values(this.props.registros.despachos).map((cliente,key) => {
             return (
              <div key={key} className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                <div className="card bg-light d-flex flex-fill">
                  <div className="card-header text-muted border-bottom-0">
                    {cliente.length} pedidos 
                     
                  </div>
                  <div className="card-body pt-0">
                    <div className="row">
                      <div className="col-7">
                        <h2 className="lead"><b>{cliente[0].titulo}</b></h2>
                        {cliente[0].sucursal &&
                          <h2 className="lead text-info"><b>{cliente[0].sucursal}</b></h2>
                        }
                        <p className="text-muted text-sm"><b>Comentario: </b> {cliente[0].comentario} </p>
                        <ul className="ml-4 mb-0 fa-ul text-muted">
                          <li className="small"><span className="fa-li"><FontAwesomeIcon icon={faBuilding}/></span> Tipo de Envio: {cliente[0].shipping_type}</li>
                          <li className="small"><span className="fa-li"><FontAwesomeIcon icon={faBuilding}/></span> Direccion: {cliente[0].direccion_envio}</li>
                          <li className="small"><span className="fa-li"><FontAwesomeIcon icon={faBuilding}/></span> Transportista: {cliente[0].agencia_envio}</li>
                          <li className="small"><span className="fa-li"><FontAwesomeIcon icon={faBuilding}/></span> Transportista: {cliente[0].agencia_envio_2}</li>
                          <li className="small"><span className="fa-li"><FontAwesomeIcon icon={faPhone}/></span> Telefono #: {cliente[0].telefono}</li>
                        </ul>
                      </div>
                      <div className="col-5 text-center">
                        <img src={imagen} alt="user-avatar" className="img-circle img-fluid"></img>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="text-right">

                      {/* validar si ya fue llenado el peso y los bultos */}
                      {
                        
                        /*!cliente[cliente.length-1].peso &&*/
                        !cliente[cliente.length-1].bultos &&
                        
                        <a  onClick={()=>this.props.funcionMostrarOcultarModalPeso(cliente,'En Ruta')} className="btn btn-sm btn-primary">
                          Cargar Datos
                        </a> 
                      }
                      {
                        cliente[0].bultos &&
                        cliente[0].type == "Inmediato" &&
                        <a  onClick={()=>this.props.funcioniniciarEntrega(cliente,'En Ruta')} className="btn btn-sm btn-primary">
                          Iniciar Ruta
                        </a> 
                      }
                      
                     
                      
                    </div>
                  </div>
                </div>
              </div>
            )
          })
 
        }



      </div>


    )

  }



}

export default ListadoTable;
