import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import imagen_vehiculo from '../../assets/images/vehiculo.png';



class PedidosFiltrados extends Component {

    render() {

        let data = this.props.data
        let pedidos = this.props.data.pedidos_filtrados
         
        if (!pedidos) {
            return <></>
        }
        
        let color = "info"
        let tipo_pedido = ""
 
        switch (data.tipo) {
            case 0:
                tipo_pedido = "Todos"
                color = "info"
                break;
            case 1:
                tipo_pedido = "Recibidos"
                color = "warning"
                
                break;
            case 2:
                tipo_pedido = "En Preparacion"
                color = "danger"
                
                break;
            case 3:
                tipo_pedido = "Entregados"
                color = "success"
                
                break;
        
            default:
                break;
        }
 

        let titulo = `Listado de Pedidos`
        let mostrar_modal = data.modal_pedidos_filtrados.mostrar_modal

        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.props.funcionMostrarVehiculo(null,null)}
                    backdrop="static"
                    keyboard={false}
                    size={'xl'}
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="card card-widget widget-user-2">
                            <div className={`widget-user-header bg-${color}`}>
                                <div className="widget-user-image">
                                    <img className="img-circle elevation-2" src={imagen_vehiculo} alt="User Avatar"></img>
                                </div>
                                <h3 className="widget-user-username"><b>{tipo_pedido}</b></h3> 
                            </div>
                            <div className="card-footer p-0">
                                {
                                    pedidos.map((pedido,key) =>{
                                        return(
                                            <ul className="nav flex-column" key={'vehiculo-'+key}>
                                                <li className="nav-item">
                                                    <a  className="nav-link">
                                                        {pedido.social_reason} {pedido.document_order_number} <span className="float-right text-dark">Almacen({pedido.warehouse_code})</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        )
                                    })
                                }
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.props.funcionMostrarVehiculo(null,null)} >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}




export default PedidosFiltrados;