import React, { useState,useEffect  } from 'react';
import ListadoTable from './ListadoTable';
import {PageContent,} from 'react-adminlte-3'
import {Form,Check} from 'react-bootstrap';
import EncabezadoModulo from './EncabezadoModulo';
import Crear from './Crear';
import Mostrar from './Mostrar';
import { useParams,Navigate  } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck} from '@fortawesome/free-solid-svg-icons';
//importar configuracion axio
import { laravelAPI } from '../../config.js'

//importar funciones helpers
import { mostrarMensajeExito,cerrarMenuLateral,mostrarMensajeError,mensajeDeConfirmacion } from '../../helpers'


function Contenido() {
    //estatus
    const [state, setState] = useState({
        registros : null,
        titulo_modulo: 'Ayudantes',
        modal_crear : { mostrar_modal: false},
        modal_ayudante:{ mostrar_modal: false},
        crear_registro:{    id: null,
                            nombres:null,
                            apellidos:null,
                            tipo:null,
                            numero_documento:null,
                        },
        errores_validacion : { nombres:null,
                                apellidos:null,
                                tipo:null,
                                numero_documento:null,
                                },
        mostrar_inactivos : 0,
        refrescar : 0,
        ayudante: null
    });

    //funciones que se ejecutaran al iniciar el componente
    useEffect( async() => {
        //cerrar menu lateral
        cerrarMenuLateral()
        
        let datos_consultados = await obtenerRegistros();

        //validar que exista datos de cliente
        if (datos_consultados.data) {
            //actualizar datos de registros
            actualizarDatosDeRegistros(datos_consultados.data);
        } 
        
        
    }, [state.mostrar_inactivos,state.refrescar]);


    
 
    return (
        <div>
        <EncabezadoModulo 
            titulo_modulo={state.titulo_modulo}
            funcionMostrarOcultarModalCrear = {()=>mostrarOcultarModalCrear(null)}
        />
        
        <PageContent>
            <section className="content">
            
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                    <div className='col-9'>

                                        <h3 className="card-title">Listado Registros</h3>
                                    </div>
                                    <div className='col-3'>
                                        <Form>
                                            <Form.Check 
                                                type="switch"
                                                id="mostrar-inactivos"
                                                label="Mostrar Inactivos"
                                                onClick= {ActivosInactivos}
                                            />
                                            
                                        </Form>
                                    </div>
                                    </div>

                                </div>
                                
                                <div className="card-body">
                                    <div className='table-responsive p-0 w-100'>
                                        <ListadoTable 
                                            registros={state.registros}
                                            funcionEliminarRegistro = {eliminarRegistro}
                                            funcionMostrarAyudante = {mostrarAyudante}
                                            funcionMostrarOcultarModalCrear = {mostrarOcultarModalCrear}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Crear 
                    data={state}
                    funcionMostrarOcultarModalCrear = {mostrarOcultarModalCrear}
                    funcionGuardarRegistrosUsiario  = {guardarRegistrosUsiario}
                    funcionHandleInputChanger = {handleInputChange}
                />

                <Mostrar 
                    data={state}
                    funcionMostrarAyudante = {mostrarAyudante}
                  
                />
               
                  
            </section>
        </PageContent>
        </div>
    )

    
    


    function obtenerRegistros() {
        
        let parametros = {
            mostrar_inactivos : state.mostrar_inactivos
        };
        return new Promise((resolve, reject)=>{

            laravelAPI.get('ayudantes/obtener-ayudantes', { params : parametros })
            .then(function (response) {
                resolve(response)
                
            })
            .catch(function (error) {
                reject(null)
            })
        })

    }

 

    function actualizarDatosDeRegistros(data) {
         
        
        setState(previousState => {
            return { ...previousState,  registros:data}
        });
    }

 

    //craer funcion para que se ejecute al mostrar o ocultar modal 
    function mostrarOcultarModalCrear(ayudante=null){
    
        let valor = state.modal_crear.mostrar_modal ? false : true
        let formulario = {  id: null,
                            nombre:null,
                            apellido:null,
                            tipo_documento:null,
                            numero_documento:null,
                            correo: null,

                        }

        /* VALIDAR SI ES EL FORMULARIO DE EDICION */
        if (ayudante) {
            formulario = {  id: ayudante.id,
                            nombre: ayudante.names,
                            apellido: ayudante.surnames,
                            tipo_documento: ayudante.document_type_id,
                            numero_documento: ayudante.document_number,
                            correo: ayudante.email,
                        }
            }
        

                
        setState(previousState => {
            return { ...previousState,  modal_crear : { mostrar_modal: valor}, ayudante: ayudante, crear_registro : formulario}
        });
      
    }


    
    function guardarRegistrosUsiario(e){
        
        e.preventDefault()
        
        return new Promise((resolve, reject)=>{
            
            laravelAPI.get('ayudantes/registrar-ayudante', { params : state.crear_registro })
            .then(function (response) {
                
                mostrarMensajeExito(response.data.mensaje)
                //cerrar modal 
                mostrarOcultarModalCrear(null)
                
                setState(previousState => {
                    return{ ...previousState,  refrescar : state.refrescar+1 }
                });

                resolve(response)
                
            })
            .catch(function (error) {
                 
                console.log(error);
                if (error.response.status==422) {
                    //actualizar los errores
                    setState(previousState => {
                        return { ...previousState,  errores_validacion:error.response.data}
                    });
                }

                mostrarMensajeError(error.response)

                reject(null)
            })
        })
         
        
        
    }

    function handleInputChange(event){

        setState(previousState => {
            return { ...previousState,  crear_registro : { ... state.crear_registro, [event.target.name] : event.target.value}}
        });

  
    }

    async function eliminarRegistro(id) {
        
        let esConfirmar = await mensajeDeConfirmacion('Seguro que Desea Eliminar el Registro')

        if(!esConfirmar) return

        return new Promise((resolve, reject)=>{

            laravelAPI.get(`ayudantes/cambiar-estatus-ayudante/${id}`, { params :  {id} })
            .then(function (response) {
                mostrarMensajeExito(response.data.mensaje)
                
                setState(previousState => {
                    return{ ...previousState,  refrescar : state.refrescar+1 }
                });

                resolve(response)
                
            })
            .catch(function (error) {
                reject(null)
            })
        })

    }

    
    async function ActivosInactivos(event) {
        
        let valor = event.target.checked ? 1 : 0;

        setState(previousState => {
            return{ ...previousState,  mostrar_inactivos : valor }
        }); 

    }

    
    //craer funcion para que se ejecute al
    // mostrar o ocultar modal de ayudante
    function mostrarAyudante(ayudante = null){
    
        let valor = state.modal_ayudante.mostrar_modal ? false : true
        
        setState(previousState => {
            return { ...previousState,  modal_ayudante : { mostrar_modal: valor}, ayudante:ayudante}
        });
      
    }

     
 


}
 
 

export default Contenido;