import React, { Component } from 'react';

//importar iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';


class EncabezadoModulo extends Component {

  //renderizar componente
  render() {
 
    let titulo = this.props.titulo_modulo
    return (
      <div className='content-header'>
        <div className='row mb-2'>
          <div className='col-sm-10'>
            <h1 className='title'>{titulo}</h1>
          </div>
          <div className='col-sm-2'>
            
            
          </div>
        </div>
      </div>
    )
  }



}

export default EncabezadoModulo;
