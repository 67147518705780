import React, { useState, useEffect } from 'react';
import ListadoTable from './ListadoTable';
import { PageContent } from 'react-adminlte-3'
import EncabezadoModulo from './EncabezadoModulo';
import GraficaPedidos from './GraficaPedidos';
import GraficaPedidosPorGrupo from './GraficaPedidosPorGrupo';
import Card from './Card';
import GraficaAlmacenes from './GraficaAlmacenes';
import CardVehiculo from './CardVehiculo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck,faSearch } from '@fortawesome/free-solid-svg-icons';
//importar configuracion axio
import { laravelAPI } from '../../config.js'
//importar funciones helpers
import { cerrarMenuLateral,fechaActual,fomatearFecha } from '../../helpers'
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClimbingBoxLoader";
import Vehiculos from './Vehiculos';
import PedidosFiltrados from './PedidosFiltrados';

const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

function Contenido() {

    //estatus
    const [state, setState] = useState({
        titulo_modulo: `Tracking del`,
        kpis: null,
        conteo_vehiculos: null,
        clientes_pedidos: null,
        modal_vehiculos: { mostrar_modal: false },
        modal_pedidos_filtrados: { mostrar_modal: false },
        tipo: null,
        datos_pedidos: { labels: [], recibidos: [], entregados: [], en_preparacion: [], totales: [] },
        datos_pedidos_por_grupo: { labels: [], cliente: [], lima: [], provincia: [] },
        datos_pedidos_por_almacen: { labels: [], total: [] },
        otros_filtros: {
            fecha_actual: fechaActual,
            almacen: 'Todos'
        },
        refrescar: 0,
        sedes:null,
        pedidos_filtrados:[]

    })

    //variables y funcion para bloquear pantalla
    const [cargando, setCargando] = useState(false)
    const gestionarCarga = (valor) => { setCargando(valor); }

    //funciones que se ejecutaran al iniciar el componente
    useEffect(async () => {
        //cerrar menu lateral
        cerrarMenuLateral()
        //bloquear pantalla
        gestionarCarga(true)
        
        await obtenerRegistros();

        //desbloquear pantalla
        gestionarCarga(false)

        await obtenerPedidosChart();

        await obtenerPedidosPorAlmacen();
    }, [state.refrescar]);


    if (cargando) {
        return (
            <div className='mx-auto' style={style}>
                <ClipLoader color='rgb(53, 213, 182)' loading={true} size={16} />
            </div>
        )
    } else {
        //renderizar componente
        return (
            <>
                <EncabezadoModulo titulo_modulo={`${state.titulo_modulo} ${fomatearFecha(state.otros_filtros.fecha_actual, true)}`} />
                <PageContent>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className='col-sm-4 col-12'>
                                    <div className="card">
                                        <div className="card-header border-0">
                                            <div className="d-flex justify-content-between">
                                                <h3 className="card-title">Kpis</h3>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <div className='row'>
                                                        {
                                                            state.kpis &&
                                                            state.kpis.map((kpi, key) => {
                                                                return (
                                                                    <Card key={'kpi' + key}
                                                                        titulo={kpi.titulo}
                                                                        icono={<FontAwesomeIcon icon={faTruck} />}
                                                                        color={kpi.color}
                                                                        total={kpi.total}
                                                                        porcentaje={kpi.porcentaje}
                                                                    />
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                                <div className='col-sm-12'>
                                                    <div className='row'>
                                                        <div className='col-sm-12'>
                                                            {state.conteo_vehiculos &&
                                                                state.conteo_vehiculos.map((vehiculo, key) => {
                                                                    return (
                                                                        <CardVehiculo key={key}
                                                                            titulo={vehiculo.titulo}
                                                                            icono={<FontAwesomeIcon icon={faTruck} />}
                                                                            color={vehiculo.color}
                                                                            total={vehiculo.total}
                                                                            filtro={vehiculo.filtro}
                                                                            mostrarVehiculos={mostrarVehiculos}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-sm-4 col-12'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className="card">
                                                <div className="card-header border-0">
                                                    <div className="d-flex justify-content-between">
                                                        <h3 className="card-title">Pedidos por Delivery</h3>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <GraficaPedidos datos={state.datos_pedidos} mostrarPedidosDeGrafica={mostrarPedidosDeGrafica} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-12'>
                                            <div className="card">
                                                <div className="card-header border-0">
                                                    <div className="d-flex justify-content-between">
                                                        <h3 className="card-title">Pedidos Por Grupo</h3>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <GraficaPedidosPorGrupo datos={state.datos_pedidos_por_grupo} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-4 col-12'>
                                    <div className='row'>
                                        <div  className='col-sm-12'>
                                            <div className="form-group">
                                                <label>Fecha</label>
                                                <input type='date' className='form-control' name='fecha_actual'value={state.otros_filtros.fecha_actual} onChange={modificarInputChange}></input>
                                            </div>
                                        </div>
                                        
                                        <div  className='col-sm-8'>
                                            <div className="form-group">
                                                <label>Almacen:</label>
                                                <select className='form-control' name='almacen' value={state.otros_filtros.almacen} onChange={modificarInputChange}>
                                                <option  key={'100'} value='Todos'>Todos</option>
                                                
                                                {   state.sedes &&
                                                    state.sedes.map((sede)=>{
                                                        return (
                                                            <option  key={sede.id} value={sede.warehouse_code}> {sede.warehouse_name}</option>
                                                        )                                          
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </div>
                                       
                                        <div  className='col-sm-4 text-center m-auto'>
                                            <button type="button"  onClick={actualizarPagina} className="btn btn-primary ">
                                                <FontAwesomeIcon icon={faSearch}  /> Buscar
                                            </button>
                                        </div>
                                         
                                        <div className='col-sm-12'>
                                            <div className="card">
                                                <div className="card-header border-0">
                                                    <div className="d-flex justify-content-between">
                                                        <h3 className="card-title">Pedidos Por Almacen</h3>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <GraficaAlmacenes datos={state.datos_pedidos_por_almacen} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Vehiculos
                        data={state}
                        funcionMostrarVehiculo={mostrarVehiculos}
                    />
                    <PedidosFiltrados
                        data={state}
                        funcionMostrarVehiculo={mostrarPedidosDeGrafica}
                    />
                </PageContent>
            </>
        )
    }


    function obtenerRegistros() {
        return new Promise((resolve, reject) => {
            laravelAPI.get(`inicio/pedidos/dashboard`, { params: { fecha: state.otros_filtros.fecha_actual,sede:state.otros_filtros.almacen } })
                .then(function (response) {
                    //actualizar datos de registros
                    actualizarDatosDeRegistros(response.data);

                    resolve(response.data)
                })
                .catch(function (error) {
                    resolve(error)
                })
        })
    }


    function actualizarDatosDeRegistros(data) {

        setState(previousState => {
            return {
                ...previousState,
                kpis: data.kpis,
                conteo_vehiculos: data.conteo_vehiculos,
                clientes_pedidos: data.clientes_pedidos,
                sedes:data.sedes
            }
        });

    }


    function obtenerVehiculos(tipo) {
        return new Promise((resolve, reject) => {
            laravelAPI.get(`inicio/pedidos/dashboard/obtenerVehiculos/${tipo}`)
                .then(function (response) {
                    //retornar datos
                    resolve(response.data)
                })
                .catch(function (error) {
                    resolve(error)
                })
        })
    }

    //craer funcion para que se ejecute al
    // mostrar o ocultar modal de vehiculo
    async function mostrarVehiculos(tipo) {

        let valor = state.modal_vehiculos.mostrar_modal ? false : true

        let vehiculos = null;

        if (valor) {
            vehiculos = await obtenerVehiculos(tipo);
        }

        setState(previousState => {
            return { ...previousState, modal_vehiculos: { mostrar_modal: valor }, vehiculos: vehiculos, tipo }
        });

    }

    function obtenerPedidosChart() {
        return new Promise((resolve, reject) => {
            laravelAPI.get(`inicio/pedidos/dashboard/obtenerChartPedidos/${state.otros_filtros.fecha_actual}/${state.otros_filtros.almacen}`)
                .then(function (response) {
                    actualizarDatosDeRegistrosChars(response.data);

                    resolve(response.data)
                })
                .catch(function (error) {
                    resolve(error)
                })
        })
    }

    function actualizarDatosDeRegistrosChars(data) {

        let datos = {
            labels: data.pedidos.labels,
            recibidos: data.pedidos.recibidos,
            entregados: data.pedidos.entregados,
            en_preparacion: data.pedidos.en_preparacion,
            totales: data.pedidos.totales
        }

        let datos2 = {
            labels: data.prdidos_por_grupo.labels,
            lima: data.prdidos_por_grupo.lima,
            cliente: data.prdidos_por_grupo.cliente,
            provincia: data.prdidos_por_grupo.provincia
        }

        setState(previousState => {
            return {
                ...previousState,
                datos_pedidos: datos,
                datos_pedidos_por_grupo: datos2,
            }
        });

    }


    function obtenerPedidosPorAlmacen() {
        return new Promise((resolve, reject) => {
            laravelAPI.get(`inicio/pedidos/dashboard/obtenerChartPedidosPorAlmacen/${state.otros_filtros.fecha_actual}/${state.otros_filtros.almacen}`)
                .then(function (response) {
                    actualizarDatosDeRegistrosPorAlmacen(response.data);

                    resolve(response.data)
                })
                .catch(function (error) {
                    resolve(error)
                })
        })
    }

    function actualizarDatosDeRegistrosPorAlmacen(data) {

        let datos = {
            labels: data.labels,
            total: data.total,

        }

        setState(previousState => {
            return {
                ...previousState,
                datos_pedidos_por_almacen: datos
            }
        });

    }

    function modificarInputChange(event){

        console.log( event.target.value)
        setState(previousState => {
            return { ...previousState,  otros_filtros : { ... state.otros_filtros, [event.target.name] : event.target.value}}
        });

  
    }

    function actualizarPagina() {
        setState(previousState => {
            return { ...previousState, refrescar: state.refrescar + 1  }
        });
    }

    async function mostrarPedidosDeGrafica(tipo,fecha) {
        
        let valor = state.modal_pedidos_filtrados.mostrar_modal ? false : true

        let tipo_pedido = null

        switch (tipo) {
            case 0:
                tipo_pedido = "Todos"
                break;
            case 1:
                tipo_pedido = "Recibidos"
                
                break;
            case 2:
                tipo_pedido = "En Preparacion"
                
                break;
            case 3:
                tipo_pedido = "Entregados"
                
                break;
        
            default:
                break;
        }

        //fecha de busqueda
        let fecha_busqueda = state.otros_filtros.fecha_actual.substring(0,5)+state.datos_pedidos.labels[fecha]

        let obtener_pedidos = null;

        if (valor) {
            //obtener pedidos
            obtener_pedidos = await obtenerPedidosFiltrados(fecha_busqueda,tipo_pedido)
        }

        setState(previousState => {
            return { ...previousState, modal_pedidos_filtrados: { mostrar_modal: valor }, pedidos_filtrados: obtener_pedidos, tipo }
        });

    }

 

    
    function obtenerPedidosFiltrados(fecha, tipo) {
        return new Promise((resolve, reject) => {
            laravelAPI.get(`inicio/pedidos/dashboard/obtenerPedidosFiltrados/${fecha}/${tipo}/${state.otros_filtros.almacen}`)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    resolve(error)
                })
        })
    }

}

export default Contenido;