import React, { useState, useEffect } from 'react';
import ListadoTable from './ListadoTable';
import { PageContent, } from 'react-adminlte-3'
import { Form, Check } from 'react-bootstrap';

import EncabezadoModulo from './EncabezadoModulo';
import { useParams, Navigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch,faFile } from '@fortawesome/free-solid-svg-icons';
//importar configuracion axio
import { laravelAPI,laravelReport } from '../../../config'
//importar funciones helpers
import { mostrarMensajeExito,
     EsAdministrador,
     obtenerCodigoAs400,
     cerrarMenuLateral,
     mostrarMensajeError,
     mensajeDeConfirmacion,
     fechaActual} from  '../../../helpers'
     
import ClipLoader from "react-spinners/ClimbingBoxLoader";
const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

function Contenido() {
    //estatus
    const [state, setState] = useState({
        registros: null,
        titulo_modulo: 'Reporte de Pedidos',
        refrescar: 0,
        pedido: null,
        filtros: 'todos',
        modal_pedido_detalles:{ mostrar_modal: false},
        modal_pedido:{ mostrar_modal: false},
        otros_filtros: {
                        numero_pedido: '',
                        codigo_cliente: '',
                        fecha_desde: fechaActual,
                        fecha_hasta: fechaActual
                    },
        mostrar_foto : null
        

    });

    //variables y funcion para bloquear pantalla
    const [cargando, setCargando] = useState(false)
    const gestionarCarga = (valor) =>{  setCargando(valor); }


    //funciones que se ejecutaran al iniciar el componente
    useEffect(async () => {
        //cerrar menu lateral
        cerrarMenuLateral()
        
        await obtenerRegistros();


    }, [state.refrescar]);


    if (cargando) {
        return (
          <div  className='mx-auto' style={style}>
            <ClipLoader color='rgb(53, 213, 182)' loading={true}  size={16} />
          </div>
        )
    }else{

        return (
            <div>
                <EncabezadoModulo
                    titulo_modulo={state.titulo_modulo}
                />

                <PageContent>
                    <section className="content">

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='row'>
                                                 
                                                <div className='col-sm-4'>
                                                    <div className="form-group">
                                                        <label>Nº Pedido</label>
                                                        <input className='form-control' name='numero_pedido' onKeyPress={onKeyUp} value={state.otros_filtros.numero_pedido} onChange={modificarInputChange}></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Codigo Cliente</label>
                                                        <input className='form-control' name='codigo_cliente' onKeyPress={onKeyUp} value={state.otros_filtros.codigo_cliente} onChange={modificarInputChange}></input>
                                                    </div>
                                                </div>
                                                <div className='col-sm-4'>
                                                    
                                                    <div className="form-group">
                                                        <label>Desde</label>
                                                        <input type='date' className='form-control' name='fecha_desde' onKeyPress={onKeyUp} value={state.otros_filtros.fecha_desde} onChange={modificarInputChange}></input>
                                                    </div>
                                                </div>
                                                <div className='col-sm-4'>
                                                    
                                                    <div className="form-group">
                                                        <label>Hasta</label>
                                                        <input type='date' className='form-control' name='fecha_hasta' onKeyPress={onKeyUp} value={state.otros_filtros.fecha_hasta} onChange={modificarInputChange}></input>
                                                    </div>
                                                    <button type="button"  onClick={obtenerRegistros} className="btn btn-primary ">
                                                        <FontAwesomeIcon icon={faSearch}  /> Buscar
                                                    </button>
                                                    <button type="button"  onClick={descargarRegistros} className="btn btn-success ml-2">
                                                        <FontAwesomeIcon icon={faFile}  /> Descargar
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='table-responsive p-0'>
                                                <ListadoTable
                                                    registros={state.registros}
                                                    funcionObtenerPedidos={obtenerRegistros}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </PageContent>
            </div>
        )

    }



    function obtenerRegistros(pageNumber = 1) {

            let parametros = {
                numero_pedido: state.otros_filtros.numero_pedido,
                codigo_cliente: state.otros_filtros.codigo_cliente,
                fecha_desde: state.otros_filtros.fecha_desde,
                fecha_hasta: state.otros_filtros.fecha_hasta,
                //codigo_as400 : !EsAdministrador() ? obtenerCodigoAs400() : '',
        };

        return new Promise((resolve, reject) => {
            //bloquear pantalla
            gestionarCarga(true)

            laravelAPI.get(`reportes/pedidos?page=${pageNumber}`, { params: parametros })
                .then(function (response) {
                    
                    //actualizar datos de registros
                    actualizarDatosDeRegistros(response.data);
                    //desbloquear pantalla
                    gestionarCarga(false)
                    resolve(response)

                })
                .catch(function (error) {
                    //desbloquear pantalla
                    gestionarCarga(false)
                    reject(null)
                })
        })

    }

    function descargarRegistros() {
        let parametros = `?numero_pedido=${state.otros_filtros.numero_pedido}&codigo_cliente=${state.otros_filtros.codigo_cliente}&fecha_desde=${state.otros_filtros.fecha_desde}&fecha_hasta=${state.otros_filtros.fecha_hasta}`;
        window.open(`${laravelReport}reportes/pedidos-exportar/excel`+parametros, '_blank');
    }


    function actualizarDatosDeRegistros(data) {
        setState(previousState => {
            return { ...previousState, registros: data,titulo_modulo: `Reporte de Pedidos (Total Pedidos:${data.total})` }
        });
    }

    /**
     * metodo encargado de refrescar la vista
     *
     */
    function refrescarVista() {

        console.log('refrescarVista')
        setState(previousState => {
            return { ...previousState, refrescar: state.refrescar + 1  }
        });
    }

  
    function modificarInputChange(event){

        setState(previousState => {
            return { ...previousState,  otros_filtros : { ... state.otros_filtros, [event.target.name] : event.target.value}}
        });

    }
 
    async function onKeyUp(event) {
        if (event.charCode === 13) {
            //alert('hola'); 
        }
    }
    
}



export default Contenido;