import React, {Component} from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContenidoInicio from './components/inicio/Contenido';
import ContenidoUsuario from './components/usuario/Contenido';
import ContenidoAgencia from './components/agencia/Contenido';
import ContenidoPanelCliente from './components/panelCliente/Contenido';
import ContenidoVehiculo from './components/vehiculo/Contenido';
import ContenidoAyudante from './components/ayudante/Contenido';
import ContenidoPedidos from './components/pedidos/Contenido';
import ContenidoPedidosPorVehiculos from './components/pedidosPorVehiculos/Contenido';
import ContenidoPedidosPorAyudantes from './components/pedidosPorAyudantes/Contenido';
import ContenidoConductor from './components/conductor/Contenido';
import ContenidoAsignarCarga from './components/asignarCarga/Contenido';
import ContenidoEntregaPedidos from './components/entregaPedidos/Contenido';
import ContenidoRegularizarPedidos from './components/regularizarPedidos/Contenido';
import ContenidoConfiguracionesGps from './components/configuraciones/gps/Contenido';
import ContenidoRastreoFlota from './components/rastreo/flota/Contenido';
import ContenidoInicioSecion from './components/inicioSecion/Contenido';
import ContenidoClientes from './components/cliente/Contenido';
import ContenidoConfiguracionesWhatsapp from './components/configuraciones/whatsapp/Contenido';
import ContenidoConfiguracionesTracking from './components/configuraciones/tracking/Contenido';
import ContenidoReportePedidos from './components/reportes/pedidos/Contenido';

import Error from './components/Error';
//importando la plantilla admin LTE
import { Wrapper,  PageWrapper,  PageHeader,    Breadcrumb,  BreadcrumbItem,  NavHeader,  Nav,  NavToggle,  NavLink,  AsideToggle,  SidebarWrapper,  MainLogo,Sidebar,  UserPanel,  SidebarNav,  SidebarNavItem,  SidebarNavTree,} from 'react-adminlte-3'
//importar conponentes
import MenuPrincipal from './components/MenuPrincipal';
import MenuSuperior from './components/MenuSuperior';
import {TokenValido,eliminarToken,validarExistenciaDePermiso } from './helpers'

class Router extends Component{
   
    state = {usuario_autenticado: TokenValido()};
   

    render(){
        return(
            <>
            {!this.state.usuario_autenticado &&
            <BrowserRouter>
                <Routes>
                    <Route  path='/inicio-secion' usuario={this.state.usuario_autenticado} element={<ContenidoInicioSecion/>}  /> 
                    <Route  path='*' element={<ContenidoInicioSecion/>} />
                    <Route   path='/panel/cliente/:cliente_id' element={<ContenidoPanelCliente/>} /> 

                </Routes>
            </BrowserRouter> 
            }
            {this.state.usuario_autenticado &&
            <BrowserRouter>
                    {/* configuracion de rutas */}

                    <div className='wrapper'  >        
          
                    {/* componente Menu Superior */}
                    <MenuSuperior/>
                    {/* componente menu principal */}
                    <MenuPrincipal/>

                    <PageWrapper>

                        <Routes>
                            {
                                validarExistenciaDePermiso(['mostrar-seguimiento']) &&
                                <Route   path='/panel/cliente/:cliente_id' element={<ContenidoPanelCliente/>} /> 
                            }
                            {
                                validarExistenciaDePermiso(['mostrar-seguimiento']) &&
                                <Route   path='/panel/cliente/' element={<ContenidoPanelCliente/>}  /> 
                            }
                            {
                                validarExistenciaDePermiso(['mostrar-dashboard']) &&
                                <Route component={this.props.gestionarCarga} path='/' element={<ContenidoInicio/>}  /> 
                            }

                            {
                                validarExistenciaDePermiso(['mostrar-seguimiento']) &&
                                <Route  path='/rastreo/flota' element={<ContenidoRastreoFlota/>}  /> 
                            } 
     
                            
                            {
                                validarExistenciaDePermiso(['mostrar-usuarios']) &&
                                <Route  path='/administracion/usuarios' element={<ContenidoUsuario/>}  /> 

                            }                            
                            {
                                validarExistenciaDePermiso(['mostrar-usuarios']) &&
                                <Route  path='/reportes/pedidos' element={<ContenidoReportePedidos/>}  /> 

                            }      
                            {
                                validarExistenciaDePermiso(['mostrar-usuarios']) &&
                                <Route  path='/administracion/agencias' element={<ContenidoAgencia/>}  /> 

                            }                          
                            {
                                validarExistenciaDePermiso(['mostrar-vehiculos']) &&
                                <Route  path='/vehiculos' element={<ContenidoVehiculo/>}  /> 

                            }                               
                            {
                                validarExistenciaDePermiso(['mostrar-ayudantes']) &&
                                <Route  path='/ayudantes' element={<ContenidoAyudante/>}  /> 

                            }     

                                                   
                            {
                                validarExistenciaDePermiso(['mostrar-conductores']) &&
                                <Route  path='/conductores' element={<ContenidoConductor/>}  /> 

                            }                            
                            {
                                validarExistenciaDePermiso(['mostrar-asignar-carga']) &&
                                <Route  path='/asignar/carga' element={<ContenidoAsignarCarga/>}  /> 

                            }                           
                            {
                                validarExistenciaDePermiso(['mostrar-entrega-pedido']) &&
                                <Route  path='/entrega/pedidos' element={<ContenidoEntregaPedidos/>}  /> 


                            } 
                            {
                                validarExistenciaDePermiso(['mostrar-regularizar-pedido']) &&
                                <Route  path='/entrega/regularizar-pedidos' element={<ContenidoRegularizarPedidos/>}  /> 


                            }                         
                            {
                                validarExistenciaDePermiso(['mostrar-pedidos']) &&
                                <Route  path='/pedidos' element={<ContenidoPedidos/>}  /> 
                                 

                            }                           
                            {
                                validarExistenciaDePermiso(['mostrar-pedidos-vehiculos']) &&
                                <Route  path='/pedidos-por-vehiculos' element={<ContenidoPedidosPorVehiculos/>}  /> 
                                 

                            }                        
                            {
                                validarExistenciaDePermiso(['mostrar-pedidos-vehiculos']) &&
                                <Route  path='/pedidos-por-ayudantes' element={<ContenidoPedidosPorAyudantes/>}  /> 
                                 

                            }                           
                            {
                                validarExistenciaDePermiso(['mostrar-configuracion-gps']) &&
                                <Route  path='/configuraciones/gps' element={<ContenidoConfiguracionesGps/>}  /> 
                                 

                            }                           
                            {
                                validarExistenciaDePermiso(['mostrar-configuracion-ws']) &&
                                <Route  path='/configuraciones/whatsapp' element={<ContenidoConfiguracionesWhatsapp/>}  /> 
                                 

                            }                           
                            {
                                validarExistenciaDePermiso(['mostrar-clientes']) &&
                                <Route  path='/clientes' element={<ContenidoClientes/>}  /> 
                                 

                            }

                            <Route  path='/configuraciones/transito' element={<ContenidoConfiguracionesTracking/>}  /> 
                                 
                            <Route  path='*' element={<Error/>} />
                        </Routes>

                    </PageWrapper>
            
                    </div>
            </BrowserRouter>}
            </>

        )
    }
 



}

export default Router;
