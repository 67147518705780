import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelopeSquare, faCar, faComment,faBookmark, faTint, faTachometerAlt,faShoppingBag,faCircle } from '@fortawesome/free-solid-svg-icons';

 

class Crear extends Component {

    
    
    render() {
        
        let data = this.props.data
        let formulario = data.crear_registro
        let titulo = `${formulario.id ? 'Editar':'Crear'} ${data.titulo_modulo}`
        let mostrar_modal = data.modal_crear.mostrar_modal
        let marcas = []
        let tipos = []
        let conductores = []
        let sedes = []
        let errores_validacion = data.errores_validacion

        //validar que existan registros
        if(data.registros){
            marcas = data.registros.marcas_vehiculo
            tipos = data.registros.tipos_vehiculo
            conductores = data.registros.conductores
            sedes = data.registros.sedes
        }
 
        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.props.funcionMostrarOcultarModalCrear()}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={this.props.funcionGuardarRegistrosUsiario}>
                         
                    <Modal.Body>

                                              
                            { errores_validacion.placa && <span className="form-text text-danger mb-1">{errores_validacion.placa[0]}</span> }
                             
                            <InputGroup className='mb-3'>
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faCar}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Placa"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="placa"
                                    id="placa"
                                    isInvalid={errores_validacion.placa ? true : false}
                                    onChange = {this.props.funcionHandleInputChanger}
                                    value={formulario.placa}
                                />
                            </InputGroup>

                            { errores_validacion.description && <span className="form-text text-danger mb-1">{errores_validacion.description[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faComment}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Description"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="description"
                                    id="description"
                                    isInvalid={errores_validacion.description ? true : false}
                                    onChange = {this.props.funcionHandleInputChanger}
                                    value={formulario.description}


                                />
                            </InputGroup>

                            { errores_validacion.marca && <span className="form-text text-danger mb-1">{errores_validacion.marca[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faBookmark}/></InputGroup.Text>

                                <Form.Select value={formulario.marca} isInvalid={errores_validacion.marca ? true : false} aria-label="Default select example" name='marca' className='custom-select form-control-border border-width-2' onChange = {this.props.funcionHandleInputChanger}>
                                    
                                    <option>Seleccione una Marca</option>

                                    {
                                        marcas.map((marca)=>{
                                            return (
                                                <option key={marca.id} value={marca.id}>{marca.name}</option>
                                            )                                          
                                        })
                                    }
                                    
                                </Form.Select>                                
                                    
                            </InputGroup>

                            { errores_validacion.tipo && <span className="form-text text-danger mb-1">{errores_validacion.tipo[0]}</span> }


                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"  className='border-0 bg-white'> <FontAwesomeIcon icon={faBookmark}/></InputGroup.Text>

                                <Form.Select value={formulario.tipo} isInvalid={errores_validacion.tipo ? true : false} aria-label="Default select example" name='tipo' className='custom-select form-control-border border-width-2' onChange = {this.props.funcionHandleInputChanger}>
                                    <option>Seleccione un Tipo de Vehiculo</option>
                                    {
                                        tipos.map((tipo)=>{
                                            return (
                                                <option  key={tipo.id} value={tipo.id}> {tipo.name}</option>
                                            )                                          
                                        })
                                    }
                                </Form.Select>                                
                                    
                            </InputGroup>


                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"  className='border-0 bg-white'> <FontAwesomeIcon icon={faBookmark}/></InputGroup.Text>

                                <Form.Select value={formulario.conductor_id}  aria-label="Default select example" name='conductor_id' className='custom-select form-control-border border-width-2' onChange = {this.props.funcionHandleInputChanger}>
                                    <option>Seleccione un conductor</option>
                                    {
                                        conductores.map((conductor)=>{
                                            return (
                                                <option  key={conductor.id} value={conductor.id}> {conductor.names} {conductor.surnames}</option>
                                            )                                          
                                        })
                                    }
                                </Form.Select>                                
                                    
                            </InputGroup>

                            { errores_validacion.sede && <span className="form-text text-danger mb-1">{errores_validacion.sede[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"  className='border-0 bg-white'> <FontAwesomeIcon icon={faShoppingBag}/></InputGroup.Text>

                                <Form.Select isInvalid={errores_validacion.sede ? true : false} value={formulario.sede}  aria-label="Default select example" name='sede' className='custom-select form-control-border border-width-2' onChange = {this.props.funcionHandleInputChanger}>
                                    <option>Seleccione un una Sede</option>
                                    {
                                        sedes.map((sede)=>{
                                            return (
                                                <option  key={sede.id} value={sede.id}> {sede.name}</option>
                                            )                                          
                                        })
                                    }
                                </Form.Select>                                
                                    
                            </InputGroup>

                            { errores_validacion.estatus && <span className="form-text text-danger mb-1">{errores_validacion.estatus[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"  className='border-0 bg-white'> <FontAwesomeIcon icon={faShoppingBag}/></InputGroup.Text>

                                <Form.Select isInvalid={errores_validacion.estatus ? true : false} value={formulario.estatus}  aria-label="Default select example" name='estatus' className='custom-select form-control-border border-width-2' onChange = {this.props.funcionHandleInputChanger}>
                                    <option>Seleccione un estado</option>
                                    <option value={'Disponible'}>Disponible</option>
                                    <option value={'En Taller'}>En Taller </option>
                                    
                                </Form.Select>                                
                                    
                            </InputGroup>
                            
                            { errores_validacion.modelo && <span className="form-text text-danger mb-1">{errores_validacion.modelo[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faCar}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Modelo"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="modelo"
                                    id="modelo"
                                    onChange = {this.props.funcionHandleInputChanger}
                                    isInvalid={errores_validacion.modelo ? true : false}
                                    value={formulario.modelo}

                                />
                            </InputGroup>
                            { errores_validacion.color && <span className="form-text text-danger mb-1">{errores_validacion.color[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faTint}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Color"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="color"
                                    id="color"
                                    onChange = {this.props.funcionHandleInputChanger}
                                    isInvalid={errores_validacion.color ? true : false}
                                    value={formulario.color}

                                />
                            </InputGroup>
                            { errores_validacion.maxima_velocidad && <span className="form-text text-danger mb-1">{errores_validacion.maxima_velocidad[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faTachometerAlt}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Maxima Velocidad"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="maxima_velocidad"
                                    id="maxima_velocidad"
                                    onChange = {this.props.funcionHandleInputChanger}
                                    isInvalid={errores_validacion.maxima_velocidad ? true : false}
                                    value={formulario.maxima_velocidad}

                                />
                            </InputGroup>

                            
                            { errores_validacion.capacidad_kilogramos && <span className="form-text text-danger mb-1">{errores_validacion.capacidad_kilogramos[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faCircle}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Capacidad En Kg"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="capacidad_kilogramos"
                                    id="capacidad_kilogramos"
                                    isInvalid={errores_validacion.capacidad_kilogramos ? true : false}
                                    onChange = {this.props.funcionHandleInputChanger}
                                    value={formulario.capacidad_kilogramos}


                                />
                            </InputGroup>
                            
                            { errores_validacion.capacidad_volumen && <span className="form-text text-danger mb-1">{errores_validacion.capacidad_volumen[0]}</span> }

                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1" className='border-0 bg-white'> <FontAwesomeIcon icon={faCircle}/></InputGroup.Text>
                                <FormControl className='form-control-border border-width-2'
                                    placeholder="Ingrese Capacidad En Volumen"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name="capacidad_volumen"
                                    id="capacidad_volumen"
                                    isInvalid={errores_validacion.capacidad_volumen ? true : false}
                                    onChange = {this.props.funcionHandleInputChanger}
                                    value={formulario.capacidad_volumen}


                                />
                            </InputGroup>
                            
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.props.funcionMostrarOcultarModalCrear(null)} >
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>Guardar</Button>
                    </Modal.Footer>
                    </Form>
                </Modal>
            </>
        );
    }
}




export default Crear;