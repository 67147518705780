import React, { Component } from 'react';

// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
//iconos fas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScaleBalanced, faEnvelopeSquare, faCar, faComment, faBookmark, faTint, faTachometerAlt, faLessThan } from '@fortawesome/free-solid-svg-icons';
import imagen_vehiculo from '../../assets/images/vehiculo.png';
import imagen_sin_foto from '../../assets/images/logo.jpg';
import { fomatearFecha} from '../../helpers'
import DetalleProceso from '../panelCliente/DetalleProceso';
import StarRatings from 'react-star-ratings';
import Select from 'react-select'

//importar funciones helpers
import { obtenerEscala} from '../../helpers'



class CargarAgencia extends Component {
    
    registros = [];

    render() {
        this.registros = []

        let data = this.props.data
        let pedidos = this.props.data.pedidos
       
        if (!pedidos) {
            return <></>            
        }

        let titulo = `Cambiar Agencia`
        let mostrar_modal = data.modal_agencia.mostrar_modal
        let options = [];

        this.props.data.registros.agencias.map((agencia)=>{
            options.push({ value: agencia.code, label: agencia.name })                                       
        })


       
          
        return (
            <>
                <Modal
                    show={mostrar_modal}
                    onHide={()=>this.props.funcionMostrarOcultarModalAgencia(null)}
                    backdrop="static"
                    keyboard={false}
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-sm-12'>.
                                 
                                <Select onChange = {this.props.funcionHandleInputChangerAgencia} 
                                        value={this.props.data.crear_registro_agencia['agencia']}
                                        options={options} 
                                />
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.props.funcionMostrarOcultarModalAgencia(null)} >
                            Close
                        </Button>
                        <a  onClick={()=>this.props.funcionCambiarAgencia(pedidos)} className="btn  btn-primary">
                          Cambiar Agencia
                        </a>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    
  //obtener los registros de pedido
  obtenerRegistroDeProcesoDePedido(detalle){

    let descripcion = detalle.transit.description_web
    //validar si ya se agrego dicho detalle
    const result = this.registros.filter(registro => registro==descripcion);

    //si ya se agrego salimos de la funcion
    if (result.length>0) {
      return null
    }

    //si no existe lo agregamos al arreglo
    this.registros.push(descripcion);
    
    return {
      id: detalle.id,
      titulo_estado: descripcion,
      fecha: `${detalle.date_of_work} ${detalle.work_time}`,
      descripcion: descripcion+' de Manera Exitosa.',
      estado: 'Aprobado'
    };

  }
}





export default CargarAgencia;