import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPhone, faCar } from '@fortawesome/free-solid-svg-icons';
import imagen from '../../assets/images/vehiculo.png';
// componentes de boostrap
import { Button, Modal, Form, InputGroup, FormControl,ProgressBar } from 'react-bootstrap';

//importar funciones helpers
import { confirmarAyudante,mostrarMensajeExito,mensajeDeConfirmacion} from '../../helpers'
//importar configuracion axio
import { laravelAPI } from '../../config.js'


class RegularizarPedido extends Component {

  render() {

    let state = this.props.data
    let cliente = state.pedidos_en_destino
    if (cliente == null) {
      cliente = state.pedidos_entregados
    }
    let errores_validacion = state.errores_validacion
    let Clientes = [];
    let ids = [];
     
    return (
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                </div>

                <div className="card-body" >
                  <div className="row">
                    <form onSubmit={this.props.guardarEntrega} encType="multipart/form-data" id="imageForm">
                      <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                        <div className="card bg-light d-flex flex-fill">
                          <div className="card-header text-muted border-bottom-0">
                            <p className='mt-3'>{cliente.length} pedidos</p>
                            <p><b>{cliente[0].agencia_envio}</b></p>
                          </div>
                          <div className="card-body pt-0">
                            <div className="row">
                              <div className="col-7">
                                <h2 className="lead"><b>{cliente[0].social_reason}</b></h2>
                                <p className="text-muted text-sm"><b>Comentario: </b> {cliente[0].comentario} </p>

                              </div>
                              <div className="col-5 text-center">
                                <img src={imagen} alt="user-avatar" className="img-circle img-fluid"></img>
                              </div>

                              <div className="col-12" id='formulario'>
                                <h4 className='text-center mt-2'>Llenar Formulario</h4>
                                {/* <div className="form-group">
                                  <label>Fecha</label>
                                  <input className='form-control'  onChange = {this.props.modificarTipoEntrega} type={'date'} defaultValue={state.crear_registro.fecha} name='fecha' ></input>
                                </div>
                                <div className="form-group">
                                  <label>Hora</label>
                                  <input className='form-control'  onChange = {this.props.modificarTipoEntrega} type={'time'} defaultValue={state.crear_registro.hora} name='hora' ></input>
                                </div> */}

                                {
                                   
                                  cliente.map((client, key) => {
                                    ids.push(client.order_delivery_detail_id);

                                    if (!Clientes.includes(client.customer_id)) {
                                      Clientes.push(client.customer_id);
                                                                            
                                      return (
                                        <div key={key}>
                                          <div className='row'>
                                            <div className='col-12'>
                                              <p><label className='text-info' style={{'font-size': 'large'}}>DATOS DEL CLIENTE:</label></p>
                                            </div>
                                            <div className='col-4 text-info'>
                                              <p><label style={{'font-size': 'large'}}>Codigo: </label></p>
                                            </div>
                                            <div className='col-8 text-info'>
                                              <p><label style={{'font-size': 'large'}}>{client.customer_code}</label></p>
                                            </div>
                                            <div className='col-4 text-info'>
                                              <p><label style={{'font-size': 'large'}}>Razón Social:</label></p>
                                            </div>
                                            <div className='col-8 text-info'>
                                              <p><label style={{'font-size': 'large'}}>{client.name_social_reason}</label></p>
                                            </div>
                                            <div className='col-4 text-info'>
                                            <p><label style={{'font-size': 'large'}}>Ruc:</label></p>
                                            </div>
                                            <div className='col-8 text-info'>
                                              <p><label style={{'font-size': 'large'}}>{client.document_number}</label></p>
                                            </div>
                                            <div className='col-4 text-info'>
                                              <p><label className='text-info' style={{'font-size': 'large'}}>Guía:</label></p>
                                            </div>
                                            <div className='col-8 text-info'>
                                              <p><label style={{'font-size': 'large'}}>{(client.referral_guide_number==null)?'Sin Guía':client.referral_guide_number}</label></p>
                                            </div>
                                            <div className='col-4 text-info'>
                                              <p><label className='text-info' style={{'font-size': 'large'}}>Nro Pedido:</label></p>
                                            </div>
                                            <div className='col-8 text-info'>
                                              <p><label style={{'font-size': 'large'}}>{client.document_order_number}</label></p>
                                            </div>
                                          </div>
                                        {/* <p><label className='text-info' style={{'font-size': 'large'}}>Cliente: {client.customer_code} - {client.name_social_reason}</label></p>
                                        <p>
                                          <label className='text-info' style={{'font-size': 'large'}}>Ruc Cliente: {client.document_number}</label>
                                        </p>
                                        <p>
                                          <label className='text-info' style={{'font-size': 'large'}}>Guía: {(client.referral_guide_number==null)?'Sin Guía':client.referral_guide_number}</label>
                                        </p>
                                        <p>
                                          <label className='text-info' style={{'font-size': 'large'}}>Pedido: {client.document_order_number}</label>
                                        </p> */}
                                        <Form.Group key={"p"+key} controlId="formFile"  className="mb-3">
                                          <Form.Label>Cargar foto del documento :</Form.Label>
                                          <Form.Control isInvalid = {errores_validacion[`imagen-${client.customer_id}`] ? true : false}   type="file" accept="image/*" name={`imagen-${client.customer_id}`} onChange={(e) => {this.props.modificarImagen(e,client.customer_id,client.id,'peso')}} />
                                          <ProgressBar style={{'border': 'black 1px solid'}} now={this.props.data.progress_bar[`imagen-${client.customer_id}`] ? this.props.data.progress_bar[`imagen-${client.customer_id}`] : 0} />

                                        </Form.Group>
                                        <Form.Group key={"b"+key}  controlId="formFile"  className="mb-3">
                                          <Form.Label>Cargar Foto de bulto:</Form.Label>
                                          <Form.Control isInvalid = {errores_validacion[`imagen-bulto-${client.customer_id}`] ? true : false}   type="file" accept="image/*" name={`imagen-bulto-${client.customer_id}`} onChange={(e) => {this.props.modificarImagen(e,client.customer_id,client.id,'bulto')}} />
                                          <ProgressBar   style={{'border': 'black 1px solid'}} now={this.props.data.progress_bar[`imagen-bulto-${client.customer_id}`] ? this.props.data.progress_bar[`imagen-bulto-${client.customer_id}`] : 0} />

                                        </Form.Group>
                                        
                                        </div>
                                      )
                                    }
                                  })
                                }

                               
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )

  }

  async generarTransbordo(pedido_id,ids){

    let confirmacion = await confirmarAyudante('Si Desea Generar un Transbordo Seleccione el Ayudante')
     
    if(confirmacion.id){

      let esConfirmar = await mensajeDeConfirmacion(`Para ${confirmacion.names} ${confirmacion.surnames}?`);
  
      if (!esConfirmar) {
        return;
      }
      return new Promise((resolve, reject) => {

        laravelAPI.get(`ayudantes/trasbordar-ayudante/${pedido_id}/${confirmacion.id}`,{params : {ordenes:ids}})
            .then(function (response) {

                mostrarMensajeExito(response.mensaje)
                window.location.replace(`/entrega/pedidos`)
                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
      })
    }


  }

  
  async posponerEnvio(pedido_id,ids){

    
    let esConfirmar = await mensajeDeConfirmacion(
      "Seguro de realizar la acción?"
    );

    if (!esConfirmar) {
      return;
    }

     
    if(esConfirmar){
      return new Promise((resolve, reject) => {

        laravelAPI.get(`ayudantes/posponer-entrega`,{params : {ordenes:ids}})
            .then(function (response) {

                mostrarMensajeExito(response.mensaje)
                window.location.reload()
                resolve(response)

            })
            .catch(function (error) {
                reject(null)
            })
      })
    }


  }

}

export default RegularizarPedido;
