import React, { Component } from 'react';

//importar iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';


class EncabezadoModulo extends Component {

  //renderizar componente
  render() {
 
    let titulo = this.props.titulo_modulo

    return (
      <div className='content-header'>
        <div className='row mb-2'>
          <div className='col-sm-12'>
            <h4 className='title'>{titulo}</h4>
          </div>
           
        </div>
      </div>
    )
  }



}

export default EncabezadoModulo;
